import React from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const HonestyModal = ({ show, closeModal }) => {
  const cancelButtonRef = useRef(null);

  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-start shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-start sm:mt-0 ">
                      <Dialog.Title
                        as="h3"
                        className="text-3xl mb-2 font-light leading-6 text-gray"
                      >
                        By clicking agree, you confirm that:
                      </Dialog.Title>
                      <hr className="line-break w-full mt-5 mb-5"></hr>
                      <div className="honesty-list mt-2 ml-5">
                        <ul className="list-disc text-lg leading-6">
                          <li className="list-item">
                            <p className="text-gray font-light">
                              You have answered all questions truthfully and
                              that there is no other information which may be
                              important for us too consider before assessing
                              your application.
                            </p>
                          </li>

                          <li className="list-item">
                            <p className="text-gray font-light">
                              You are not aware of any illness, activity or
                              occupational risk that you are exposed to that
                              should be considered.
                            </p>
                          </li>

                          <li className="list-item">
                            <p className="text-gray font-light">
                              Once this policy is in place, the future lifestyle
                              choices that you make can also affect our
                              agreement. You agree that you will communicate
                              with us if:
                            </p>
                            <ul className="list-disc text-base ml-5">
                              <li className="list-item">
                                <p className="text-gray font-light">
                                  You change your occupation
                                </p>
                              </li>
                              <li className="list-item">
                                <p className="text-gray font-light">
                                  You start or stop taking part in a dangerous
                                  sport or activity
                                </p>
                              </li>
                              <li className="list-item">
                                <p className="text-gray font-light">
                                  You start or stop smoking
                                </p>
                              </li>
                              <li className="list-item">
                                <p className="text-gray font-light">
                                  You plan to travel or live outside of South
                                  Africa for more than 30 consecutive days
                                </p>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr className="line-break w-full mt-5 mb-5"></hr>
                  <div className="mt-5 text-center">
                    {/* <fieldset> */}
                    <div className="space-y-5">
                      <div className="relative flex items-start">
                        <div className="flex h-5 items-center">
                          <input
                            id="agree"
                            aria-describedby="agree-description"
                            name="agree"
                            type="checkbox"
                            checked={checked}
                            onChange={handleChange}
                            onClick={closeModal}
                            className="h-8 w-8 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="agree"
                            className="font-light text-gray"
                          >
                            <span>Yes, I Agree</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default HonestyModal;
