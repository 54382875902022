import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Stepper from "../components/multi-form-stepper/Stepper";
import {
    alreadyGotCover,
    medicalQuestionsOptions,
    productCover,
    steps,
    products,
    vaccination,
} from "../models/DataModels";
import { useState } from "react";
import QuoteInformation from "../components/content/QuoteInformation";
import {
    alcholOptions,
    marketingConsent,
    libertyGroupConsent,
    otherConsent,
    researchConsent,
    marketingConsentAll,
} from "../models/DataModels";
import ProgressBar from "../components/progress-bar/ProgressBar";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import "./slider/Slider.css";
import { MainContext } from "../App";
import { useCookies } from "react-cookie";
import Select from "react-select";
import axios from "axios";
import HonestyModal from "../components/modals/QuoteModals/HonestyModal";
import CallUsModal from "../components/modals/CallUsModal";
import ApplicationModal from "../components/modals/ApplicationModal";
import { applicationModal } from "../models/DataModels";
import Loading from "../components/modals/Loading";
import AsyncSelect from "react-select/async";

export default function Quote() {
    const navigate = useNavigate();
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
    const [pageLoading, setPageLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(2);
    const [isWeightValid, setIsWeightValid] = useState(true);
    const [isHeightValid, setIsHeightValid] = useState(true);
    const [premium, setPremium] = useState(0);
    const [cover, setCover] = useState(0);
    const [existingCoverAmount, setExistingCoverAmount] = useState(0);
    const [isExistingCoverAmountValid, setIsExistingCoverAmountValid] =
          useState(true);
    const [monthlySlider, setMonthlySlider] = useState([0]);
    const [monthlyPremium, setMonthlyPremium] = useState([0]);
    const [quoteDetails, setQuoteDetails] = useState({
        height: null,
        weight: null,
        liberty_direct_marketing: false,
        liberty_group_marketing: false,
        partner_marketing: false,
        research_marketing: false,
        drinker: false,
        alcohol_units: null,
    });
    const [country, setCountry] = useState("");
    const [vaccinationStatus, setVaccinationStatus] = useState(null);
    const vaccinationStatusRef = useRef(null);
    const [isCountryValid, setIsCountryValid] = useState(true);
    const [alcoholUnits, setAlcoholUnits] = useState("");
    const [isAlcoholUnitsValid, setIsAlcoholUnitsValid] = useState(true);
    const { height, weight } = quoteDetails;
    const [cookies, setCookie, removeCookie] = useCookies();
    const [showQuoteSection, setShowQuoteSection] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [tooltip, showTooltip] = useState(true);

    const [showReflexiveQuestion, setShowReflexiveQuestion] = useState(false);
    const [reflexiveQuestion, setReflexiveQuestion] = useState(null);
    const [reflexiveQuestionPlaceholder, setReflexiveQuestionPlaceholder] = useState();
    const reflexiveSearchTerm = useRef("");
    const [reflexiveLookup, setReflexiveLookup] = useState();
    const [reflexiveSubmitUrl, setReflexiveSubmitUrl] = useState();
    const [reflexiveMethod, setReflexiveMethod] = useState();
    const [reflexiveAnswers, setReflexiveAnswers] = useState([]);

    const yesChecked = useRef(false);
    const [yesStateChecked, setYesStateChecked] = useState(false);
    const noChecked = useRef(false);
    const [noStateChecked, setNoStateChecked] = useState(false);

    const [progress, setProgress] = useState(0);
    const loadPercent = useRef(0);
    const [loadingPerc, setLoadingPerc] = useState(0);
    const [checkBoxValue, setCheckBoxDisabled] = useState(false);
    const [consentYesChecked, setConsentYesChecked] = useState(false);
    const [consentNoChecked, setConsentNoChecked] = useState(false);
    const [consentAllYesChecked, setConsentAllYesChecked] = useState(false);
    const [consentAllNoChecked, setConsentAllNoChecked] = useState(false);
    const [consentLibertyYesChecked, setConsentLibertyYesChecked] =
          useState(false);
    const [consentLibertyNoChecked, setConsentLibertyNoChecked] = useState(false);
    const [consentOtherYesChecked, setConsentOtherYesChecked] = useState(false);
    const [consentOtherNoChecked, setConsentOtherNoChecked] = useState(false);
    const [consentResearchYesChecked, setConsentResearchYesChecked] =
          useState(false);
    const [consentResearchNoChecked, setConsentResearchNoChecked] =
          useState(false);
    const [gotCoverYesChecked, setGotCoverYesChecked] = useState(false);
    const [gotCoverNoChecked, setGotCoverNoChecked] = useState(false);
    const [travelYesChecked, setTravelYesChecked] = useState(false);
    const [travelNoChecked, setTravelNoChecked] = useState(false);
    const [alcholYesChecked, setAlcoholYesChecked] = useState(false);
    const [alcholNoChecked, setAlcoholNoChecked] = useState(false);
    const [currentCoverName, setCurrentCoverName] = useState("");
    const [productCode, setProductCode] = useState();
    const [staticData, setStaticData] = useState({});
    const [minCover, setMinCover] = useState(0);
    const [maxCover, setMaxCover] = useState(0);
    const [affinity, setAffinity] = useState("");
    const [quoteResponse, setQuoteResponse] = useState({
        quote_id: 0,
        case_id: 0,
        questions: [
            {
                code: "",
                text: "",
                type: "",
                options: ["", ""],
                url: "",
                method: "",
            },
        ],
    });
    const [quoteQuestionIndex, setQuoteQuestionIndex] = useState(0);
    const [quoteQuestionCount, setQuoteQuestionCount] = useState(18);
    const [quoteQuestionRequest, setQuoteQuestionRequest] = useState({
        case_id: 0,
        code: "",
        answer: "",
    });
    const [show, setShow] = useState(false);
    const [showDropOff, setShowDropOff] = useState(false);
    const [showDropOffModal, setShowDropOffModal] = useState(false);
    const productKey = JSON.parse(localStorage.getItem("productKey"));
    const customerId = JSON.parse(localStorage.getItem("custId"));
    const [dropOffModel, setDropOffModel] = useState({
        id: 0,
        image: "",
        dropOff: "",
        title: "",
        messageSection1: "",
        messageSection2: "",
        messageSection3: "",
        messageSection4: "",
        messageSection5: "",
    });
    const [dropOffId, setDropOffId] = useState(7);

    useEffect(() => {
        const validate = async () => {
            if (productKey) {
                setCurrentCoverName(productKey);
            }

            if ((await cookies.productSlug) === undefined) {
                sessionStorage.setItem("invalidPath", true);
                window.location = "/";
            }
        };
        validate();

        const getStaticData = async () => {
            var config = {
                method: "get",
                url: `${API_ENDPOINT}buyonline/static-data`,
                headers: {
                    Authorization: `${AUTH_KEY}`,
                },
            };

            return await axios(config)
                .then((response) => {
                    if (response) {
                        if (response.data.success === false) {
                            toast.warn(`Failed to get data`, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            return Promise.reject(
                                console.log("Get static data", JSON.stringify(response.data))
                            );
                        } else {
                            setStaticData(response.data);
                            let selectedProduct = "";

                            if (productKey) {
                                if (productKey === "Serious Illness") {
                                    let productToFind = "Serious Illness Cover";
                                    selectedProduct = productCover.find(
                                        (x) => x.id === productToFind
                                    ).value;
                                    setProductCode(response.data.PRODUCTS[productToFind]);
                                } else {
                                    selectedProduct = productCover.find(
                                        (x) => x.id === productKey
                                    ).value;
                                    setProductCode(response.data.PRODUCTS[productKey]);
                                }
                            }

                            let minCover = response.data["MINIMUM_COVER"][selectedProduct];
                            setMinCover(minCover);

                            let maxCover = response.data["MAXIMUM_COVER"][selectedProduct];
                            setMaxCover(maxCover);

                            let affinity = "sbsa";
                            setAffinity(affinity);

                            setPremium(minCover);
                        }
                    }
                })
                .catch((error) => {
                    toast.error(`Failed to get data`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    console.log("Get static data", JSON.stringify(error));
                    return;
                });
        };

        getStaticData();
    }, []);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "2px",
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
            color: "#3C4B6C",
        }),
        control: (styles, state) => ({
            ...styles,
            borderColor: "#858D9D",
            boxShadow: "none",
            "&:hover": {
                border: "2px solid #0033a1",
            },
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition };
        },
    };

    function isValidNumber(number) {
        return /^[0-9\b]+$/.test(number);
    }

    function isValidName(name) {
        return /(^[a-zA-Z_(),@'/-])/gm.test(name);
    }

    const onChange = (event) => {
        if (event.target.name === "country") {
            if (!isValidName(event.target.value)) {
                setIsCountryValid(false);
            } else {
                setIsCountryValid(true);
            }

            if (event.target.value === "") {
                setIsCountryValid(true);
            }

            setCountry(event.target.value);
            return;
        }

        if (event.target.name === "existingCoverAmount") {
            if (!isValidNumber(event.target.value)) {
                setIsExistingCoverAmountValid(false);
            } else {
                setIsExistingCoverAmountValid(true);
            }

            if (event.target.value < 0) {
                setIsExistingCoverAmountValid(false);
            }

            setExistingCoverAmount(event.target.value);
            return;
        }

        if (event.target.name === "alcohol_units") {
            if (!isValidNumber(event.target.value)) {
                setIsAlcoholUnitsValid(false);
            } else {
                setIsAlcoholUnitsValid(true);
                setAlcoholUnits(event.target.value);
            }

            if (event.target.value <= 0 || event.target.value === "") {
                setIsAlcoholUnitsValid(false);
                setAlcoholUnits(event.target.value);
                return;
            } else {
                setIsAlcoholUnitsValid(true);
                setAlcoholUnits(event.target.value);
            }
        }

        if (event.target.name === "weight") {
            if (!isValidNumber(event.target.value)) {
                setIsWeightValid(false);
            } else {
                setIsWeightValid(true);
            }

            if (event.target.value < 25) {
                setIsWeightValid(false);
            }

            if (event.target.value > 220) {
                setIsWeightValid(false);
            }
        }

        if (event.target.name === "height") {
            if (!isValidNumber(event.target.value)) {
                setIsHeightValid(false);
            } else {
                setIsHeightValid(true);
            }

            if (event.target.value < 80) {
                setIsHeightValid(false);
            }

            if (event.target.value > 220) {
                setIsHeightValid(false);
            }
        }

        setQuoteDetails({
            ...quoteDetails,
            [event.target.name]: event.target.value,
        });
    };

    function onVaccinationChange(selectedValue) {
        let currentQuestion = quoteResponse.questions[quoteQuestionIndex];

        if (selectedValue !== null) {
            setVaccinationStatus(selectedValue.value);
            vaccinationStatusRef.current = selectedValue.value;
            setQuoteQuestionRequest({
                ...quoteQuestionRequest,
                case_id: quoteResponse.case_id,
                code: currentQuestion.code,
                answer: selectedValue.value,
            });
        } else {
            setVaccinationStatus(null);
        }

        // let loadPerc = 0;
        // let loading = setInterval(() => {
        //     loadPerc += 1;
        //     setLoadingPerc(loadPerc);
        //     loadPercent.current = loadPerc;
        // }, 15);
        // setTimeout(() => {
        //     clearInterval(loading);
        //     updateLoadingPerc(0);
        // }, 1500);

        nextQuestion();
    }

    const closeLoader = () => {
        setPageLoading(false);
    };


    const getQuoteEstimates = async (customerId, productCode) => {
        if (productCode === undefined) {
            productCode = products.filter((prod) => prod.id === productKey)[0].value;
        }

        var config = {
            method: "get",
            url: `${API_ENDPOINT}buyonline/quote-estimate/${customerId}/${productCode}`,
            headers: {
                Authorization: `${AUTH_KEY}`,
            },
        };

        return await axios(config)
            .then((response) => {
                if (response.data) {
                    if (response.data.success === false) {
                        toast.warn(`Failed to get quote estimates`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return Promise.reject(
                            console.log("Get static data", JSON.stringify(response.data))
                        );
                    } else {
                        let coverList = [];
                        let premiumList = [];

                        let range = response.data.products.filter((product) => {
                            sessionStorage.setItem("estimates",JSON.stringify(response.data.products));
                            if (product.code.toLowerCase() === productCode.toLowerCase()) {
                                product.estimates.forEach((cover_premium) => {
                                    coverList.push(cover_premium.cover);
                                    premiumList.push(cover_premium.premium);
                                });
                                return true;
                            }
                        });
                        setMonthlySlider(coverList);
                        setMonthlyPremium(premiumList);
                        setCover(coverList[0]);
                        setPremium(premiumList[0]);
                    }
                }
            })
            .catch((error) => {
                toast.error(`Failed to get quote estimates`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.log("Get quote data", JSON.stringify(error));

                return;
            });
    };

    const onSubmitAdditionalDetails = async (e, contextUser) => {
        e.preventDefault();

        if ( !document.getElementById("confirm-product-disclosure").checkValidity() ) {
            toast.error(`Please confirm that you have downloaded and read the ${productKey} product disclosure.`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        if (quoteDetails.height === null) {
            quoteDetails.height = contextUser.user.id_number && parseInt(contextUser.user.id_number[6]) > 4 ?
                parseInt(process.env.REACT_APP_DEFAULT_MALE_HEIGHT) :
                parseInt(process.env.REACT_APP_DEFAULT_FEMALE_HEIGHT);
        }

        if (quoteDetails.weight === null) {
            quoteDetails.weight = contextUser.user.id_number && parseInt(contextUser.user.id_number[6]) > 4 ?
                parseInt(process.env.REACT_APP_DEFAULT_MALE_WEIGHT) :
                parseInt(process.env.REACT_APP_DEFAULT_FEMALE_WEIGHT);
        }

        let data = JSON.stringify(quoteDetails);
        console.log(data)

        var config = {
            method: "patch",
            url: `${API_ENDPOINT}buyonline/customer/${customerId}`,
            headers: {
                Authorization: `${AUTH_KEY}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        await axios(config)
            .then((response) => {
                if (response) {
                    if (response.data.success === false) {
                        toast.warn(`Failed to add details`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return Promise.reject(
                            console.log(
                                "Add additional details",
                                JSON.stringify(response.data)
                            )
                        );
                    } else {
                        toast.success("Customer updated successfully!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        contextUser.setContextUser(response.data);

                        if (showQuoteSection === false) {
                            setShowQuoteSection(true);
                            setTimeout(()=>{
                                document.querySelector(".cover-amount").scrollIntoView({ behavior: "smooth" });
                            }, 500);
                        }
                        return;
                    }
                }
            })
            .catch((error) => {
                toast.error(`Failed to add details`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.log("Add additional details", JSON.stringify(error));
                return;
            });

        await getQuoteEstimates(customerId, productCode);

        return;
    };

    const onSubmit = async (e, contextUser) => {
        setPageLoading(true);
        e.preventDefault();

        if (showCallUs) return;
        let selectedProduct = "";

        const customerId = JSON.parse(localStorage.getItem("custId"));
        const productKey = JSON.parse(localStorage.getItem("productKey"));
        if (productKey) {
            selectedProduct = products.find((x) => x.id === productKey)?.value;
        }

        var data = JSON.stringify({
            existing_cover: parseFloat(existingCoverAmount),
            products: [
                {
                    name: selectedProduct,
                    cover: parseFloat(cover),
                },
            ],
        });

        var config = {
            method: "post",
            url: `${API_ENDPOINT}buyonline/init-sale/${affinity}/${customerId}`,
            headers: {
                Authorization: `${AUTH_KEY}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        return await axios(config)
            .then((response) => {
                if (response) {
                    setPageLoading(false);
                    if (response.data.success === false) {
                        toast.warn(`Failed to get quote estimates`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return Promise.reject(
                            console.log("Sale failed", JSON.stringify(response.data))
                        );
                    } else {
                        setPageLoading(false);
                        setQuoteResponse({
                            ...quoteResponse,
                            quote_id: response.data["quote_id"],
                            case_id: response.data["case_id"],
                            questions: response.data["questions"],
                        });

                        setQuoteQuestionCount(response.data["questions"].length);
                        localStorage.setItem("caseId", JSON.stringify(response.data["case_id"]));

                        localStorage.setItem(
                            "quoteId",
                            JSON.stringify(response.data["quote_id"])
                        );

                        toast.success("Sale submitted successfully!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                        if (showContent === false) {
                            setShowContent(true);
                            setTimeout(()=>{
                                document.querySelector(".uw-question-container").scrollIntoView({ behavior: "smooth" });
                            }, 500);
                        }

                        return;
                    }
                } else {
                    setPageLoading(false);
                    toast.error("Please select a premium and tick the required field!", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
                onSetUnderwriting(response.data);
            })
            .catch((error) => {
                setPageLoading(false);
                toast.error(`An error occurred while processing your submission. Please try again or select Call Me and one of our friendly agents will be in touch. `, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.log("Sale failed", JSON.stringify(error));

                return;
            });
    };

    const onSetUnderwriting = async (model) => {
        if (model !== null && model !== undefined) {
            setQuoteResponse({
                ...quoteResponse,
                quote_id: model["quote_id"],
                case_id: model["case_id"],
                questions: model["questions"],
            });
        }
    };

    function checkValue(e) {
        if (e.target.id === "consentYes") {
            let consent = !consentYesChecked;
            setConsentYesChecked(consent);
            setConsentNoChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                liberty_direct_marketing: true,
            });
        }

        if (e.target.id === "consentNo") {
            let noConsent = !consentNoChecked;
            setConsentNoChecked(noConsent);
            setConsentYesChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                liberty_direct_marketing: false,
            });
        }

        if (e.target.id === "consentLibertyGroupYes") {
            let consent = !consentLibertyYesChecked;
            setConsentLibertyYesChecked(consent);
            setConsentLibertyNoChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                liberty_group_marketing: true,
            });
        }

        if (e.target.id === "consentLibertyGroupNo") {
            let noConsent = !consentLibertyNoChecked;
            setConsentLibertyNoChecked(noConsent);
            setConsentLibertyYesChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                liberty_group_marketing: false,
            });
        }

        if (e.target.id === "consentOtherYes") {
            let consent = !consentOtherYesChecked;
            setConsentOtherYesChecked(consent);
            setConsentOtherNoChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                partner_marketing: true,
            });
        }

        if (e.target.id === "consentOtherNo") {
            let noConsent = !consentOtherNoChecked;
            setConsentOtherNoChecked(noConsent);
            setConsentOtherYesChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                partner_marketing: false,
            });
        }

        if (e.target.id === "consentResearchYes") {
            let consent = !consentResearchYesChecked;
            setConsentResearchYesChecked(consent);
            setConsentResearchNoChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                research_marketing: true,
            });
        }

        if (e.target.id === "consentResearchNo") {
            let noConsent = !consentResearchNoChecked;
            setConsentResearchNoChecked(noConsent);
            setConsentResearchYesChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                research_marketing: false,
            });
        }

        if (e.target.id === "consentAllYes") {
            let yesConsent = !consentAllYesChecked;
            setConsentAllYesChecked(yesConsent);
            setConsentAllNoChecked(false);

            setConsentYesChecked(yesConsent);
            setConsentNoChecked(false);

            setConsentLibertyYesChecked(yesConsent);
            setConsentLibertyNoChecked(false);

            setConsentOtherYesChecked(yesConsent);
            setConsentOtherNoChecked(false);

            setConsentResearchYesChecked(yesConsent);
            setConsentResearchNoChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                liberty_direct_marketing: true,
                liberty_group_marketing: true,
                partner_marketing: true,
                research_marketing: true,
            });
        }

        if (e.target.id === "consentAllNo") {
            let noConsent = !consentAllNoChecked;
            setConsentAllNoChecked(noConsent);
            setConsentAllYesChecked(false);

            setConsentNoChecked(noConsent);
            setConsentYesChecked(false);

            setConsentLibertyNoChecked(noConsent);
            setConsentLibertyYesChecked(false);

            setConsentOtherNoChecked(noConsent);
            setConsentOtherYesChecked(false);

            setConsentResearchNoChecked(noConsent);
            setConsentResearchYesChecked(false);

            setQuoteDetails({
                ...quoteDetails,
                liberty_direct_marketing: false,
                liberty_group_marketing: false,
                partner_marketing: false,
                research_marketing: false,
            });
        }

        if (e.target.id === "alreadyGotCoverYes") {
            let alreadyGotCoverYes = !gotCoverYesChecked;
            setGotCoverYesChecked(alreadyGotCoverYes);
            setGotCoverNoChecked(false);
        }

        if (e.target.id === "alreadyGotCoverNo") {
            let alreadyGotCoverNo = !gotCoverNoChecked;
            setGotCoverNoChecked(alreadyGotCoverNo);
            setGotCoverYesChecked(false);
        }

        if (e.target.id === "travelYes") {
            let hasTravelled = !travelYesChecked;
            setTravelYesChecked(hasTravelled);
            setTravelNoChecked(false);
        }

        if (e.target.id === "travelNo") {
            let hasNotTravelled = !travelNoChecked;
            setTravelNoChecked(hasNotTravelled);
            setTravelYesChecked(false);
        }

        if (e.target.id === "alcholYes") {
            let alcoholYes = !alcholYesChecked;
            setAlcoholYesChecked(alcoholYes);
            setAlcoholNoChecked(false);
            setQuoteDetails({
                ...quoteDetails,
                drinker: true,
            });
        }

        if (e.target.id === "alcholNo") {
            let alcoholNo = !alcholNoChecked;
            setAlcoholNoChecked(alcoholNo);
            setAlcoholYesChecked(false);
            setQuoteDetails({
                ...quoteDetails,
                drinker: false,
            });
        }
    }

    function onMedicalQuestionChecked(e) {
        let currentQuestion = quoteResponse.questions[quoteQuestionIndex];

        console.log("showReflexiveQuestion: " + showReflexiveQuestion);
        if (!showReflexiveQuestion) {
            if (e.target.id === "yes") {
                let checkedYes = !yesChecked.current;
                yesChecked.current = checkedYes;
                noChecked.current = false;
                setNoStateChecked(false);

                setQuoteQuestionRequest({
                    ...quoteQuestionRequest,
                    case_id: quoteResponse.case_id,
                    url: currentQuestion.url,
                    code: currentQuestion.code,
                    answer: true,
                });

                // if (!currentQuestion?.on_true) {
                //     setShowDropOff(true);
                // }
            }

            if (e.target.id === "no") {
                let checkedNo = !noChecked.current;
                noChecked.current = checkedNo;
                yesChecked.current = false;
                setYesStateChecked(false);
                setQuoteQuestionRequest({
                    ...quoteQuestionRequest,
                    case_id: quoteResponse.case_id,
                    code: currentQuestion.code,
                    answer: false,
                });
            }
        } else {
            if (e.target.id === "yes") {
                setYesStateChecked(true);
                yesChecked.current = true;
                noChecked.current = false;
                setNoStateChecked(false);
            } else {
                setYesStateChecked(false);
                yesChecked.current = false;
                noChecked.current = true;
                setNoStateChecked(false);
                setShowDropOff(true);
            }
        }


        document.getElementById('yes').parentElement.style.visibility = 'collapse';
        document.getElementById('no').parentElement.style.visibility = 'collapse';
        document.querySelector('.question-block').style.visibility = 'collapse';
        document.getElementById('loading-icon').style.display = 'block';

        let loadPerc = 0;

        // let loading = setInterval(() => {
        //     loadPerc += 1;
        //     setLoadingPerc(loadPerc);
        //     loadPercent.current = loadPerc;
        // }, 15);

        // setTimeout(() => {
        //     //clearInterval(loading);
        //     updateLoadingPerc(0);
        // }, 1500);

        nextQuestion(e);
    }

    const updateLoadingPerc = (loading) => {
        setLoadingPerc(loading);
    };


    const onContinue = () => {
        return
    };

    const onClick = () => {
        return;
    };

    const onSubmitDisclosure = async (answer, url, method="post") => {
        let data = JSON.stringify({
            answer: `${answer}`,
        });

        var config = {
            method: method,
            url: `${API_ENDPOINT}buyonline${url}`,
            headers: {
                Authorization: `${AUTH_KEY}`,
                "Content-Type": "application/json",
            },
            data: data,
        };

        return await axios(config)
            .then((response) => {
                if (response) {
                    if (response.data.success === false) {
                        toast.warn(`Failed to submit answer`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return Promise.reject(
                            console.log(
                                "Answered question failed",
                                JSON.stringify(response.data)
                            )
                        );
                    } else {
                        return response.data;
                    }
                }
            })
            .catch((error) => {
                toast.error(`Failed to submit answer`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.log("Answered question failed", JSON.stringify(error));

                return;
            });
    };

    const onSubmitBaseQuestion = async (answer, url) => {
        let data = JSON.stringify({
            answer: `${answer}`,
        });

        console.log(config);
        console.log(config);
        var config = {
            method: "patch",
            url: `${API_ENDPOINT}buyonline${url}`,
            headers: {
                Authorization: `${AUTH_KEY}`,
                "Content-Type": "application/json",
            },
            data: data,
        };


        return await axios(config)
            .then((response) => {
                if (response) {
                    if (response.data.success === false) {
                        toast.warn(`Failed to submit answer`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        return Promise.reject(
                            console.log("Base question failed", JSON.stringify(response.data))
                        );
                    } else {
                        return response.data;
                    }
                }
            })
            .catch((error) => {
                toast.error(`Failed to submit base answer`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.log("Base question failed", JSON.stringify(error));

                return;
            });
    };

    function decimalSeparator(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleSliderChange = (e) => {
        let premiumAmount = monthlyPremium[Math.floor(e.target.value)];
        setPremium(premiumAmount);

        let coverAmount = monthlySlider[Math.floor(e.target.value)];
        setCover(coverAmount);
    };

    const nextQuestion = async (e) => {
        let currentIndex = quoteQuestionIndex;
        let prevIndex = quoteQuestionIndex;
        let answer;
        console.log(currentIndex);
        console.log(quoteResponse.questions[quoteQuestionIndex]);

        console.log("yesChecked: " + yesChecked.current);
        console.log("noChecked: " + noChecked.current);
        if ( quoteResponse.questions[quoteQuestionIndex]?.code === "Medical_Search_3_Coronavirus" ) {
            document.querySelector('.question-block').style.visibility = 'collapse';
            document.querySelector('.medical-search-dropdown').style.visibility = 'collapse';
            document.getElementById('loading-icon').style.display = 'block';

            answer = vaccinationStatusRef.current;
            noChecked.current = false;
            yesChecked.current = false;
            currentIndex = currentIndex + 1;


            let result = await onSubmitDisclosure(answer, quoteResponse.questions[quoteQuestionIndex].url, quoteResponse.questions[quoteQuestionIndex].method);

            if (result) {
                noChecked.current = false;
                yesChecked.current = false;

                setQuoteQuestionIndex(currentIndex);
                setCheckBoxDisabled(false);
            }
            document.querySelector('.question-block').style.visibility = 'visible';
            document.getElementById('loading-icon').style.display = 'none';
            return;

        } else if (quoteQuestionIndex === quoteQuestionCount) {
            // Proceed to next page
            currentIndex = currentIndex + 1;
        } else {
            if (!yesChecked && !noChecked) {
                toast.error(`Tick yes or no before clicking on next`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return false;
            }
            console.log("base question clicked");
            answer = false;
            if (yesChecked.current) {
                answer = true;
            }
            if (noChecked.current) {
                currentIndex = currentIndex + 1;
            }
        }

        console.log("CURRENT INDEX: " + currentIndex, " / ", quoteQuestionCount);

        if (currentIndex > quoteQuestionCount) {
            toast.success("Question's saved successfully!", {
                position: toast.POSITION.TOP_RIGHT,
            });

            let uw_status = await axios.get(`${API_ENDPOINT}buyonline/check-uw-status/${quoteResponse.case_id}`);
            let uw_eligible = await uw_status.data.eligible;
            if (uw_eligible) {
                setShow(true);
            } else {
                let dropOff = applicationModal.find((x) => x.id === 7);
                dropOff.messageSection1 = dropOff.messageSection1.replace(
                    `{0}`,
                    productKey
                );
                setDropOffModel(dropOff);
                setShowDropOffModal(true);

                console.log("AXIOS POST LEAD DROP OFF");
                // AXIOS POST LEAD DROP OFF
                var config = {
                    method: "post",
                    url: `${API_ENDPOINT}buyonline/submit-lead/by-quote-id/${quoteResponse.quote_id}`,
                    headers: {
                        Authorization: `${AUTH_KEY}`,
                        "Content-Type": "application/json",
                    },
                };

                return await axios(config)
                    .then((response) => {
                        return;
                    })
                    .catch((error) => {
                        toast.error(`An error occured while submitting drop-off lead`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });

                        return;
                    });
            }
            return;
        }

        /* SUBMITTING BASE QUESTION */
        let result = await onSubmitBaseQuestion(answer, quoteResponse.questions[quoteQuestionIndex].url);

        if (result) {
            console.log("SHOW UW RESULT: ");
            console.log(result);

            noChecked.current = false;
            yesChecked.current = false;
            if (result.complete === true) {
                if (answer === true) {
                    currentIndex = currentIndex + 1;
                }
            } else if ( result.disclosure_required === true ) {

                document.getElementById('yes').parentElement.style.display = 'none';
                document.getElementById('no').parentElement.style.display = 'none';
                document.querySelector('.question-block').style.visibility = 'visible';

                console.log("SHOW REFLEXIVE QUESTION: ");
                setShowReflexiveQuestion(true);
                document.getElementById('loading-icon').style.display = 'none';
                if (typeof(result.options) === "string" && result.options.startsWith("/")) {
                    setReflexiveQuestionPlaceholder("Type at least 3 characters  to see a list of options");
                    showQuestionSearchDropdown(result.options, result.url, result.method, result.text);
                }
                if (typeof(result.options) === "object" ) {
                    setReflexiveQuestionPlaceholder("Select an option");
                    showQuestionDropdown(result.options, result.url, result.method, result.text);
                }
            } else if ( result.complete === false) {
                currentIndex = currentIndex +1;
            }
        }

        if (currentIndex > prevIndex) {
            let newProgress = progress;
            let increaseProgress = currentIndex / quoteQuestionCount * 100;  //parseInt(newProgress + 5);
            console.log("progr: " + increaseProgress);
            setProgress(increaseProgress);
            setQuoteQuestionIndex(currentIndex);
            setCheckBoxDisabled( currentIndex === quoteQuestionCount ? true : false);

            document.getElementById('loading-icon').style.display = 'none';
            document.getElementById('yes').parentElement.style.visibility = 'visible';
            document.getElementById('no').parentElement.style.visibility = 'visible';
            document.querySelector('.question-block').style.visibility = 'visible';

        } else if (currentIndex === prevIndex) {
            document.getElementById('loading-icon').style.display = 'none';
            document.querySelector('.question-block').style.visibility = 'visible';
        }
        return;
    };

    const prevQuestion = (e) => {
        let currentIndex = quoteQuestionIndex;

        setCheckBoxDisabled(false);
        if (currentIndex === 0) {
            return;
        } else {
            currentIndex = currentIndex - 1;


            setShowReflexiveQuestion(false);
            noChecked.current = false;
            yesChecked.current = false;

            setQuoteQuestionIndex(currentIndex);
        }

        let newProgress = progress;
        let decreaseProgress = parseInt(newProgress - 5);
        setProgress(decreaseProgress);
    };


    const showQuestionDropdown = (options, submit_url, method, text) => {
        console.log("SHOW REFLEXIVE QUESTION DROPDOWN: ");
        console.log("Question: ", text);
        setReflexiveQuestion(text);
        setShowReflexiveQuestion(true);
        setReflexiveLookup(options);
        setReflexiveSubmitUrl(submit_url);
        setReflexiveMethod(method);
        return;
    };

    const showQuestionSearchDropdown = (lookup_url, submit_url, method, text) => {
        console.log("SHOW REFLEXIVE SEARCH DROPDOWN: ");
        console.log("Question: ", text);
        setReflexiveQuestion(text);
        setShowReflexiveQuestion(true);
        setReflexiveLookup(lookup_url);
        setReflexiveSubmitUrl(submit_url);
        setReflexiveMethod(method);
        return;
    };

    const onSearchReflexQuestion = (searchTerm) => {
        reflexiveSearchTerm.current = searchTerm ? searchTerm : "";
    };

    const onChangeReflexiveQuestion = (answer) => {
        console.log(answer);
        setReflexiveAnswers(answer);
    };

    const fetchReflexiveData = async () => {
        console.log("fetchReflexiveData");
        console.log(reflexiveLookup);

        if (typeof(reflexiveLookup) === "string" && reflexiveLookup.startsWith("/") && reflexiveSearchTerm.current.length > 2) {
            setReflexiveQuestionPlaceholder("Type at least 3 characters to see a list of options");
            let url = `${API_ENDPOINT}buyonline/${reflexiveLookup.substr(1).split("<")[0]}${reflexiveSearchTerm.current}`;
            console.log("url: ", url);
            var config = {
                method: "get",
                url: url,
                headers: {
                    Authorization: `${AUTH_KEY}`,
                },
            };

            if (reflexiveSearchTerm.current !== "") {
                return await axios(config)
                    .then((response) => {
                        if (response) {
                            console.log(response.data);
                            let options = response.data.conditions.map((item, index) => ({
                                value: index,
                                label: item,
                            }));
                            return options;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error(`An error occurred while filtering options. Please try again.`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        console.log("reflexive fetch", JSON.stringify(error));
                        return;
                    });
            } else {
                return false;
            }
        } else if ( typeof(reflexiveLookup) === "object" ) {
            setReflexiveQuestionPlaceholder("Select an option");
            return reflexiveLookup;
        }
    };

    const submitReflexiveData = async () => {
        document.getElementById('loading-icon').style.display = 'block';
        document.querySelector('.question-block').style.visibility = 'collapse';

        setShowReflexiveQuestion(false);


        console.log("submitReflexiveData");

        console.log(reflexiveSubmitUrl);
        console.log(reflexiveMethod);

        let url = `${API_ENDPOINT}buyonline${reflexiveSubmitUrl}`;

        if (url.indexOf("update_disclosure") > -1) {
            url = url.replace("update_disclosure", "update-disclosure");
        }
        console.log("url: ", url);
        var config = {
            method: reflexiveMethod,
            url: url,
            headers: {
                Authorization: `${AUTH_KEY}`,
            },
            data: {answer: reflexiveAnswers[0].label}
        };


        return await axios(config)
            .then((response) => {
                if (response) {
                    console.log(response.data);
                    let data = response.data;
                    if (data.complete === false) {
                        setShowReflexiveQuestion(true);
                        document.getElementById('loading-icon').style.display = 'none';
                        document.querySelector('.question-block').style.visibility = 'visible';
                        console.log("SETTING REFLEXIVE DATA...");
                        console.log("METHOD: ", data.method);
                        setReflexiveMethod(data.method);
                        console.log("URL: ", data.url);
                        setReflexiveSubmitUrl(data.url);
                        console.log("TEXT: ",  data.current_question.questionText);
                        setReflexiveQuestion(data.current_question.questionText);
                        let answers;
                        if (data.current_question.answerType === "BOOLEAN") {
                            answers = data.current_question.options.map((item, index) => ({
                                value: index,
                                label: item,
                            }));
                        } else {
                            answers = data.current_question.options.map((item, index) => ({
                                value: index,
                                label: item,
                            }));
                        }
                        console.log("ANSWERS: ",  answers);
                        setReflexiveAnswers([]);
                        setReflexiveLookup(answers);
                        setReflexiveQuestionPlaceholder("Select an option");
                    } else if (data.complete === true) {

                        setReflexiveAnswers([]);
                        setReflexiveQuestion(null);
                        setReflexiveMethod(null);
                        let currentIndex = quoteQuestionIndex;
                        currentIndex = currentIndex + 1;
                        setQuoteQuestionIndex(currentIndex);
                        setCheckBoxDisabled(false);

                        document.getElementById('loading-icon').style.display = 'none';

                        document.getElementById('yes').parentElement.style.display = 'flex';
                        document.getElementById('no').parentElement.style.display = 'flex';
                        document.getElementById('yes').parentElement.style.visibility = 'visible';
                        document.getElementById('no').parentElement.style.visibility = 'visible';
                        document.querySelector('.question-block').style.visibility = 'visible';

                    } else if (data.success === false) {
                        setQuoteQuestionIndex(quoteQuestionCount);
                        setReflexiveAnswers([]);
                        setReflexiveQuestion(null);
                        setReflexiveMethod(null);
                        setCheckBoxDisabled(false);
                        setCheckBoxDisabled(true);
                        setProgress(100);

                        document.getElementById('loading-icon').style.display = 'none';
                        document.querySelector('.question-block').style.visibility = 'visible';

                    }
                }
            })
            .catch((error) => {
                setPageLoading(false);
                console.log(error);
                toast.error(`An error occurred while processing, please try again.}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                console.log("reflexive submit", JSON.stringify(error));
                return;
            });

    };


    const closeModal = () => {
        setTimeout(() => {
            setShow(false);
            navigate("/summary");
        }, 100);
    };

    const closeModalDropOff = () => {
        setTimeout(() => {
            localStorage.clear();
            navigate("/");
        }, 100);
    };

    const [showCallUs, setShowCallUs] = useState(false);

    const closeCallUsModal = () => {
        setShowCallUs(false);
        navigate("/");
    };

    const onCallUsClick = () => {
        setShowCallUs(true);
    };

    const goBack = () => {
        setShowCallUs(false);
        return;
    };

    return (
        <MainContext.Consumer>
          {(value) => (
              <div className="relative bg-light-white px-4 pt-20 pb-20 sm:px-6 lg:px-8 lg:pb-28 lg:pt-28">
                <div className="relative mx-auto lg:pl-36 lg:pr-36">
                  <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-1">
                    <div className="flex flex-col rounded-lg shadow-lg">
                      <div className="flex flex-col bg-white xl:p-6 p-6">
                        <Stepper steps={steps} currentStep={2} />
                      </div>

                      <hr className="line-break pb-5 w-full"></hr>

                      <div className="grid grid-cols-6 gap-6 bg-white">
                        <div className="col-span-6 sm:col-span-4">
                          <div className="flex flex-1 flex-col justify-between bg-white p-6">
                            {/* Additional Personal Details And Marketing Consent */}
                            <div>
                              <h3 className="text-3xl font-normal leading-6 text-gray pb-2">
                                Additional personal details
                              </h3>
                            </div>

                            <div className="pb-5">
                              <form
                                onSubmit={(event) =>
                                    onSubmitAdditionalDetails(event, value)
                                }
                                id="additional-details-form"
                              >
                                <h3 className="text-2xl font-normal pt-5 leading-6 text-gray pb-2">
                                  Personal Information
                                </h3>

                                <div className="rounded-lg shadow-lg p-5">
                                  <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                                    <div className="col-span-6 sm:col-span-3">
                                      <label
                                        htmlFor="weight"
                                        className="block text-sm font-normal text-gray-500 py-2"
                                      >
                                        <span>WEIGHT (KG)</span>
                                      </label>
                                      <div
                                        className={
                                            isWeightValid
                                                ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                                : "flex border-2 border-red rounded w-full"
                                        }
                                      >
                                        {tooltip && (
                                            <ReactTooltip
                                              id="weight"
                                              place="bottom"
                                              effect="solid"
                                              textColor="#ffffff"
                                              backgroundColor="#0033a1"
                                              scrollHide={true}
                                            >
                                              Leave blank if the above is unknown
                                            </ReactTooltip>
                                        )}

                                        <input
                                          data-tip
                                          data-for="weight"
                                          placeholder="Your weight"
                                          value={weight}
                                          type="number"
                                          name="weight"
                                          id="weight"
                                          autoComplete="weight"
                                          onChange={onChange}
                                          className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                          onMouseEnter={() => showTooltip(true)}
                                          onMouseLeave={() => {
                                              showTooltip(false);
                                              setTimeout(() => showTooltip(true), 50);
                                          }}
                                        />
                                        <span className="inline-flex items-center border-none focus:border-none px-3 pb-2 text-sm text-light-blue">
                                          KG
                                        </span>
                                      </div>
                                      <p
                                        className={
                                            !isWeightValid
                                                ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                                : "hidden"
                                        }
                                      >
                                        WEIGHT INVALID (RANGE: 25kg - 220kg)
                                      </p>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                      <label
                                        htmlFor="height"
                                        className="block text-sm font-normal text-gray-500 py-2"
                                      >
                                        <span>HEIGHT (CM)</span>
                                      </label>
                                      <div
                                        className={
                                            isHeightValid
                                                ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                                : "flex border-2 border-red rounded w-full"
                                        }
                                      >
                                        {tooltip && (
                                            <ReactTooltip
                                              id="height"
                                              place="bottom"
                                              effect="solid"
                                              textColor="#ffffff"
                                              backgroundColor="#0033a1"
                                              scrollHide={true}
                                            >
                                              Leave blank if the above is unknown
                                            </ReactTooltip>
                                        )}
                                        <input
                                          data-tip
                                          data-for="weight"
                                          placeholder="Your height"
                                          value={height}
                                          type="number"
                                          name="height"
                                          id="height"
                                          autoComplete="height"
                                          onChange={onChange}
                                          className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                          onMouseEnter={() => showTooltip(true)}
                                          onMouseLeave={() => {
                                              showTooltip(false);
                                              setTimeout(() => showTooltip(true), 50);
                                          }}
                                        />
                                        <span className="inline-flex items-center border-none focus:border-none px-3 pb-2 text-sm text-light-blue">
                                          CM
                                        </span>
                                      </div>
                                      <p
                                        className={
                                            !isHeightValid
                                                ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                                : "hidden"
                                        }
                                      >
                                        HEIGHT INVALID (RANGE: 80cm - 220cm)
                                      </p>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-6 gap-6 py-2">
                                    <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                      <label className="font-normal text-gray">
                                        <span
                                          className={
                                              !alcholYesChecked && !alcholNoChecked
                                                  ? "after:content-['*'] after:text-red"
                                                  : "after:content-['*']"
                                          }
                                        >
                                          DO YOU DRINK ALCOHOL?
                                        </span>
                                      </label>
                                      <fieldset className="mt-2">
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                          {alcholOptions.map(
                                              (
                                                  alcholOptionsType,
                                                  alcholOptionsTypeIdx
                                              ) => (
                                                  <div
                                                    key={alcholOptionsType.id}
                                                    className="flex items-center"
                                                  >
                                                    {alcholOptionsTypeIdx === 0 ? (
                                                        <input
                                                          required={!alcholNoChecked}
                                                          id={alcholOptionsType.id}
                                                          name={alcholOptionsType.title}
                                                          value={alcholYesChecked}
                                                          type="checkbox"
                                                          checked={alcholYesChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                                        />
                                                    ) : (
                                                        <input
                                                          required={!alcholYesChecked}
                                                          id={alcholOptionsType.id}
                                                          name={alcholOptionsType.title}
                                                          value={alcholNoChecked}
                                                          type="checkbox"
                                                          checked={alcholNoChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                                        />
                                                    )}

                                                    <label
                                                      htmlFor={alcholOptionsType.id}
                                                      className="ml-3 block text-sm font-normal text-gray-500"
                                                    >
                                                      {alcholOptionsType.title}
                                                    </label>
                                                  </div>
                                              )
                                          )}
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>

                                  {alcholYesChecked === true ? (
                                      <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                          <label
                                            htmlFor="alcohol_units"
                                            className="block text-sm font-normal text-gray-500 py-2"
                                          >
                                            <span className="after:content-['*'] after:text-red">
                                              IF YES, HOW MANY UNITS OF ALCOHOL DO YOU
                                              DRINK PER WEEK?
                                            </span>
                                          </label>
                                          <div
                                            className={
                                                isAlcoholUnitsValid
                                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                                    : "flex border-2 border-red rounded w-full"
                                            }
                                          >
                                            {tooltip && (
                                                <ReactTooltip
                                                  id="alcohol_units"
                                                  place="bottom"
                                                  effect="solid"
                                                  textColor="#ffffff"
                                                  backgroundColor="#0033a1"
                                                  scrollHide={true}
                                                >
                                                  1 unit is 1 tot, 1 beer, 1 glass of wine
                                                </ReactTooltip>
                                            )}
                                            <input
                                              data-tip
                                              data-for="alcohol_units"
                                              required={true}
                                              placeholder="How many units"
                                              type="number"
                                              name="alcohol_units"
                                              id="alcohol_units"
                                              autoComplete="alcohol_units"
                                              value={alcoholUnits}
                                              onChange={onChange}
                                              className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                              onMouseEnter={() => showTooltip(true)}
                                              onMouseLeave={() => {
                                                  showTooltip(false);
                                                  setTimeout(() => showTooltip(true), 50);
                                              }}
                                            />
                                          </div>

                                          <p
                                            className={
                                                !isAlcoholUnitsValid
                                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                                    : "hidden"
                                            }
                                          >
                                            INVALID UNITS ENTERED
                                          </p>
                                        </div>
                                      </div>
                                  ) : (
                                      ""
                                  )}
                                </div>

                                <h3 className="text-2xl font-normal pt-5 leading-6 text-gray pb-2">
                                  Marketing Consent
                                </h3>

                                <div className="rounded-lg shadow-lg p-5">
                                  <div className="grid grid-cols-6 gap-6 py-2">
                                    <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                      <label className="font-normal text-gray">
                                        CONSENT TO ALL
                                      </label>
                                      <fieldset className="mt-3">
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                          {marketingConsentAll.map(
                                              (
                                                  marketingConsentAllType,
                                                  marketingConsentAllIdx
                                              ) => (
                                                  <div
                                                    key={marketingConsentAllType.id}
                                                    className="flex items-center"
                                                  >
                                                    {marketingConsentAllIdx === 0 ? (
                                                        <input
                                                          id={marketingConsentAllType.id}
                                                          name={
                                                              marketingConsentAllType.title
                                                          }
                                                          type="checkbox"
                                                          value={consentAllYesChecked}
                                                          checked={consentAllYesChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    ) : (
                                                        <input
                                                          id={marketingConsentAllType.id}
                                                          name={
                                                              marketingConsentAllType.title
                                                          }
                                                          type="checkbox"
                                                          value={consentAllNoChecked}
                                                          checked={consentAllNoChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    )}

                                                    <label
                                                      htmlFor={marketingConsentAllType.id}
                                                      className="ml-3 block text-sm font-normal text-gray-500"
                                                    >
                                                      {marketingConsentAllType.title}
                                                    </label>
                                                  </div>
                                              )
                                          )}
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                      <p className="mt-2 text-gray font-normal">
                                        I consent to be sent information about new
                                        products, services, and special offers from:
                                      </p>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-6 gap-6 py-2">
                                    <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                      <label className="font-normal text-gray">
                                        <span
                                          className={
                                              !consentYesChecked && !consentYesChecked
                                                  ? "after:content-['*'] after:text-red"
                                                  : "after:content-['*']"
                                          }
                                        >
                                          LIBERTY DIRECT SERVICES?
                                        </span>
                                      </label>
                                      <fieldset className="mt-3">
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                          {marketingConsent.map(
                                              (
                                                  marketingConsentType,
                                                  marketingConsentIdx
                                              ) => (
                                                  <div
                                                    key={marketingConsentType.id}
                                                    className="flex items-center"
                                                  >
                                                    {marketingConsentIdx === 0 ? (
                                                        <input
                                                          required={!consentNoChecked}
                                                          id={marketingConsentType.id}
                                                          name={marketingConsentType.title}
                                                          type="checkbox"
                                                          value={consentYesChecked}
                                                          checked={consentYesChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    ) : (
                                                        <input
                                                          required={!consentYesChecked}
                                                          id={marketingConsentType.id}
                                                          name={marketingConsentType.title}
                                                          type="checkbox"
                                                          value={consentNoChecked}
                                                          checked={consentNoChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    )}

                                                    <label
                                                      htmlFor={marketingConsentType.id}
                                                      className="ml-3 block text-sm font-normal text-gray-500"
                                                    >
                                                      {marketingConsentType.title}
                                                    </label>
                                                  </div>
                                              )
                                          )}
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-6 gap-6 py-2">
                                    <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                      <label className="font-normal text-gray">
                                        <span
                                          className={
                                              !consentLibertyYesChecked &&
                                                  !consentLibertyNoChecked
                                                  ? "after:content-['*'] after:text-red"
                                                  : "after:content-['*']"
                                          }
                                        >
                                          FROM THE BROADER LIBERTY GROUP®
                                        </span>
                                      </label>
                                      <fieldset className="mt-3">
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                          {libertyGroupConsent.map(
                                              (
                                                  libertyGroupConsentType,
                                                  libertyGroupConsentIdx
                                              ) => (
                                                  <div
                                                    key={libertyGroupConsentType.id}
                                                    className="flex items-center"
                                                  >
                                                    {libertyGroupConsentIdx === 0 ? (
                                                        <input
                                                          required={
                                                              !consentLibertyNoChecked
                                                          }
                                                          id={libertyGroupConsentType.id}
                                                          name={
                                                              libertyGroupConsentType.title
                                                          }
                                                          type="checkbox"
                                                          value={consentLibertyYesChecked}
                                                          checked={consentLibertyYesChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    ) : (
                                                        <input
                                                          required={
                                                              !consentLibertyYesChecked
                                                          }
                                                          id={libertyGroupConsentType.id}
                                                          name={
                                                              libertyGroupConsentType.title
                                                          }
                                                          type="checkbox"
                                                          value={consentLibertyNoChecked}
                                                          checked={consentLibertyNoChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    )}

                                                    <label
                                                      htmlFor={libertyGroupConsentType.id}
                                                      className="ml-3 block text-sm font-normal text-gray-500"
                                                    >
                                                      {libertyGroupConsentType.title}
                                                    </label>
                                                  </div>
                                              )
                                          )}
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-6 gap-6 py-2">
                                    <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                      <label className="font-normal text-gray">
                                        <span
                                          className={
                                              !consentOtherYesChecked &&
                                                  !consentOtherNoChecked
                                                  ? "after:content-['*'] after:text-red"
                                                  : "after:content-['*']"
                                          }
                                        >
                                          FROM ANY OTHER COMPANIES WE MAY PARTNER
                                          WITH?
                                        </span>
                                      </label>
                                      <fieldset className="mt-3">
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                          {otherConsent.map(
                                              (otherConsentType, otherConsentIdx) => (
                                                  <div
                                                    key={otherConsentType.id}
                                                    className="flex items-center"
                                                  >
                                                    {otherConsentIdx === 0 ? (
                                                        <input
                                                          required={!consentOtherNoChecked}
                                                          id={otherConsentType.id}
                                                          name={otherConsentType.title}
                                                          type="checkbox"
                                                          value={consentOtherYesChecked}
                                                          checked={consentOtherYesChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    ) : (
                                                        <input
                                                          required={!consentOtherYesChecked}
                                                          id={otherConsentType.id}
                                                          name={otherConsentType.title}
                                                          type="checkbox"
                                                          value={consentOtherNoChecked}
                                                          checked={consentOtherNoChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    )}

                                                    <label
                                                      htmlFor={otherConsentType.id}
                                                      className="ml-3 block text-sm font-normal text-gray-500"
                                                    >
                                                      {otherConsentType.title}
                                                    </label>
                                                  </div>
                                              )
                                          )}
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-6 gap-6 py-2">
                                    <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                      <label className="font-normal text-gray">
                                        <span
                                          className={
                                              !consentResearchYesChecked &&
                                                  !consentResearchNoChecked
                                                  ? "after:content-['*'] after:text-red"
                                                  : "after:content-['*']"
                                          }
                                        >
                                          DO YOU CONSENT TO BEING CONTACTED FOR
                                          RESEARCH PURPOSES, BEARING IN MIND THAT WE
                                          FOLLOW STRICT CODES OF CONDUCT AND TREAT
                                          CLIENT INFORMATION WITH THE UTMOST
                                          CONFIDENTIALITY?
                                        </span>
                                      </label>
                                      <fieldset className="mt-3">
                                        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                          {researchConsent.map(
                                              (
                                                  researchConsentType,
                                                  researchConsentIdx
                                              ) => (
                                                  <div
                                                    key={researchConsentType.id}
                                                    className="flex items-center"
                                                  >
                                                    {researchConsentIdx === 0 ? (
                                                        <input
                                                          required={
                                                              !consentResearchNoChecked
                                                          }
                                                          id={researchConsentType.id}
                                                          name={researchConsentType.title}
                                                          type="checkbox"
                                                          value={consentResearchYesChecked}
                                                          checked={
                                                              consentResearchYesChecked
                                                          }
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    ) : (
                                                        <input
                                                          required={
                                                              !consentResearchYesChecked
                                                          }
                                                          id={researchConsentType.id}
                                                          name={researchConsentType.title}
                                                          type="checkbox"
                                                          value={consentResearchNoChecked}
                                                          checked={consentResearchNoChecked}
                                                          onChange={checkValue}
                                                          className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                                                        />
                                                    )}

                                                    <label
                                                      htmlFor={researchConsentType.id}
                                                      className="ml-3 block text-sm font-normal text-gray-500"
                                                    >
                                                      {researchConsentType.title}
                                                    </label>
                                                  </div>
                                              )
                                          )}
                                        </div>
                                      </fieldset>
                                    </div>
                                  </div>

                                  <div className="flex py-5">
                                    <div className="w-full flex justify-center">
                                      <button
                                        className="bg-btn-primary-color font-bold xl:w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                                        type="submit"
                                        onClick={onContinue}
                                      >
                                        <span>CONTINUE</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>

                            {/* Quote Section */}
                            {showQuoteSection ? (
                                <>
                                  <div className="pb-5">
                                    <form
                                      onSubmit={(event) => onSubmit(event, value)}
                                      id="personal-details-form"
                                    >
                                      <h3 className="text-3xl font-normal leading-6 text-gray pb-2">
                                        Select a cover amount
                                      </h3>

                                      <h3 className="text-2xl font-normal pt-5 leading-6 text-gray pb-2">
                                        Your {currentCoverName} Quote
                                      </h3>

                                      <div className="rounded-lg shadow-lg p-5">
                                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6 pb-5">
                                          <div className="col-span-6 sm:col-span-3">
                                            <label className="text-gray-500 font-normal text-md">
                                              YOUR COVER
                                            </label>
                                            <p className="text-5xl text-gray font-normal py-5">
                                              <span className="text-sm font-bold text-light-blue mr-2">
                                                R
                                              </span>
                                              {cover}
                                            </p>
                                          </div>
                                          <div className="col-span-6 sm:col-span-3">
                                            <label className="text-gray-500 font-normal text-md">
                                              YOUR PREMIUM
                                            </label>
                                            <p className="text-5xl text-gray font-normal py-5">
                                              <span className="text-sm font-bold text-light-blue mr-2">
                                                R
                                              </span>
                                              {premium}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6 pb-5">
                                          <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                            <div className="range-slider__slider w-full">
                                              <input
                                                type="range"
                                                min={0}
                                                step={0.5}
                                                max={monthlySlider?.length - 1}
                                                className="slider"
                                                id="rangeSlider"
                                                onChange={handleSliderChange}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <p className="text-xs text-gray-500 mt-2 font-normal">
                                          * THE COVER AND PREMIUM AMOUNTS ARE BASED ON
                                          MINIMAL INFORMATION AND ARE SUBJECT TO CHANGE
                                        </p>

                                        <p className="pt-2 text-gray font-normal pb-2">
                                          Already got cover with another insurance
                                          company?
                                        </p>
                                        <fieldset className="mt-2 pb-5">
                                          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                            {alreadyGotCover.map(
                                                (
                                                    alreadyGotCoverType,
                                                    alreadyGotCoverTypeIdx
                                                ) => (
                                                    <div
                                                      key={alreadyGotCoverType.id}
                                                      className="flex items-center"
                                                    >
                                                      {alreadyGotCoverTypeIdx === 0 ? (
                                                          <input
                                                            required={!gotCoverNoChecked}
                                                            id={alreadyGotCoverType.id}
                                                            name={alreadyGotCoverType.title}
                                                            value={gotCoverYesChecked}
                                                            type="checkbox"
                                                            checked={gotCoverYesChecked}
                                                            onChange={checkValue}
                                                            className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                                          />
                                                      ) : (
                                                          <input
                                                            required={!gotCoverYesChecked}
                                                            id={alreadyGotCoverType.id}
                                                            name={alreadyGotCoverType.title}
                                                            value={gotCoverNoChecked}
                                                            type="checkbox"
                                                            checked={gotCoverNoChecked}
                                                            onChange={checkValue}
                                                            className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                                          />
                                                      )}

                                                      <label
                                                        htmlFor={alreadyGotCoverType.id}
                                                        className="ml-3 block text-sm font-light text-gray-500"
                                                      >
                                                        {alreadyGotCoverType.title}
                                                      </label>
                                                    </div>
                                                )
                                            )}
                                          </div>
                                        </fieldset>

                                        {gotCoverYesChecked ? (
                                            <div className="grid grid-cols-6 sm:gap-0 xl:gap-6 pb-5">
                                              <div className="col-span-6 sm:col-span-3">
                                                <label
                                                  htmlFor="existingCoverAmount"
                                                  className="block text-sm font-normal text-gray-500 py-2"
                                                >
                                                  <span
                                                    className={
                                                        existingCoverAmount === 0
                                                            ? "after:content-['*'] after:text-red"
                                                            : "after:content-['*']"
                                                    }
                                                  >
                                                    EXISTING COVER AMOUNT
                                                  </span>
                                                </label>
                                                <div
                                                  className={
                                                      isExistingCoverAmountValid
                                                          ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                                          : "flex border-2 border-red rounded w-full"
                                                  }
                                                >
                                                  <span className="inline-flex items-center border-none focus:border-none px-3 text-sm text-light-blue">
                                                    R
                                                  </span>
                                                  <input
                                                    required={true}
                                                    placeholder="Cover amount"
                                                    type="number"
                                                    name="existingCoverAmount"
                                                    id="existingCoverAmount"
                                                    autoComplete="existingCoverAmount"
                                                    value={existingCoverAmount}
                                                    onChange={onChange}
                                                    className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                                  />
                                                </div>
                                                <p
                                                  className={
                                                      !isExistingCoverAmountValid
                                                          ? "peer-invalid:visible text-red text-sm font-bold pb-2 pt-2"
                                                          : "hidden"
                                                  }
                                                >
                                                  COVER AMOUNT INVALID
                                                </p>
                                              </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}

                                        <div className="flex py-5">
                                          <div className="w-full flex justify-center xl:justify-center">
                                            <button
                                              onClick={onCallUsClick}
                                              className="bg-blue mr-2 font-bold xl:w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-blue hover:bg-light-blue hover:text-white hover:border-light-blue border-solid border-2"
                                            >
                                              <span>CALL ME</span>
                                            </button>
                                            <button
                                              className="bg-orange ml-2 font-bold xl:w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-orange hover:bg-orange hover:text-white hover:border-orange border-solid border-2 cover-amount"
                                              type="submit"
                                              onClick={onClick}
                                            >
                                              <span>SUBMIT</span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </>
                            ) : (
                                ""
                            )}

                            {/* Underwriting Section --> Medical History Questions */}
                            {showContent ? (
                                <>
                                  <h3 className="text-3xl font-normal leading-6 text-gray pb-2">
                                    Underwriting questions
                                  </h3>

                                  <h3 className="text-2xl font-normal pt-5 leading-6 text-gray pb-2">
                                    Medical History
                                  </h3>

                                  <div className="rounded-lg shadow-lg p-5">
                                    <div className="grid grid-cols-6 gap-6 pb-5 bg-white-100">
                                      <div className="col-span-6 xl:col-span-6 sm:col-span-3 position-relative">
                                        <div className="mt-5 pr-5 pl-5">
                                          <ProgressBar progressPercentage={progress} />
                                        </div>

                                        <div className={ loadingPerc > 0 ? "mt-5 pr-5 pl-5 position-absolute loadingBar" : "mt-5 pr-5 pl-5 hidden " }>
                                          <ProgressBar progressPercentage={loadingPerc} />
                                        </div>

                                        <div className="grid grid-cols-6 gap-0 pt-4 xl:h-32 sm:h-60">
                                          <div className="col-span-1 xl:col-span-1 sm:col-span-1"></div>
                                          {quoteQuestionIndex === quoteQuestionCount ? (
                                              <div className="col-span-4 xl:col-span-4 sm:col-span-4">
                                                <div className="text-center relative">
                                                  <div className="flex justify-center items-center font-normal text-gray">
                                                    <span>
                                                      You have completed all medical questions - click
                                                      continue to proceed.
                                                      <div className="flex justify-center items-center font-normal pt-5">
                                                        <button
                                                          className="bg-btn-primary-color font-bold xs:w-32 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                                                          type="button"
                                                          onClick={(e)=> { nextQuestion(e);}}
                                                        >
                                                          <span>Continue</span>
                                                        </button>
                                                      </div>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                          ) : (
                                              <div className="col-span-6 xl:col-span-6 sm:col-span-6">
                                                <div className="question-block flex px-6 justify-between items-center font-normal text-gray">
                                                  <span
                                                    className={
                                                        quoteQuestionIndex < quoteQuestionCount
                                                            ? "after:content-['*'] after:text-red"
                                                            : "after:content-['']"
                                                    }
                                                  >
                                                    { quoteResponse.questions[
                                                         quoteQuestionIndex
                                                     ]?.text}
                                                  </span>
                                                </div>
                                              </div>
                                          )}
                                        </div>

                                        <div className="text-center relative uw-question-container">


                                          <fieldset>
                                            <div
                                              className={"flex justify-center"}
                                            >
                                              <div id="loading-icon" style={{display: "none"}}>
                                                <svg
                                                  version="1.1"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                                  x="0px"
                                                  y="0px"
                                                  width="40px"
                                                  height="40px"
                                                  viewBox="0 0 50 50"
                                                  style={{ enableBackground: "new 0 0 50 50" }}
                                                  xmlSpace="preserve"
                                                >
                                                  <path
                                                    fill="#0033a1"
                                                    d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                                                  >
                                                    <animateTransform
                                                      attributeType="xml"
                                                      attributeName="transform"
                                                      type="rotate"
                                                      from="0 25 25"
                                                      to="360 25 25"
                                                      dur="0.6s"
                                                      repeatCount="indefinite"
                                                    />
                                                  </path>
                                                </svg>
                                              </div>
                                              {quoteQuestionIndex < quoteQuestionCount && medicalQuestionsOptions.map(
                                                  (optionsType, optionsTypeIdx) => (
                                                      <div
                                                        key={optionsType.id}
                                                        className={
                                                            quoteResponse.questions[
                                                                quoteQuestionIndex
                                                            ]?.code ===
                                                                "Medical_Search_3_Coronavirus"
                                                                ? "hidden"
                                                                :  "flex items-center p-5"  }
                                                      >
                                                        {optionsTypeIdx === 0 ? (
                                                            <input
                                                              required={!noChecked.current}
                                                              id={optionsType.id}
                                                              name={optionsType.title}
                                                              type="checkbox"
                                                              disabled={checkBoxValue}
                                                              value={yesStateChecked}
                                                              checked={yesChecked.current}
                                                              onChange={ onMedicalQuestionChecked }
                                                              className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full uw-input"
                                                            />
                                                        ) : (
                                                            <input
                                                              required={!yesChecked.current}
                                                              id={optionsType.id}
                                                              name={optionsType.title}
                                                              type="checkbox"
                                                              disabled={checkBoxValue}
                                                              value={noStateChecked}
                                                              checked={noChecked.current}
                                                              onChange={ onMedicalQuestionChecked }
                                                              className="h-6 w-6 border-light-blue text-light-blue checked:bg-light-blue rounded-full uw-input"
                                                            />
                                                        )}

                                                        <label
                                                          htmlFor={optionsType.id}
                                                          className="ml-3 block text-sm font-normal text-gray-500"
                                                        >
                                                          {optionsType.title}
                                                        </label>
                                                      </div>
                                                  )
                                              )}
                                            </div>
                                          </fieldset>
                                        </div>

                                        {quoteResponse.questions[quoteQuestionIndex]
                                         ?.code === "Medical_Search_3_Coronavirus" ? (
                                             <>
                                               <div className="items-center relative px-6 medical-search-dropdown">
                                                 <div className="grid grid-cols-6 gap-6">
                                                   <div className="col-span-6">
                                                     <label
                                                       htmlFor="vaccinationStatus"
                                                       className="block text-sm font-normal text-gray-500 py-2"
                                                     >
                                                       <span>
                                                         Select vaccination status
                                                       </span>
                                                     </label>
                                                     <div className="text-gray pb-2">
                                                       <Select
                                                         className="text-sm font-light text-gray uw-input"
                                                         classNamePrefix="vaccinationStatus"
                                                         placeholder={
                                                             "Your vaccination status"
                                                         }
                                                         isDisabled={false}
                                                         isLoading={false}
                                                         isClearable={true}
                                                         isRtl={false}
                                                         isSearchable={true}
                                                         styles={customStyles}
                                                         onChange={(value) =>
                                                             onVaccinationChange(value)
                                                         }
                                                         value={vaccinationStatus}
                                                         options={vaccination}
                                                       />
                                                     </div>
                                                   </div>
                                                 </div>
                                               </div>
                                             </>
                                         ) : showReflexiveQuestion ? (
                                             <>
                                               <div className="items-center relative px-6">
                                                 <div className="grid grid-cols-6 gap-6">
                                                   <div className="col-span-6">
                                                     <label
                                                       htmlFor="reflexiveQuestion"
                                                       className="block text-sm font-normal text-gray-500 py-2"
                                                     >
                                                       <span>
                                                         {reflexiveQuestion}
                                                       </span>
                                                     </label>
                                                     <div className="text-gray pb-2">

                                                       <AsyncSelect
                                                         id="reflexive-select"
                                                         className="text-sm font-light text-gray"
                                                         classNamePrefix="reflexive-question"
                                                         placeholder={reflexiveQuestionPlaceholder}
                                                         isDisabled={false}
                                                         isLoading={false}
                                                         isClearable={true}
                                                         isMulti={true}
                                                         isRtl={false}
                                                         isSearchable={true}
                                                         styles={customStyles}
                                                         onChange={(value) => onChangeReflexiveQuestion(value)}
                                                         value={reflexiveAnswers}
                                                         onInputChange={(value) =>
                                                             onSearchReflexQuestion(value)
                                                         }
                                                         defaultOptions={typeof(reflexiveLookup) === "object" ? reflexiveLookup : [] }
                                                         loadOptions={fetchReflexiveData}
                                                       />
                                                     </div>
                                                   </div>
                                                 </div>
                                               </div>

                                               <button
                                                 className="bg-btn-primary-color font-bold xs:w-32 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2 mx-auto flex"
                                                 type="button"
                                                 onClick={submitReflexiveData}
                                               >
                                                 <span>Next</span>
                                               </button>

                                             </>
                                         ) : (
                                             ""
                                         )}

                                        <div className="p-6">
                                          <div className="flex flex-1 flex-row justify-between w-full">
                                            <div className="items-center">
                                              { quoteQuestionIndex > 0 && (
                                                  <button
                                                    className="bg-btn-primary-color font-bold xs:w-32 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                                                    type="button"
                                                    onClick={prevQuestion}
                                                  >
                                                    <span>Prev</span>
                                                  </button>
                                              )}
                                            </div>


                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                            ) : (
                                <></>
                            )}
                          </div>
                        </div>
                        <HonestyModal show={show} closeModal={closeModal} />
                        <CallUsModal
                          show={showCallUs}
                          closeModal={closeCallUsModal}
                          goBack={goBack}
                        />

                        <ApplicationModal
                          dropOffModel={dropOffModel}
                          dropOffId={dropOffId}
                          show={showDropOffModal}
                          closeModal={closeModalDropOff}
                        />

                        <div className="col-span-6 sm:col-span-2">
                          <div className="flex flex-1 flex-col justify-between bg-white pt-1 pb-6 px-6 xl:p-6 sticky top-0">
                            <QuoteInformation></QuoteInformation>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Loading
                  show={pageLoading}
                  closeLoaderModal={closeLoader}
                />
              </div>
          )}
        </MainContext.Consumer>
    );
}
