import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import TermsPDF from "../../assets/terms/product_disclosures/20240503_SBDLIS_Buy_Online_Terms_and_Conditions.pdf";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export default function Content() {
  const navigate = useNavigate();

  const [consultant, setConsultant] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    code: "",
  });

  const [isValid, setIsValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);

  const { fullName, email, contactNumber, code } = consultant;

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function isValidName(name) {
    return /([a-zA-Z0-9_(),@'/-])/gm.test(name);
  }

  function isValidCode(code) {
    return /([a-zA-Z0-9_(),@'/-])/gm.test(code);
  }

  function isValidNumber(contactNumber) {
    return /^[0-9\b]+$/.test(contactNumber);
  }

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const onChange = (event) => {
    if (event.target.name === "fullName") {
      if (!isValidName(event.target.value)) {
        setIsNameValid(false);
      } else {
        setIsNameValid(true);
      }

      if (event.target.value === "") {
        setIsNameValid(true);
      }
    }

    if (event.target.name === "email") {
      if (!isValidEmail(event.target.value)) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }

      if (event.target.value === "") {
        setIsValid(true);
      }
    }

    if (event.target.name === "contactNumber") {
      if (!isValidNumber(event.target.value)) {
        setIsNumberValid(false);
      } else {
        setIsNumberValid(true);
      }

      if (event.target.value.length < 10 || event.target.value.length > 10) {
        setIsNumberValid(false);
      } else {
        setIsNumberValid(true);
      }

      if (event.target.value === "") {
        setIsNumberValid(true);
      }
    }

    if (event.target.name === "code") {
      if (!isValidCode(event.target.value)) {
        setIsCodeValid(false);
      } else {
        setIsCodeValid(true);
      }

      if (event.target.value === "") {
        setIsCodeValid(true);
      }
    }

    setConsultant({ ...consultant, [event.target.name]: event.target.value });
  };

  const onClick = () => {
    navigate("/application");
  };

  return (
    <>
      <div className="relative bg-light-white px-4 pt-5 pb-20 sm:px-6 lg:px-12 lg:pt-10 lg:pb-28">
        <div className="relative mx-auto lg:pl-36 lg:pr-36">
          <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-1">
            <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <div className="mt-2 block">
                    <h1 className="mb-5 text-gray font-normal leading-6 text-3xl">
                      Welcome
                    </h1>

                    <p className="mb-5 text-gray font-normal">
                      Before you go ahead...
                    </p>
                    <p className="mb-5 text-gray font-normal">
                      Here are a few things you should know.
                    </p>
                    <p className="mb-5 text-gray font-normal">
                      Reminder that when you click to move on, you are
                      confirming the information on each page as being accurate
                      and accepting any terms and conditions or disclaimers
                      listed on each page.
                    </p>
                    <p className="mb-5 text-gray font-normal">
                      The information you provide makes up the basis of our
                      agreement and it can have an effect on the cover you will
                      be offered and the premiums you will pay.
                    </p>
                    <p className="mb-5 text-gray font-normal">
                      If the process is stopped, whether by choice or due to
                      technical issues, a friendly sales agent will call you
                      within minutes to assist you (during office hours of 8am
                      to 6pm). They will take you through the telephonic
                      application and you may have to provide your details
                      again.
                    </p>

                    <p className="text-gray font-bold">Insurer:</p>
                    <p className="text-gray mb-5 font-normal">
                      Products are branded by Standard Bank and are underwritten
                      by Liberty Group Limited, a licensed life insurer and an
                      authorised financial services provider (FSP 2409). We do
                      not offer advice, we provide relevant product information
                      so that you can make an informed decision.
                    </p>
                    <p className="mb-5 text-gray font-normal">
                      Full terms and conditions of the products and agreement
                      are provided during the process, you can also read them
                      <a
                        href={TermsPDF}
                        target="_blank"
                        className="border-b-2 border-transparent hover:border-light-blue text-light-blue font-bold"
                        rel="noreferrer"
                      >
                        {" "}
                        here.
                      </a>
                    </p>
                    <p className="mb-5 text-gray font-normal">
                      By clicking to continue, you confirm that you consent to
                      the above.
                    </p>
                  </div>
                </div>

                {/* Only commented out might be used in future */}
                {/* <hr className="line-break mb-5 w-full"></hr> */}
                {/* <form onSubmit={onSubmit} id="welcome-form">
                  <div>
                    <h3 className="text-xl font-normal leading-6 text-gray pb-5">
                      Consultant Details
                    </h3>
                  </div>

                  <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="fullName"
                        className="block text-sm font-normal text-gray-500 pb-2"
                      >
                        CONSULTANT FULL NAME
                      </label>
                      <input
                        type="text"
                        name="fullName"
                        id="fullName"
                        autoComplete="fullName"
                        value={fullName}
                        onChange={onChange}
                        className={
                          isNameValid
                            ? "mt-1 block w-full rounded border text-gray border-gray-500 xl:py-2 px-3 shadow-sm focus:border-blue focus:outline-none focus:ring-blue sm:text-sm"
                            : "mt-1 block w-full rounded border text-gray border-gray-500 xl:py-2 px-3 shadow-sm focus:border-red focus:outline-none focus:ring-red sm:text-sm"
                        }
                      />
                      <p
                        className={
                          !isNameValid && fullName.length > 0
                            ? "peer-invalid:visible text-red font-light"
                            : "invisible"
                        }
                      >
                        Please enter a valid name
                      </p>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-normal text-gray-500 pb-2"
                      >
                        CONSULTANT EMAIL ADDRESS
                      </label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        autoComplete="email"
                        value={email}
                        onChange={onChange}
                        className={
                          isValid
                            ? "mt-1 block w-full rounded border text-gray border-gray-500 xl:py-2 px-3 shadow-sm focus:border-blue focus:outline-none focus:ring-blue sm:text-sm"
                            : "mt-1 block w-full rounded border text-gray border-gray-500 xl:py-2 px-3 shadow-sm focus:border-red focus:outline-none focus:ring-red sm:text-sm"
                        }
                      />
                      <p
                        className={
                          !isValid && email.length > 0
                            ? "peer-invalid:visible text-red font-light"
                            : "invisible"
                        }
                      >
                        Please enter a valid email
                      </p>
                    </div>
                  </div>

                  <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="contactNumber"
                        className="block text-sm font-normal text-gray-500 pb-2"
                      >
                        CONSULTANT CONTACT NUMBER
                      </label>
                      <input
                        type="text"
                        name="contactNumber"
                        id="contactNumber"
                        autoComplete="contactNumber"
                        value={contactNumber}
                        onChange={onChange}
                        className={
                          isNumberValid
                            ? "mt-1 block w-full rounded border text-gray border-gray-500 xl:py-2 px-3 shadow-sm focus:border-blue focus:outline-none focus:ring-blue sm:text-sm"
                            : "mt-1 block w-full rounded border text-gray border-gray-500 xl:py-2 px-3 shadow-sm focus:border-red focus:outline-none focus:ring-red sm:text-sm"
                        }
                      />
                      <p
                        className={
                          !isNumberValid
                            ? "peer-invalid:visible text-red font-light"
                            : "invisible"
                        }
                      >
                        Please enter a valid contact number
                      </p>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="code"
                        className="block text-sm font-normal text-gray-500 pb-2"
                      >
                        CONSULTANT CODE
                      </label>
                      <input
                        type="text"
                        name="code"
                        id="code"
                        autoComplete="code"
                        value={code}
                        onChange={onChange}
                        className={
                          isCodeValid
                            ? "mt-1 block w-full rounded border text-gray border-gray-500 xl:py-2 px-3 shadow-sm focus:border-blue focus:outline-none focus:ring-blue sm:text-sm"
                            : "mt-1 block w-full rounded border text-gray border-gray-500 xl:py-2 px-3 shadow-sm focus:border-red focus:outline-none focus:ring-red sm:text-sm"
                        }
                      />
                      <p
                        className={
                          !isCodeValid && code.length > 0
                            ? "peer-invalid:visible text-red font-light"
                            : "invisible"
                        }
                      >
                        Please enter a valid code
                      </p>
                    </div>
                  </div>

                  <div className="bg-white px-4 py-5 text-center sm:px-6">
                    <button
                      className="bg-btn-primary-color xl:w-100 w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                      type="submit"
                      onClick={onClick}
                    >
                      <span>CONTINUE</span>
                    </button>
                    <ToastContainer />
                  </div>
                </form> */}
              </div>

              <hr className="line-break pb-5 w-full"></hr>
              <div className="bg-white p-5 justify-center text-center">
                <button
                  className="bg-btn-primary-color xl:w-100 w-64 py-2 px-4 font-bold sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                  type="submit"
                  onClick={onClick}
                >
                  <span>CONTINUE</span>
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
