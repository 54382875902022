import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { MainContext } from "../App";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import {
  educationTypes,
  genderTypes,
  products,
  smokedOptions,
  productCover,
} from "../models/DataModels";
import { useNavigate } from "react-router";
import QuickQuoteInformation from "../components/content/QuickQuoteInformation";
import ApplicationModal from "../components/modals/ApplicationModal";
import { applicationModal } from "../models/DataModels";
import Loading from "../components/modals/Loading";

export default function QuickQuote() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isAltNumberValid, setIsAltNumberValid] = useState(true);
  const [isAgeValid, setIsAgeValid] = useState(true);
  const [isIdNumberValid, setIsIdNumberValid] = useState(true);
  const [isIncomeValid, setIsIncomeValid] = useState(true);
  const [educationType, setEducationType] = useState(null);
  const [genderType, setGenderType] = useState(null);
  const [smokeChecked, setSmokeChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [nonSmokeChecked, setNonSmokeChecked] = useState(false);
  const [hasQualifiedForCover, setHasQualifiedForCover] = useState(false);
  const [qualified_product, setQualifiedProduct] = useState("");
  const [premium, setPremium] = useState(0);
  const [cover, setCover] = useState(0);
  const [monthlySlider, setMonthlySlider] = useState([0]);
  const [monthlyPremium, setMonthlyPremium] = useState([0]);
  const [minCover, setMinCover] = useState(0);
  const [maxCover, setMaxCover] = useState(0);
  const [staticData, setStaticData] = useState({});
  const [affinity, setAffinity] = useState("");
  const [productCode, setProductCode] = useState();
  const [show, setShow] = useState(false);
  const [dropOffModel, setDropOffModel] = useState({
    id: 0,
    image: "",
    dropOff: "",
    title: "",
    messageSection1: "",
    messageSection2: "",
    messageSection3: "",
    messageSection4: "",
    messageSection5: "",
  });
  const [userAge, setAge] = useState(0);
  const [userGender, setGender] = useState("");
  const [userIsCitizen, setIsCitizen] = useState(false);
  const [dropOffId, setDropOffId] = useState(1);
  const [data, setData] = useState([]);
  const setCustId = (id) => {
    setData(id);
    localStorage.setItem("custId", JSON.stringify(id));
  };
  const [quoteUserDetails, setQuoteUserDetails] = useState({
    product: "",
    first_name: "",
    id_number: "",
    last_name: "",
    contact_number: "",
    alternative_contact_number: "",
    email_address: "",
    income: 0,
    qualification: "",
    citizenship: "",
    gender: "",
    smoker: false,
  });

  const {
    first_name,
    last_name,
    contact_number,
    alternative_contact_number,
    email_address,
    income,
    id_number,
    gender,
    qualification,
    smoker,
  } = quoteUserDetails;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "2px",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#3C4B6C",
    }),
    control: (styles, state) => ({
      ...styles,
      borderColor: "#858D9D",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #0033a1",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const productKey = JSON.parse(localStorage.getItem("productKey"));

  useEffect(() => {
    const validate = async () => {
      let selectedProduct = "";

      if (productKey) {
        setQualifiedProduct(productKey);
        if (productKey === "Serious Illness") {
          let productToFind = "Serious Illness Cover";
          selectedProduct = products.find((x) => x.id === productToFind).value;
          setQuoteUserDetails({
            ...quoteUserDetails,
            product: selectedProduct,
          });
        } else {
          selectedProduct = products.find((x) => x.id === productKey).value;
          setQuoteUserDetails({
            ...quoteUserDetails,
            product: selectedProduct,
          });
        }
      }

      if ((await cookies.productSlug) === undefined) {
        sessionStorage.setItem("invalidPath", true);
        window.location = "/";
      }
    };
    validate();

    const getStaticData = async () => {
      var config = {
        method: "get",
        url: `${API_ENDPOINT}buyonline/static-data`,
        headers: {
          Authorization: `${AUTH_KEY}`,
        },
      };

      return await axios(config)
        .then((response) => {
          if (response) {
            if (response.data.success === false) {
              toast.warn(`Failed to get data`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              return Promise.reject(
                console.log("Get static data", JSON.stringify(response.data))
              );
            } else {
              setStaticData(response.data);
              let selectedProduct = "";

              if (productKey) {
                if (productKey === "Serious Illness") {
                  let productToFind = "Serious Illness Cover";
                  selectedProduct = productCover.find(
                    (x) => x.id === productToFind
                  ).value;
                  setProductCode(response.data.PRODUCTS[productToFind]);
                } else {
                  selectedProduct = productCover.find(
                    (x) => x.id === productKey
                  ).value;
                  setProductCode(response.data.PRODUCTS[productKey]);
                }
              }

              let minCover = response.data["MINIMUM_COVER"][selectedProduct];
              console.log(minCover);
              setMinCover(minCover);

              let maxCover = response.data["MAXIMUM_COVER"][selectedProduct];
              setMaxCover(maxCover);

              let affinity = "sbsa";
              setAffinity(affinity);

              setPremium(minCover);
            }
          }
        })
        .catch((error) => {
          toast.error(`Failed to get data`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("Get static data", JSON.stringify(error));

          return;
        });
    };

    getStaticData();
  }, []);

  function isValidEmail(email) {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      email
    );
  }

  function isValidName(name) {
    return /[a-zA-Z]$/gm.test(name);
  }

  function isValidNumber(contactNumber) {
    return /^[0-9]+$/.test(contactNumber);
  }

  function isValidAmount(amount) {
    return /^[0-9\b]+$/.test(amount);
  }

  function onChangeEducation(selectedValue) {
    if (selectedValue !== null) {
      setEducationType(selectedValue);
      setQuoteUserDetails({
        ...quoteUserDetails,
        qualification: selectedValue.value,
      });
    } else {
      setEducationType(null);
    }
  }

  function onChangeGender(selectedValue) {
    if (selectedValue !== null) {
      setGenderType(selectedValue);
      setQuoteUserDetails({
        ...quoteUserDetails,
        gender: selectedValue.value,
      });

      if (userGender !== selectedValue.label) {
        toast.error("Invalid gender selected", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setGenderType(null);
        return;
      }
    } else {
      setGenderType(null);
    }
  }

  function IdNumberValidation(id_number) {
    const generate_luhn_digit = (id_number) => {
      let total = 0;
      let count = 0;
      let i = 0;
      while (i < id_number.length) {
        let multiple = (count % 2) + 1;
        count = count + 1;
        let temp = multiple * parseInt(id_number[i]);
        temp = parseInt(Math.floor(temp / 10) + (temp % 10));
        total = total + temp;
        i = i + 1;
      }
      total = (total * 9) % 10;
      return total;
    };

    const validate = (id_number) => {
      let isnum = /^\d+$/.test(id_number);
      try {
        if (!isnum || id_number.length !== 13) {
          return false;
        }
        let last_digit = parseInt(
          id_number.substr(id_number.length - 1, id_number.length)
        );
        let number_section = id_number.substr(0, id_number.length - 1);
        return last_digit === generate_luhn_digit(number_section);
      } catch (error) {
        return false;
      }
    };

    let tempDate = new Date(
      id_number.substring(0, 2),
      id_number.substring(2, 4) - 1,
      id_number.substring(4, 6)
    );

    let id_date = tempDate.getDate();
    let id_month = tempDate.getMonth();
    let id_year = tempDate.getFullYear();

    let fullDate = id_date + "-" + (id_month + 1) + "-" + id_year;
    let dob = moment(fullDate).format("MM/DD/YYYY");
    localStorage.setItem("dob", JSON.stringify(dob));

    let currentYear = new Date().getFullYear();
    let age = currentYear - id_year;
    setAge(age);
    localStorage.setItem("age", JSON.stringify(age));

    let genderCode = id_number.substring(6, 10);
    var gender = parseInt(genderCode) < 5000 ? "Female" : "Male";
    setGender(gender);
    localStorage.setItem("gender", JSON.stringify(gender));

    var isCitizen = parseInt(id_number.substring(10, 11)) === 0 ? true : false;
    setIsCitizen(isCitizen);
    if (userIsCitizen)
      setQuoteUserDetails({ ...quoteUserDetails, citizenship: "za" });

    return id_number !== "0000000000000" && validate(id_number) && isCitizen;
  }

  const checkIfIdExists = async (customer_id, product) => {
    setDropOffId(5);
    var config = {
      method: "get",
      url: `${API_ENDPOINT}buyonline/quotes/accepted/by-customer-id/${customer_id}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
      },
    };

    return await axios(config, product)
      .then((response) => {
        if (response.data.success === false) {
          toast.warn(`Failed to fetch accepted quotes by customer`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return Promise.reject(
            console.log("Fetch accepted quotes", JSON.stringify(response.data))
          );
        } else {
          let existingQuote = false;
          response.data.forEach((list) => {
            let hasProduct = list.products.find(
              (prod) => prod.product__name === product
            );
            if (hasProduct) {
              existingQuote = true;
            }
          });

          if (existingQuote) {
            let dropOff = applicationModal.find((x) => x.id === 5);
            dropOff.messageSection1 = dropOff.messageSection1.replace(
              `{0}`,
              customer_id
            );
            dropOff.messageSection1 = dropOff.messageSection1.replace(
              `{1}`,
              product
            );
            setDropOffModel(dropOff);
            return true;
          }
          return false;
        }
      })
      .catch((error) => {
        toast.error(`Failed to fetch accepted quotes`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Accepted quotes fetch", JSON.stringify(error));

        return true;
      });
  };

  const checkIdDropOff = async (event) => {
    let idNumber = event.target.value;
    let result = IdNumberValidation(idNumber);

    if (result) {
      const productKey = JSON.parse(localStorage.getItem("productKey"));
      let checkId = await checkIfIdExists(idNumber, productKey);
      console.log("checkID ", checkId);
      if (checkId) {
        setShow(true);
        return;
      }

      let invalidAge = await checkAgeDropOff(userAge, productKey);
      console.log("invalidAge ", !invalidAge);
      if (!invalidAge) {
        setShow(true);
        return;
      }

      let notCitizen = await checkCitizenDropOff(userIsCitizen);
      console.log("notCitizen  ", !notCitizen);
      if (!notCitizen) {
        setShow(true);
        return;
      }
    } else {
      return;
    }
  };

  const checkAgeDropOff = async (customerAge, product) => {
    setDropOffId(6);

    if (customerAge < 18 || customerAge > 65) {
      let dropOff = applicationModal.find((x) => x.id === 6);
      dropOff.messageSection1 = dropOff.messageSection1.replace(`{0}`, product);
      setDropOffModel(dropOff);
      return false;
    } else {
      return true;
    }
  };

  const checkCitizenDropOff = async (customerIsCitizen) => {
    setDropOffId(4);

    if (!customerIsCitizen) {
      let dropOff = applicationModal.find((x) => x.id === 4);
      setDropOffModel(dropOff);
      return false;
    } else {
      return true;
    }
  };

  const onChange = (event) => {
    if (event.target.name === "first_name") {
      if (!isValidName(event.target.value)) {
        setIsNameValid(false);
      } else {
        setIsNameValid(true);
      }

      if (event.target.value === "") {
        setIsNameValid(true);
      }
    }

    if (event.target.name === "last_name") {
      if (!isValidName(event.target.value)) {
        setIsLastNameValid(false);
      } else {
        setIsLastNameValid(true);
      }

      if (event.target.value === "") {
        setIsLastNameValid(true);
      }
    }

    if (event.target.name === "email_address") {
      if (!isValidEmail(event.target.value)) {
        setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }

      if (event.target.value === "") {
        setIsEmailValid(true);
      }
    }

    if (event.target.name === "contact_number") {
      if (!isValidNumber(event.target.value)) {
        setIsNumberValid(false);
      } else {
        setIsNumberValid(true);
      }

      if (event.target.value === "0000000000") setIsNumberValid(false);

      if (event.target.value === "") {
        setIsNumberValid(true);
      }
    }

    if (event.target.name === "alternative_contact_number") {
      if (!isValidNumber(event.target.value)) {
        setIsAltNumberValid(false);
      } else {
        setIsAltNumberValid(true);
      }

      if (event.target.value === "0000000000") setIsAltNumberValid(false);

      if (event.target.value === "") {
        setIsAltNumberValid(true);
      }
    }

    if (event.target.name === "income") {
      if (!isValidAmount(event.target.value)) {
        setIsIncomeValid(false);
      } else {
        setIsIncomeValid(true);
      }

      if (event.target.value <= 0 || event.target.value === "") {
        setIsIncomeValid(false);
      }
    }

    if (event.target.name === "id_number") {
      setIsIdNumberValid(false);
      if (
        isValidNumber(event.target.value) &&
        IdNumberValidation(event.target.value)
      ) {
        setIsIdNumberValid(true);
        localStorage.setItem("id", JSON.stringify(event.target.value));
      }
    }

    setQuoteUserDetails({
      ...quoteUserDetails,
      [event.target.name]: event.target.value,
    });
  };

  function checkValue(e) {
    if (e.target.id === "smoker") {
      let isSmoker = !smokeChecked;
      setSmokeChecked(isSmoker);
      setNonSmokeChecked(false);
      setIsChecked(true);

      setQuoteUserDetails({
        ...quoteUserDetails,
        smoker: true,
      });
    }

    if (e.target.id === "nonSmoker") {
      let isNonSmoker = !nonSmokeChecked;
      setNonSmokeChecked(isNonSmoker);
      setSmokeChecked(false);
      setIsChecked(true);

      setQuoteUserDetails({
        ...quoteUserDetails,
        smoker: false,
      });
    }
  }

  const handleSliderChange = (e) => {
    let premiumAmount = monthlyPremium[Math.floor(e.target.value)];
    setPremium(premiumAmount);

    let coverAmount = monthlySlider[Math.floor(e.target.value)];
    setCover(coverAmount);
    console.log(e.target.value, coverAmount, premiumAmount);
  };

  const closeModal = () => {
    setShow(false);
    navigate("/");
  };

  const onClick = () => {
    return;
  };

  const onBuyCover = async (e) => {
    try {
      let selectedProduct = "";

      const customerId = JSON.parse(localStorage.getItem("custId"));
      const productKey = JSON.parse(localStorage.getItem("productKey"));
      if (productKey) {
        selectedProduct = products.find((x) => x.id === productKey)?.value;
      }

      localStorage.setItem(
        "quickQuoteDetails",
        JSON.stringify(quoteUserDetails)
      );

      toast.success("Buy now submitted successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      navigate("/application");
      return;
    } catch (error) {
      toast.error(`Buy now failed`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("Quick quote failed", JSON.stringify(error));
      return;
    }
  };

  const validateEmptyOnSubmit = () => {
    if (
      email_address === "" ||
      first_name === "" ||
      last_name === "" ||
      contact_number === "" ||
      income === 0 ||
      qualification === "" ||
      gender === "" ||
      id_number === "" ||
      !isChecked
    ) {
      return false;
    }
  };

  const validateOnSubmit = () => {
    if (
      !isEmailValid ||
      !isNameValid ||
      !isLastNameValid ||
      !isNumberValid ||
      !isIncomeValid ||
      !isIdNumberValid ||
      !isAltNumberValid
    ) {
      return false;
    }
  };

  const closeLoader = () => {
    setShowLoader(false);
  };

  const onSubmit = async (e, contextUser) => {
    e.preventDefault();
    let notEmpty = validateEmptyOnSubmit();
    if (notEmpty === false) {
      toast.error("Please fill in all the required fields marked with (*)", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let isValid = validateOnSubmit();
    if (isValid === false) {
      toast.error("Please fix validation errors", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    localStorage.setItem("quickQuoteData", JSON.stringify(quoteUserDetails));

    var data = JSON.stringify({
      ...quoteUserDetails,
    });

    let config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/submit-lead/quick-quote`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    setShowLoader(true);

    await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            setShowLoader(false);
            toast.warn(`Failed to submit quick quote`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log("Quick Quote Submit", JSON.stringify(response.data))
            );
          } else {
            setCustId(response.data?.customer_pk);
            toast.success("Quote submitted successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setHasQualifiedForCover(true);
            setShowLoader(false);
            return;
          }
        }
      })
      .catch((error) => {
        toast.error(`Failed to submit quick quote`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Quick Quote Submit", JSON.stringify(error));
        setShowLoader(false);
        return;
      });

    const customerId = JSON.parse(localStorage.getItem("custId"));
    await getQuoteEstimates(customerId, productCode);
    return;
  };

  const getQuoteEstimates = async (customerId, productCode) => {
    setShowLoader(true);
    if (productCode === undefined) {
      productCode = products.filter((prod) => prod.id === productKey)[0].value;
    }

    var config = {
      method: "get",
      url: `${API_ENDPOINT}buyonline/quote-estimate/${customerId}/${productCode}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
      },
    };

    return await axios(config)
      .then((response) => {
        if (response.data) {
          if (response.data.success === false) {
            setShowLoader(false);
            toast.warn(`Failed to get quote estimates`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log("Get static data", JSON.stringify(response.data))
            );
          } else {
            let coverList = [];
            let premiumList = [];

            let range = response.data.products.filter((product) => {
              if (product.code.toLowerCase() === productCode.toLowerCase()) {
                product.estimates.forEach((cover_premium) => {
                  coverList.push(cover_premium.cover);
                  premiumList.push(cover_premium.premium);
                });
                return true;
              }
            });
            setMonthlySlider(coverList);
            setMonthlyPremium(premiumList);
            setCover(coverList[0]);
            setPremium(premiumList[0]);

            setShowLoader(false);
          }
        }
      })
      .catch((error) => {
        toast.error(`Failed to get quote estimates`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Get quote data", JSON.stringify(error));
        setShowLoader(false);
        return;
      });
  };

  return (
    <MainContext.Consumer>
      {(value) => (
        <>
          <div className="relative bg-light-white px-4 pt-20 pb-20 sm:px-6 lg:px-8 lg:pb-28 lg:pt-28">
            <div className="relative mx-auto lg:pl-36 lg:pr-36">
              <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-1">
                <div className="flex flex-col rounded-lg shadow-lg">
                  <div className="grid grid-cols-6 gap-6 xl:gap-6 sm:gap-0 bg-white">
                    <div className="col-span-6 sm:col-span-4">
                      <div className="flex flex-1 flex-col justify-between bg-white p-6">
                        <div>
                          <h3 className="text-3xl font-normal leading-6 text-gray pb-5">
                            Get an estimate quote
                          </h3>

                          <p className="pt-2 text-gray font-normal pb-2">
                            You are 2 minutes away from leaving a legacy for
                            your family, please share the following details with
                            us.
                          </p>
                        </div>

                        <form
                          onSubmit={(event) => onSubmit(event, value)}
                          id="quick-quote-form"
                        >
                          <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="first_name"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    first_name.length === 0
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  FIRST NAME
                                </span>
                              </label>
                              <div
                                className={
                                  isNameValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <input
                                  required={true}
                                  placeholder="Your first name"
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  autoComplete="first_name"
                                  value={first_name}
                                  onChange={onChange}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>

                              <p
                                className={
                                  !isNameValid && first_name.length > 0
                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                    : "hidden"
                                }
                              >
                                INVALID FIRSTNAME
                              </p>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="last_name"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    last_name.length === 0
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  LAST NAME
                                </span>
                              </label>
                              <div
                                className={
                                  isLastNameValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <input
                                  required={true}
                                  placeholder="Your last name"
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  autoComplete="last_name"
                                  value={last_name}
                                  onChange={onChange}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>

                              <p
                                className={
                                  !isLastNameValid && last_name.length > 0
                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                    : "hidden"
                                }
                              >
                                INVALID LASTNAME
                              </p>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="contact_number"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    contact_number.length === 0
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  CONTACT NUMBER
                                </span>
                              </label>
                              <div
                                className={
                                  isNumberValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <input
                                  maxLength={10}
                                  required={true}
                                  placeholder="Your contact number"
                                  type="text"
                                  name="contact_number"
                                  id="contact_number"
                                  autoComplete="contact_number"
                                  value={contact_number}
                                  onChange={onChange}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>

                              <p
                                className={
                                  !isNumberValid
                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                    : "hidden"
                                }
                              >
                                INVALID MUST BE 10 DIGITS
                              </p>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="alternative_contact_number"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span>ALTERNATIVE CONTACT NUMBER</span>
                              </label>
                              <div
                                className={
                                  isAltNumberValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <input
                                  maxLength={10}
                                  placeholder="Alternative contact number"
                                  type="text"
                                  name="alternative_contact_number"
                                  id="alternative_contact_number"
                                  autoComplete="alternative_contact_number"
                                  value={alternative_contact_number}
                                  onChange={onChange}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>

                              <p
                                className={
                                  !isAltNumberValid
                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                    : "hidden"
                                }
                              >
                                INVALID MUST BE 10 DIGITS
                              </p>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="email_address"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    email_address.length === 0
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  EMAIL ADDRESS
                                </span>
                              </label>
                              <div
                                className={
                                  isEmailValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <input
                                  required={true}
                                  placeholder="Your email address"
                                  type="text"
                                  name="email_address"
                                  id="email_address"
                                  autoComplete="email_address"
                                  value={email_address}
                                  onChange={onChange}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>

                              <p
                                className={
                                  !isEmailValid && email_address.length > 0
                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                    : "hidden"
                                }
                              >
                                INVALID EMAIL ADDRESS
                              </p>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="id_number"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    id_number.length === 0
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  ID NUMBER
                                </span>
                              </label>
                              <div
                                className={
                                  isIdNumberValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <input
                                  maxLength={13}
                                  required={true}
                                  placeholder="Your id number"
                                  type="text"
                                  name="id_number"
                                  id="id_number"
                                  autoComplete="id_number"
                                  value={id_number}
                                  onChange={onChange}
                                  onBlur={checkIdDropOff}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>
                              <p
                                className={
                                  !isIdNumberValid
                                    ? "peer-invalid:visible text-red text-sm font-bold pb-2 pt-2"
                                    : "hidden"
                                }
                              >
                                INVALID ID NUMBER MUST BE 13 DIGITS
                              </p>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="educationType"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    educationType === null
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  EDUCATION
                                </span>
                              </label>
                              <div className="text-gray pb-2">
                                <Select
                                  className="text-sm font-light text-gray"
                                  classNamePrefix="educationType"
                                  isDisabled={false}
                                  placeholder={"Your education"}
                                  isLoading={false}
                                  isClearable={true}
                                  isRtl={false}
                                  isSearchable={true}
                                  styles={customStyles}
                                  onChange={(value) => onChangeEducation(value)}
                                  value={educationType}
                                  options={educationTypes}
                                />
                              </div>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="gender"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    genderType === null
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  GENDER
                                </span>
                              </label>
                              <div className="text-gray pb-2">
                                <Select
                                  className="text-sm font-light text-gray"
                                  classNamePrefix="gender"
                                  placeholder={"Your gender"}
                                  isDisabled={false}
                                  isLoading={false}
                                  isClearable={true}
                                  isRtl={false}
                                  isSearchable={false}
                                  styles={customStyles}
                                  onChange={(value) => onChangeGender(value)}
                                  value={genderType}
                                  options={genderTypes}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="income"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    income === 0 || income === ""
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  GROSS MONTHLY INCOME
                                </span>
                              </label>
                              <div
                                className={
                                  isIncomeValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <span className="inline-flex items-center border-none focus:border-none px-3 text-sm text-light-blue">
                                  R
                                </span>
                                <input
                                  required={true}
                                  placeholder="Your household income"
                                  type="text"
                                  name="income"
                                  id="income"
                                  autoComplete="income"
                                  value={income}
                                  onChange={onChange}
                                  onFocus={(e) => (e.target.value = "")}
                                  // onBlur={onSetNumberValue}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>
                              <p
                                className={
                                  !isIncomeValid
                                    ? "peer-invalid:visible text-red text-sm font-bold pb-2 pt-2"
                                    : "hidden"
                                }
                              >
                                GROSS INCOME INVALID
                              </p>
                            </div>
                            <div className="col-span-6 sm:col-span-3">
                              <label className="block text-sm font-normal text-gray-500 py-2">
                                <span
                                  className={
                                    !isChecked
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  SMOKER
                                </span>
                              </label>
                              <fieldset className="mb-2">
                                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                  {smokedOptions.map(
                                    (smokedOptions, smokedOptionsIdx) => (
                                      <div
                                        key={smokedOptions.id}
                                        className="flex items-center"
                                      >
                                        {smokedOptionsIdx === 0 ? (
                                          <input
                                            required={!nonSmokeChecked}
                                            id={smokedOptions.id}
                                            name={smokedOptions.title}
                                            value={smokeChecked}
                                            type="checkbox"
                                            checked={smokeChecked}
                                            onChange={checkValue}
                                            className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                          />
                                        ) : (
                                          <input
                                            required={!smokeChecked}
                                            id={smokedOptions.id}
                                            name={smokedOptions.title}
                                            value={nonSmokeChecked}
                                            type="checkbox"
                                            checked={nonSmokeChecked}
                                            onChange={checkValue}
                                            className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                          />
                                        )}

                                        <label
                                          htmlFor={smokedOptions.id}
                                          className="ml-3 block text-sm font-normal text-gray"
                                        >
                                          {smokedOptions.title}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              </fieldset>

                              <p
                                className={
                                  !isChecked
                                    ? "peer-invalid:visible text-red text-sm font-bold pb-2 pt-2"
                                    : "hidden"
                                }
                              >
                                PLEASE SELECT AN OPTION
                              </p>
                            </div>
                          </div>

                          <div className="xl:text-center xl:items-center text-center bg-white pt-5 xl:pt-10">
                            <button
                              className="bg-btn-primary-color font-bold xl:w-100 w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                              type="submit"
                              onClick={onClick}
                            >
                              <span>GET QUOTE</span>
                            </button>
                          </div>

                          <ApplicationModal
                            dropOffModel={dropOffModel}
                            dropOffId={dropOffId}
                            show={show}
                            closeModal={closeModal}
                          />

                          <Loading
                            show={showLoader}
                            closeLoaderModal={closeLoader}
                          />
                        </form>

                        {hasQualifiedForCover ? (
                          <>
                            <div className="pt-5">
                              <h3 className="text-3xl font-bold leading-6 text-gray py-2">
                                Well done {first_name} {last_name}
                              </h3>

                              <p className="pt-2 text-gray font-normal pb-2">
                                You are ready for {qualified_product}
                              </p>
                            </div>

                            <div className="rounded-lg shadow-lg p-5">
                              <div className="grid grid-cols-6 sm:gap-0 xl:gap-6 pb-5">
                                <div className="col-span-6 sm:col-span-3">
                                  <label className="text-gray-500 font-normal text-md">
                                    YOUR COVER
                                  </label>
                                  <p className="text-5xl text-gray font-normal py-5">
                                    <span className="text-sm font-bold text-light-blue mr-2">
                                      R
                                    </span>
                                    {cover}
                                  </p>
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <label className="text-gray-500 font-normal text-md">
                                    YOUR PREMIUM
                                  </label>
                                  <p className="text-5xl text-gray font-normal py-5">
                                    <span className="text-sm font-bold text-light-blue mr-2">
                                      R
                                    </span>
                                    {premium}
                                  </p>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 sm:gap-0 xl:gap-6 pb-5">
                                <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                  <div className="range-slider__slider w-full">
                                    <input
                                      type="range"
                                      min={0}
                                      step={0.5}
                                      max={monthlySlider?.length - 1}
                                      className="slider"
                                      id="rangeSlider"
                                      onChange={handleSliderChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              <p className="text-xs text-gray-500 mt-2 font-normal">
                                * THE COVER AND PREMIUM AMOUNTS ARE BASED ON
                                MINIMAL INFORMATION AND ARE SUBJECT TO CHANGE
                              </p>

                              <div className="xl:text-center xl:items-center text-center bg-white pt-5 xl:pt-10">
                                <button
                                  className="bg-orange font-bold xl:w-100 w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-orange hover:bg-orange hover:text-white hover:border-orange border-solid border-2"
                                  type="submit"
                                  onClick={onBuyCover}
                                >
                                  <span>BUY COVER</span>
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <div className="flex flex-1 flex-col justify-between bg-white pt-1 pb-6 px-6 xl:p-6 sticky top-0">
                        <QuickQuoteInformation></QuickQuoteInformation>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </MainContext.Consumer>
  );
}
