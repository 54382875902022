import React from "react";
import { useNavigate } from "react-router-dom";
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Shield from "../../assets/images/shield-paper.svg";
import { products } from "../../models/DataModels";
import axios from "axios";
import { toast } from "react-toastify";

const CallUsModal = ({ show, closeModal, goBack }) => {
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const [contact_details, setContactDetails] = useState({
    product: "",
    first_name: "",
    last_name: "",
    contact_number: "",
  });
  const [isNameValid, setIsNameValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
  const { product, first_name, last_name, contact_number } = contact_details;

  useEffect(() => {
    const getProduct = () => {
      let selectedProduct = "";
      const productKey = JSON.parse(localStorage.getItem("productKey"));
      if (productKey) {
        if (productKey === "Serious Illness") {
          let productToFind = "Serious Illness Cover";
          selectedProduct = products.find((x) => x.id === productToFind).value;
          setContactDetails({ ...contact_details, product: selectedProduct });
        } else {
          selectedProduct = products.find((x) => x.id === productKey).value;
          setContactDetails({ ...contact_details, product: selectedProduct });
        }
      }
    };
    getProduct();
  }, []);

  function isValidName(name) {
    return /[a-zA-Z]$/gm.test(name);
  }

  function isValidNumber(contactNumber) {
    return /^[0-9]+$/.test(contactNumber);
  }

  const onChange = (event) => {
    if (event.target.name === "first_name") {
      if (!isValidName(event.target.value)) {
        setIsNameValid(false);
      } else {
        setIsNameValid(true);
      }

      if (event.target.value === "") {
        setIsNameValid(true);
      }
    }

    if (event.target.name === "last_name") {
      if (!isValidName(event.target.value)) {
        setIsLastNameValid(false);
      } else {
        setIsLastNameValid(true);
      }

      if (event.target.value === "") {
        setIsLastNameValid(true);
      }
    }

    if (event.target.name === "contact_number") {
      if (!isValidNumber(event.target.value)) {
        setIsNumberValid(false);
      } else {
        setIsNumberValid(true);
      }

      if (event.target.value === "0000000000") setIsNumberValid(false);

      if (event.target.value === "") {
        setIsNumberValid(true);
      }
    }

    setContactDetails({
      ...contact_details,
      [event.target.name]: event.target.value,
    });
  };

  const onClick = () => {
    return;
  };

  const validateEmptyOnSubmit = () => {
    if (first_name === "" || contact_number === "") {
      return false;
    }
  };

  const validateOnSubmit = () => {
    if (!isNameValid || !isNumberValid) {
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let result = validateEmptyOnSubmit();
    let isValid = validateOnSubmit();
    if (result === false || isValid === false) {
      return;
    }

    var data = JSON.stringify({
      ...contact_details,
    });

    let config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/submit-lead/fast`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            return Promise.reject(
              console.log("In a hurry submit", JSON.stringify(response.data))
            );
          } else {
              toast.success("Thank you. Your call back is being processed and one of our friendly agents will be in contact with you soon.", {autoClose:5000});
            closeModal();
            return;
          }
        }
      })
      .catch((error) => {
        console.log("In a hurry submit", JSON.stringify(error));
        return;
      });
  };

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-primary-color bg-opacity-75 transition-opacity backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all sm:my-8 xl:w-1/2">
                  <div className="flex items-center pb-5">
                    <div className="flex h-16 w-20 flex-shrink-0 items-center justify-center rounded-full bg-white">
                      <img
                        className="h-16 w-20 shield-img"
                        src={Shield}
                        alt="shield"
                      />
                    </div>
                    <div className="flex flex-col items-center sm:text-gray">
                      <Dialog.Title
                        as="h3"
                        className="font-normal text-3xl leading-6 text-gray"
                      >
                        <span>In a hurry?</span>
                      </Dialog.Title>
                    </div>
                  </div>

                  <div className="py-2">
                    <p className="font-normal text-left text-gray">
                      Just leave your name and contact number for one of our
                      friendly agents to call you back. They will then take you
                      through the quote process and will be happy to answer any
                      questions you may have.
                    </p>
                  </div>

                  <form
                    onSubmit={(event) => onSubmit(event)}
                    id="contact-details-form"
                  >
                    <div className="grid grid-cols-1 gap-1 items-center">
                      <div className="col-span-1 xl:col-span-1">
                        <label
                          htmlFor="first_name"
                          className="block text-sm font-normal text-gray-500 py-2"
                        >
                          <span
                            className={
                              first_name.length === 0
                                ? "after:content-['*'] after:text-red"
                                : "after:content-['*']"
                            }
                          >
                            FIRST NAME
                          </span>
                        </label>
                        <div
                          className={
                            isNameValid
                              ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                              : "flex border-2 border-red rounded w-full"
                          }
                        >
                          <input
                            required={true}
                            placeholder="Your first name"
                            type="text"
                            name="first_name"
                            id="first_name"
                            autoComplete="first_name"
                            value={first_name}
                            onChange={onChange}
                            className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                          />
                        </div>

                        <p
                          className={
                            !isNameValid && first_name.length > 0
                              ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                              : "hidden"
                          }
                        >
                          INVALID FIRSTNAME
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-1">
                      <div className="col-span-1 sm:col-span-1">
                        <label
                          htmlFor="last_name"
                          className="block text-sm font-normal text-gray-500 py-2"
                        >
                          <span
                            className={
                              last_name.length === 0
                                ? "after:content-['*'] after:text-red"
                                : "after:content-['*']"
                            }
                          >
                            LAST NAME
                          </span>
                        </label>
                        <div
                          className={
                            isLastNameValid
                              ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                              : "flex border-2 border-red rounded w-full"
                          }
                        >
                          <input
                            required={true}
                            placeholder="Your last name"
                            type="text"
                            name="last_name"
                            id="last_name"
                            autoComplete="last_name"
                            value={last_name}
                            onChange={onChange}
                            className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                          />
                        </div>

                        <p
                          className={
                            !isLastNameValid && last_name.length > 0
                              ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                              : "hidden"
                          }
                        >
                          INVALID LASTNAME
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 gap-1">
                      <div className="col-span-1 xl:col-span-1">
                        <label
                          htmlFor="contact_number"
                          className="block text-sm font-normal text-gray-500 py-2"
                        >
                          <span
                            className={
                              contact_number.length === 0
                                ? "after:content-['*'] after:text-red"
                                : "after:content-['*']"
                            }
                          >
                            CONTACT NUMBER
                          </span>
                        </label>
                        <div
                          className={
                            isNumberValid
                              ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                              : "flex border-2 border-red rounded w-full"
                          }
                        >
                          <input
                            maxLength={10}
                            required={true}
                            placeholder="Your contact number"
                            type="text"
                            name="contact_number"
                            id="contact_number"
                            autoComplete="contact_number"
                            value={contact_number}
                            onChange={onChange}
                            className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                          />
                        </div>

                        <p
                          className={
                            !isNumberValid
                              ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                              : "hidden"
                          }
                        >
                          INVALID MUST BE 10 DIGITS
                        </p>
                      </div>
                    </div>

                    <div className="pt-5 text-center">
                      <div className="flex flex-1 flex-col items-center xl:flex xl:flex-row xl:justify-center xl:w-full">
                        <button
                          className="w-64 xl:w-64 mb-2 xl:mb-0 xl:mr-2 py-2 px-4 bg-btn-secondary-color text-light-blue shadow-sm border-light-blue hover:bg-btn-primary-color hover:text-white hover: border-solid border-2 font-bold"
                          type="submit"
                          onClick={goBack}
                        >
                          <span>BACK</span>
                        </button>
                        <button
                          className="bg-btn-primary-color font-bold mt-2 xl:ml-2 xl:mt-0 w-64 xl:w-64 py-2 px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                          type="submit"
                          onClick={onClick}
                        >
                          <span>CONFIRM</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CallUsModal;
