import React from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

const ApplicationModal = ({ dropOffModel, dropOffId, show, closeModal }) => {
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => null}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-primary-color bg-opacity-75 transition-opacity backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-16 w-20 flex-shrink-0 items-center justify-center rounded-full bg-white sm:mx-0 sm:h-10 sm:w-10">
                      <img
                        className="h-16 w-20 shield-img"
                        src={dropOffModel.image}
                        alt="shield"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-gray">
                      <Dialog.Title
                        as="h3"
                        className="font-normal text-2xl leading-6 text-gray"
                      >
                        {dropOffModel.title}
                      </Dialog.Title>

                      <div className="pt-5 text-left">
                        <p className="text-sm text-gray font-normal">
                          {dropOffModel.messageSection1}
                        </p>
                      </div>

                      <div className="pt-5 text-left">
                        <p className="text-sm text-gray font-normal">
                          {dropOffModel.messageSection2}
                        </p>
                      </div>

                      {dropOffId === 5 ? (
                        <div className="pt-5 text-left">
                          <p className="text-sm text-gray font-normal">
                            {dropOffModel.messageSection3}
                            <a
                              href={"/application"}
                              className="border-b-2 border-transparent hover:border-light-blue text-light-blue font-bold"
                            >
                              {" "}
                              {dropOffModel.messageSection4}
                            </a>
                          </p>
                        </div>
                      ) : (
                        <div className="pt-5 text-left">
                          <p className="text-sm text-gray font-normal">
                            {dropOffModel?.messageSection3}
                          </p>
                        </div>
                      )}

                      {dropOffId === 7 ? (
                        <div className="pt-5 text-left">
                          <p className="text-sm text-gray font-normal">
                            {dropOffModel?.messageSection4}
                            <a
                              href={"/application"}
                              className="border-b-2 border-transparent hover:border-light-blue text-light-blue font-bold"
                            >
                              {" "}
                              {dropOffModel?.messageSection5}
                            </a>
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="pt-5 text-center">
                    <button
                      className="bg-btn-primary-color font-bold xl:w-100 w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                      type="button"
                      onClick={closeModal}
                    >
                      <span>CONFIRM</span>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ApplicationModal;
