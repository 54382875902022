import React, { useEffect, useContext } from 'react'
import {  useCookies } from "react-cookie";
import Content from "../components/content/Content";

export default function SalaryProtection() {
  const [cookies, setCookie, removeCookie] = useCookies();
  useEffect( () => {
      setCookie('productSlug', 'salary-protection');
      localStorage.setItem("productKey", JSON.stringify("Salary Protection"));
  },[]);

  return (
    <>
      <div className="bg-light-white">
        <h1 className="text-3xl pt-5 font-normal tracking-tight text-gray text-center sm:text-4xl">
          Buy Salary Protection
        </h1>
        <Content></Content>
      </div>
    </>
  );
};
