import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { tellMeMoreDisability } from "../models/DataModels";

export default function TellMeMoreDisability() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  };

  const onBuyCover = () => {
    navigate("/disability-cover");
  };
  return (
    <div className="relative bg-light-white px-5 pb-5">
      <div className="absolute inset-0"></div>

      {tellMeMoreDisability.map((tellMeMore, index) => (
        <div className="" key={index}>
          <div className="relative bg-light-white py-5">
            <p className="text-gray font-normal text-3xl">{tellMeMore.title}</p>

            <div className="relative bg-light-white">
              <div className="mx-auto my-5 grid gap-5 justify-start">
                <div className="items-center">
                  <div className="flex flex-1 flex-col">
                    <Link
                      to={"/"}
                      className="border-b-2 border-transparent hover:border-light-blue text-light-blue"
                    >
                      <span className="text-base font-bold">GO BACK</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto pb-5">
            <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3 bg-light-white">
              {tellMeMore.detailsSection.map((post, index) => (
                <div
                  key={index}
                  className="flex flex-col overflow-hidden rounded-lg shadow-lg"
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-48 w-full object-cover"
                      src={post.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between bg-white p-6">
                    <div className="flex-1">
                      <div className="mt-2 block">
                        <p className="text-gray font-normal text-3xl mb-5">
                          {post.title}
                        </p>
                        <p className="mt-3 text-sm font-normal text-gray mb-5">
                          {post.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="relative bg-light-white">
            <div className="mx-auto my-5 grid gap-5 justify-center">
              <div className="items-center">
                <div className="flex flex-1 flex-col col-span-1">
                  <button
                    className="bg-btn-primary-color font-bold w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                    onClick={onBuyCover}
                  >
                    <span>BUY COVER NOW</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
