import React from "react";

const ProgressBar = ({ progressPercentage }) => {
	return (
		<div className='h-1 w-full bg-light-gray rounded overflow-hidden'>
			<div
				style={{ width: `${progressPercentage}%` }}
				className={`h-full ${
					progressPercentage < 100 ? 'bg-light-blue' : 'bg-blue'
				}`}
			></div>
		</div>
	);
};

export default ProgressBar;