import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { navigation } from "../../models/DataModels";

const Header = () => {
  const [data, setData] = useState([]);
  const [productTitle, setProductTitle] = useState([]);

  const setProduct = (product) => {
    setData(product);
    localStorage.setItem("productKey", JSON.stringify(product));
  };

  
  useEffect( () => {
    setProductTitle(JSON.parse(localStorage.getItem("productKey")));
  },[])

  return (
    <Disclosure
      as="nav"
      className="bg-gradient-to-r from-primary-color to-primary-color shadow-xl"
    >
      {({ open }) => (
        <>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex h-24 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 justify-items-end items-center">
                  <a href="/">
                    <img
                      className="block h-8 pr-2 w-auto lg:hidden"
                      src={require("../../assets/images/logo_std.png")}
                      alt="Your Company"
                    />
                  </a>
                  <a href="/">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={require("../../assets/images/logo_name.png")}
                      alt="Your Company"
                    />
                  </a>

                  <a href="/">
                    <img
                      className="hidden pr-2 w-auto lg:block"
                      src={require("../../assets/images/logo_std.png")}
                      alt="Your Company"
                    />
                  </a>

                  <a href="/">
                    <img
                      className="hidden w-auto lg:block"
                      src={require("../../assets/images/logo_name.png")}
                      alt="Your Company"
                    />
                  </a>
                </div>
              </div>

              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {navigation.map((link) => (
                  <a
                    key={link.name}
                    href={link.href}
                    onClick={() => setProduct(link.name)}
                    className={ ( (productTitle === link.name) ? "border-white " : "hover:border-white ") + "inline-flex items-center border-b-4 border-transparent px-1 pt-1 text-md font-normal text-white  hover:text-white" }
                  >
                    {link.name}
                  </a>
                ))}
              </div>

              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pt-2 pb-3">
              <Disclosure.Button
                as="a"
                href="/salary-protection"
                className="block border-l-4 border-transparent border-white py-2 pl-3 pr-4 text-base font-light text-white"
              >
                Salary Protection
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/life-cover"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-light text-white hover:border-white hover:text-white"
              >
                Life Cover
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/serious-illness-cover"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-light text-white hover:border-white hover:text-white"
              >
                Serious Illness Cover
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/disability-cover"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-light text-white hover:border-white hover:text-white"
              >
                Disability Cover
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
