import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import React, { useEffect, useState, useContext, createContext, useLayoutEffect } from "react";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import DisabilityCover from "./pages/DisabilityCover";
import Home from "./pages/Home";
import LifeCover from "./pages/LifeCover";
import SalaryProtection from "./pages/SalaryProtection";
import SeriousIllness from "./pages/SeriousIllness";
import AddBenificiary from "./pages/AddBenificiary";
import PersonalDetails from "./pages/PersonalDetails";
import Quote from "./pages/Quote";
import Summary from "./pages/Summary";
import Success from "./pages/Success";
import TellMeMore from "./pages/TellMeMore";
import { productSlugs } from "./models/DataModels";
import { CookiesProvider, useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import TellMeMoreDisability from "./pages/TellMeMoreDisability";
import TellMeMoreSalary from "./pages/TellMeMoreSalary";
import TellMeMoreIllness from "./pages/TellMeMoreIllness";
import QuickQuote from "./pages/QuickQuote";
import MaintenanceModal from "./components/modals/MaintenanceModal";

export const MainContext = createContext({
  user: {},
  setUser: (user) => {},
});

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 
function App() {
  // USING COOKIES
  const [cookies, setCookie, removeCookie] = useCookies();
  // setCookie("lastLogin", data["last_login"], {
  //   path: "/",
  //   expires: new Date(),
  // });
  // let lastLogin = cookies.lastLogin
  // removeCookie('lastLogin')

  const [showMaintenance, setShowMaintenance] = useState(false);

  const [user, setUser] = useState(null);
  const setContextUser = (ctxUser) => {
    let objUser = { ...user, ...ctxUser };
    let existingObj = JSON.parse(localStorage.getItem("ctx"));
    let updatedUser = existingObj ? { ...existingObj, ...objUser } : objUser;
    setUser(updatedUser);
    localStorage.setItem("ctx", JSON.stringify(updatedUser));
  };
  const value = { user, setContextUser };

  useEffect(() => {
    const productSlug = window.location.pathname.replace("/", "");

    console.log(process.env.REACT_APP_MAINTENANCE_MODE === "true" && localStorage.getItem("MaintenanceAcknowledged") === null)
    if (process.env.REACT_APP_MAINTENANCE_MODE === "true" && localStorage.getItem("MaintenanceAcknowledged") === null) {
      setShowMaintenance(true)
    }

    if (window.location.pathname === "/") {
      removeCookie("productSlug");
      localStorage.clear();
    }

    const getContextUser = async () => {
      if (user === null && localStorage.getItem("ctx") !== null) {
        let ctxUser = JSON.parse(localStorage.getItem("ctx"));
        setUser(ctxUser);
      }
    };

    getContextUser();
  }, []);

  const closeMaintModal = () => {
    // Closes the maintenance modal
    setShowMaintenance(false);
    localStorage.setItem("MaintenanceAcknowledged", true);
  };


  return (
    <MainContext.Provider value={value}>
      <Router>
        <Wrapper>
        <Header />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                {" "}
                <Home />{" "}
              </>
            }
          ></Route>
          <Route path="salary-protection" element={<SalaryProtection />} />
          <Route path="life-cover" element={<LifeCover />} />
          <Route path="serious-illness-cover" element={<SeriousIllness />} />
          <Route path="disability-cover" element={<DisabilityCover />} />
          <Route path="application" element={<PersonalDetails />} />
          <Route path="add-beneficiary" element={<AddBenificiary />} />
          <Route path="quote" element={<Quote />} />
          <Route path="summary" element={<Summary />} />
          <Route path="success" element={<Success />} />
          <Route path="quick-quote" element={<QuickQuote />} />
          <Route path="tell-me-more" element={<TellMeMore />} />
          <Route path="tell-me-more-salary" element={<TellMeMoreSalary />} />
          <Route
            path="tell-me-more-disability"
            element={<TellMeMoreDisability />}
          />
          <Route path="tell-me-more-illness" element={<TellMeMoreIllness />} />
        </Routes>

          { showMaintenance === true && (
            <MaintenanceModal
              show={showMaintenance}
              closeModal={closeMaintModal}
              title={"IMPORTANT"}
              message={"Our systems are currently undergoing maintenance, you might experience limited functionality during this time."}
            />
          )}
            
        <Footer />
          <ToastContainer />
          </Wrapper>
      </Router>
    </MainContext.Provider>
  );
}

export default App;
