import React, { useEffect, useState } from "react";
import { products } from "../../models/DataModels";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../modals/Loading";
import { toast } from "react-toastify";

function QuickQuoteInformation() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [contact_details, setContactDetails] = useState({
    product: "",
    first_name: "",
    last_name: "",
    contact_number: "",
  });
  const [isNameValid, setIsNameValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
  const { product, first_name, last_name, contact_number } = contact_details;

  const closeLoader = () => {
    setShowLoader(false);
  };

  useEffect(() => {
    const getProduct = () => {
      let selectedProduct = "";
      const productKey = JSON.parse(localStorage.getItem("productKey"));
      if (productKey) {
        if (productKey === "Serious Illness") {
          let productToFind = "Serious Illness Cover";
          selectedProduct = products.find((x) => x.id === productToFind).value;
          setContactDetails({ ...contact_details, product: selectedProduct });
        } else {
          selectedProduct = products.find((x) => x.id === productKey).value;
          setContactDetails({ ...contact_details, product: selectedProduct });
        }
      }
    };
    getProduct();
  }, []);

  function isValidName(name) {
    return /[a-zA-Z]$/gm.test(name);
  }

  function isValidNumber(contactNumber) {
    return /^[0-9]+$/.test(contactNumber);
  }

  const onChange = (event) => {
    if (event.target.name === "first_name") {
      if (!isValidName(event.target.value)) {
        setIsNameValid(false);
      } else {
        setIsNameValid(true);
      }

      if (event.target.value === "") {
        setIsNameValid(true);
      }
    }

    if (event.target.name === "last_name") {
      if (!isValidName(event.target.value)) {
        setIsLastNameValid(false);
      } else {
        setIsLastNameValid(true);
      }

      if (event.target.value === "") {
        setIsLastNameValid(true);
      }
    }

    if (event.target.name === "contact_number") {
      if (!isValidNumber(event.target.value)) {
        setIsNumberValid(false);
      } else {
        setIsNumberValid(true);
      }

      if (event.target.value === "0000000000") setIsNumberValid(false);

      if (event.target.value === "") {
        setIsNumberValid(true);
      }
    }

    setContactDetails({
      ...contact_details,
      [event.target.name]: event.target.value,
    });
  };

  const onClick = () => {
    return;
  };

  const validateEmptyOnSubmit = () => {
    if (first_name === "" || contact_number === "") {
      return false;
    }
  };

  const validateOnSubmit = () => {
    if (!isNameValid || !isNumberValid) {
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let result = validateEmptyOnSubmit();
    let isValid = validateOnSubmit();
    if (result === false || isValid === false) {
      return;
    }

    var data = JSON.stringify({
      ...contact_details,
    });

    let config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/submit-lead/fast`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    setShowLoader(true);

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            setShowLoader(false);
            return Promise.reject(
              console.log("In a hurry submit", JSON.stringify(response.data))
            );
          } else {
            setShowLoader(false);
            toast.success("Thank you. Your call back is being processed and one of our friendly agents will be in contact with you soon.", {autoClose:5000});
              setContactDetails({
                  product: "",
                  first_name: "",
                  last_name: "",
                  contact_number: "",
              });
            return;
          }
        }
      })
      .catch((error) => {
        console.log("In a hurry submit", JSON.stringify(error));
        setShowLoader(false);
        return;
      });
  };

  return (
    <div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex flex-1 flex-col justify-between bg-gradient-to-r from-primary-color to-secondary-color p-6">
          <div className="mt-2 block h-10 items-center text-center">
            <span className="text-white text-center text-md text-2xl font-normal mb-5">
              In a hurry?
            </span>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center">
          <p className="mt-3 font-normal p-2 text-sm text-center text-gray indent-8">
            Just leave your name and contact number for one of our friendly
            agents to call you back. They will then take you through the quote
            process and will be happy to answer any questions you may have.
          </p>
          <hr className="line-break"></hr>
        </div>

        <div className="flex flex-1 flex-col px-5 py-2 justify-between">
          <form
            onSubmit={(event) => onSubmit(event)}
            id="quick-quote-information-form"
          >
            <div className="grid grid-cols-6 gap-1">
              <div className="col-span-6 xl:col-span-6">
                <label
                  htmlFor="first_name"
                  className="block text-sm font-normal text-gray-500 py-2"
                >
                  <span
                    className={
                      first_name.length === 0
                        ? "after:content-['*'] after:text-red"
                        : "after:content-['*']"
                    }
                  >
                    FIRST NAME
                  </span>
                </label>
                <div
                  className={
                    isNameValid
                      ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                      : "flex border-2 border-red rounded w-full"
                  }
                >
                  <input
                    required={true}
                    placeholder="Your first name"
                    type="text"
                    name="first_name"
                    id="first_name"
                    autoComplete="first_name"
                    value={first_name}
                    onChange={onChange}
                    className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                  />
                </div>

                <p
                  className={
                    !isNameValid && first_name.length > 0
                      ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                      : "hidden"
                  }
                >
                  INVALID FIRSTNAME
                </p>
              </div>

              <div className="col-span-6 xl:col-span-6">
                <label
                  htmlFor="last_name"
                  className="block text-sm font-normal text-gray-500 py-2"
                >
                  <span
                    className={
                      last_name.length === 0
                        ? "after:content-['*'] after:text-red"
                        : "after:content-['*']"
                    }
                  >
                    LAST NAME
                  </span>
                </label>
                <div
                  className={
                    isLastNameValid
                      ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                      : "flex border-2 border-red rounded w-full"
                  }
                >
                  <input
                    required={true}
                    placeholder="Your last name"
                    type="text"
                    name="last_name"
                    id="last_name"
                    autoComplete="last_name"
                    value={last_name}
                    onChange={onChange}
                    className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                  />
                </div>

                <p
                  className={
                    !isLastNameValid && last_name.length > 0
                      ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                      : "hidden"
                  }
                >
                  INVALID LASTNAME
                </p>
              </div>

              <div className="col-span-6 xl:col-span-6">
                <label
                  htmlFor="contact_number"
                  className="block text-sm font-normal text-gray-500 py-2"
                >
                  <span
                    className={
                      contact_number.length === 0
                        ? "after:content-['*'] after:text-red"
                        : "after:content-['*']"
                    }
                  >
                    CONTACT NUMBER
                  </span>
                </label>
                <div
                  className={
                    isNumberValid
                      ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                      : "flex border-2 border-red rounded w-full"
                  }
                >
                  <input
                    maxLength={10}
                    required={true}
                    placeholder="Your contact number"
                    type="text"
                    name="contact_number"
                    id="contact_number"
                    autoComplete="contact_number"
                    value={contact_number}
                    onChange={onChange}
                    className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                  />
                </div>

                <p
                  className={
                    !isNumberValid
                      ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                      : "hidden"
                  }
                >
                  INVALID MUST BE 10 DIGITS
                </p>
              </div>
            </div>

            <div className="relative">
              <div className="mx-auto mt-5 grid gap-5 lg:max-w-none lg:grid-cols-1">
                <div className="items-center pb-5">
                  <div className="flex flex-1 flex-col items-center text-center">
                    <button
                      className="xl:w-100 w-64 font-bold py-2 px-4 sm:py-2 sm:px-4 bg-btn-secondary-color text-light-blue shadow-sm border-light-blue hover:bg-btn-primary-color hover:text-white hover: border-solid border-2"
                      type="submit"
                      onClick={onClick}
                    >
                      <span>LET US CALL YOU</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Loading show={showLoader} closeLoaderModal={closeLoader} />
    </div>
  );
}

export default QuickQuoteInformation;
