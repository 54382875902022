import React, { useState } from "react";

function SummaryInformation() {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    let isChecked = !checked;
    setChecked(isChecked);
  };

  return (
    <div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex flex-1 flex-col justify-between bg-gradient-to-r from-primary-color to-secondary-color p-6">
          <div className="mt-2 block items-center text-center">
            <span className="text-white p-2 text-md font-normal mb-5">
              The quote presented is an estimate, based on limited information,
              and it may change as you complete the application
            </span>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center">
          <div className="quote-list mt-2 ml-5 pb-5 mr-2">
            <ul className="list-disc text-sm leading-6">
              <li className="list-item">
                <p className="text-gray font-normal">
                  Monthly payments are paid in advance for the month ahead.
                </p>
              </li>

              <li className="list-item">
                <p className="text-gray font-normal mr-2">
                  Your policy will commence on the 1st of the month after your
                  first payment is received.
                </p>
              </li>

              <li className="list-item">
                <p className="text-gray font-normal mr-2">
                  Premiums will increase by no more than 6% each year for the
                  first 5 years
                </p>
              </li>
            </ul>
          </div>

          <hr className="line-break"></hr>
        </div>

        <div className="relative">
          <div className="mx-auto p-5 grid gap-5 lg:max-w-none lg:grid-cols-1">
            <div className="flex flex-1 flex-col justify-between items-center">
              <div className="relative flex justify-between">
                <div className="flex items-center">
                  <input
                    id="agree"
                    aria-describedby="agree-description"
                    name="agree"
                    type="checkbox"
                    checked={checked}
                    required={true}
                    onChange={handleChange}
                    className="h-8 w-8 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                  />
                </div>
                <div className="ml-2 mt-1 text-sm items-center">
                  <label htmlFor="agree" className="font-light text-gray">
                    <span className="font-semibold">
                      I agree to the information above
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SummaryInformation;
