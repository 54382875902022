import React, { useEffect, useState } from "react";
import { productDisclosures } from "../../models/DataModels";

function QuoteInformation() {
  const [product, setProduct] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const getProduct = () => {
      const productKey = JSON.parse(localStorage.getItem("productKey"));
      if (productKey) {
        let selectedProduct = productDisclosures.find(
          (x) => x.id === productKey
        ).value;
        setProduct(selectedProduct);
      }
    };
    getProduct();
  }, []);

  const handleChange = () => {
    let isChecked = !checked;
    setChecked(isChecked);
  };

  return (
    <div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex flex-1 flex-col justify-between bg-gradient-to-r from-primary-color to-secondary-color p-6">
          <div className="mt-2 block items-center text-center">
            <span className="text-white p-2 font-normal text-md mb-5">
              The quote presented is an estimate, based on limited information,
              and it may change as you complete the application
            </span>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center">
          <div className="quote-list mt-2 ml-5 mr-5 pb-5">
            <ul className="list-disc text-sm leading-6">
              <li className="list-item">
                <p className="text-gray font-normal">
                  Choose the cover and premium you are comfortable with
                </p>
              </li>

              <li className="list-item">
                <p className="text-gray font-normal">
                  Download and read through the disclosures by clicking on the
                  link above the "Next" button.
                </p>
              </li>

              <li className="list-item">
                <p className="text-gray font-normal">
                  Click "Next" to start your application for cover.
                </p>
              </li>
            </ul>
          </div>

          <hr className="line-break"></hr>
        </div>

        <form className="relative" id="confirm-product-disclosure">
          <div className="mx-auto p-5 grid gap-5 lg:max-w-none lg:grid-cols-1">
            <div className="flex flex-1 flex-col justify-between">
              <div className="relative flex">
                <div className="flex items-center">
                  <input
                    id="agree"
                    required={true}
                    aria-describedby="agree-description"
                    name="agree"
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    className="h-10 w-10 border-light-blue text-light-blue checked:bg-light-blue rounded-full"
                  />
                </div>
                <div className="ml-2 text-sm">
                  <label htmlFor="agree" className="font-normal text-gray">
                    <span className="font-semibold">
                      I confirm that I have downloaded and read the
                      <a
                        href={product}
                        target="_blank"
                        className="border-b-2 border-transparent hover:border-light-blue text-light-blue font-bold"
                      >
                        {" "}
                        product disclosure.
                      </a>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default QuoteInformation;
