import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Stepper from "../components/multi-form-stepper/Stepper";
import { TrashIcon } from "@heroicons/react/20/solid";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import {
  steps,
  additionalCover,
  banks,
  branchCodes,
  accountTypes,
  products,
  applicationModal,
} from "../models/DataModels";
import ApplicationModal from "../components/modals/ApplicationModal";
import SummaryInformation from "../components/content/SummaryInformation";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { MainContext } from "../App";
import axios from "axios";
import Loading from "../components/modals/Loading";
import CallUsModal from "../components/modals/CallUsModal";

export default function Summary() {
  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
  const ENCRYPT_KEY = process.env.REACT_APP_ENCRYPT_KEY;
  const [dropOffId, setDropOffId] = useState(1);
  const additionalCoverRef = useRef(null);
  //const [showWarning, setShowWarning] = useState(false);
  const [productList, setProductList] = useState(products);
  const [showCallMe, setShowCallMe] = useState(false);
  const [showDropOff, setShowDropOff] = useState(false);
  const showDropOffRef = useRef(false);
  const [dropOffModel, setDropOffModel] = useState({
    id: 0,
    image: "",
    dropOff: "",
    title: "",
    messageSection1: "",
    messageSection2: "",
    messageSection3: "",
    messageSection4: "",
    messageSection5: "",
  });
  const closeCallMeModal = () => {
    setShowCallMe(false);
    navigate("/");
  };

  const onClickCallMe = () => {
    setShowCallMe(true);
  };

  const goBack = () => {
    setShowCallMe(false);
    return;
  };

  const [currentStep, setCurrentStep] = useState(3);
  const [lifeStyle, setLifeStyle] = useState([
    {
      description: "",
      value: "",
    },
  ]);
  const [personalDetails, setPersonalDetails] = useState([
    {
      description: "",
      value: "",
    },
  ]);
  const [contactDetails, setContactDetails] = useState([
    {
      description: "",
      value: "",
    },
  ]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([
    {
      description: "",
      value: "",
    },
  ]);
  const [data, setData] = useState({});
  const {} = data;

  useEffect(async () => {
    const fetchCustomer = async () => {
      const id = JSON.parse(localStorage.getItem("id"));
      const gender = JSON.parse(localStorage.getItem("gender"));
      const dob = JSON.parse(localStorage.getItem("dob"));


      const contextUser = JSON.parse(localStorage.getItem("ctx"))

      let pDetails = {...contextUser, id: id, gender: gender, dob: dob}

      setPersonalDetails([
        {
          description: "FULL NAME",
          value: pDetails.first_name + " " + pDetails.last_name,
        },

        {
          description: "D.O.B",
          value: dob,
        },
        {
          description: "IDENTITY NUMBER",
          value: pDetails.id_number,
        },
        {
          description: "HEIGHT",
          value: (pDetails.height ? `${pDetails.height} CM` : ''),
        },
        {
          description: "WEIGHT",
          value: (pDetails.weight ? `${pDetails.weight} KG` : ''),
        },
        {
          description: "QUALIFICATION",
          value: pDetails.qualification.toUpperCase(),
        },
        {
          description: "GROSS MONTHLY INCOME",
          value: `R ${pDetails.income}`,
        },
        {
          description: "TAKE HOME PAY",
          value: `R ${pDetails.take_home_pay}`,
        },
      ]);

      setLifeStyle([
        {
          description: "SMOKER STATUS",
          value:
          pDetails.smoker === false ? "NON SMOKER" : "SMOKER",
        },
        {
          description: "DRINKER STATUS",
          value:
          pDetails.drinker === false
            ? "NON DRINKER"
            : "DRINKER",
        },
        {
          description: "RISKY SPORTS OR ACTIVITIES",
          value: "NONE",
        },
        {
          description: "OCCUPATION",
          value: pDetails.occupation.toUpperCase(),
        },
      ]);

      setContactDetails([
        {
          description: "CONTACT NUMBER",
          value: pDetails.contact_number,
        },
        {
          description: "ALTERNATIVE CONTACT NUMBER",
          value: pDetails.alternative_contact_number,
        },
        {
          description: "EMAIL ADDRESS",
          value: pDetails.email_address,
        },
      ]);

      if ( localStorage.getItem('beneficiaries') !== null) {
        setBeneficiaryDetails(JSON.parse(localStorage.getItem('beneficiaries')));

          // .map((ben) => {
        //   setBeneficiaryDetails([
        //     {
        //       description: "BENEFICIARY FIRSTNAME",
        //       value: ben["first_name"].toUpperCase(),
        //     },
        //     {
        //       description: "BENEFICIARY LASTNAME",
        //       value: ben["last_name"].toUpperCase(),
        //     },
        //     {
        //       description: "RELATIONSHIP TO CLIENT",
        //       value: ben["relationship_to_client"].toUpperCase(),
        //     },
        //     {
        //       description: "BENEFICIARY IDENTIFICATION",
        //       value:
        //       ben["passport_number"] === null ||
        //         ben["passport_number"] === ""
        //         ? ben["id_number"]
        //         : ben["passport_number"],
        //     },
        //   ]);
        // });
      }

    };

    await fetchCustomer();
  }, []);

  const [customer_bank_details, setBankingDetails] = useState({
    bank_name: "",
    branch_code: "",
    branch: "",
    account_holder_name: "",
    account_type: "",
    account_number: "",
    debit_order_day: 1,
  });

  const {
    bank_name,
    branch_code,
    branch,
    account_holder_name,
    account_type,
    account_number,
    debit_order_day,
  } = customer_bank_details;

  const [show, setShow] = useState(false);
  const [isBranchCodeValid, setIsBranchCodeValid] = useState(true);
  const [isBranchValid, setIsBranchValid] = useState(true);
  const [isAccountNameValid, setIsAccountNameValid] = useState(true);
  const [isAccountTypeValid, setIsAccountTypeValid] = useState(true);
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(true);
  const [isDebitOrderDayValid, setIsDebitOrderDayValid] = useState(true);
  const [bankName, setBankName] = useState(null);
  const [tooltip, showTooltip] = useState(true);
  const [bankBranch, setBankBranch] = useState("");
  const [bankBranchCode, setBankBranchCode] = useState("");
  const [account, setAccount] = useState(null);
  const [isAccountValid, setIsAccountValid] = useState(false);
  const [openTab, setOpenTab] = useState("Personal Information");
  const [activeTab, setActiveTab] = useState(false);
  const tabs = [
    {
      name: "Personal Information",
      href: "personal-information",
      content: personalDetails,
      current: true,
    },
    {
      name: "Contact Details",
      href: "contact-details",
      content: contactDetails,
      current: false,
    },
    {
      name: "Nominated Beneficiary",
      href: "nominated-beneficiary",
      content: JSON.parse(localStorage.getItem("beneficiaries")),
      current: false,
    },
  ];

  const onSetActiveTab = (e) => {
    setOpenTab(e.target.innerText);

    if (openTab === e.target.innerText) {
      setActiveTab(true);
    } else {
      setActiveTab(true);
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "2px",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#3C4B6C",
    }),
    control: (styles, state) => ({
      ...styles,
      borderColor: "#858D9D",
      border: state.isFocused ? "2px solid #0033a1" : "1px solid #858D9D",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #0033a1",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  function isValidName(name) {
    return /(^[a-zA-Z_(),@'/-])/gm.test(name);
  }

  function isValidNumber(contactNumber) {
    return /^[0-9\b]+$/.test(contactNumber);
  }

  const onChange = (event) => {
    if (event.target.name === "branch") {
      if (!isValidName(event.target.value)) {
        setIsBranchValid(false);
      } else {
        setIsBranchValid(true);
      }

      if (event.target.value === "") {
        setIsBranchValid(true);
      }
    }

    if (event.target.name === "branch_code") {
      if (!isValidNumber(event.target.value)) {
        setIsBranchCodeValid(false);
      } else {
        setIsBranchCodeValid(true);
      }

      if (event.target.value === "") {
        setIsBranchCodeValid(true);
      }
    }

    if (event.target.name === "account_holder_name") {
      if (!isValidName(event.target.value)) {
        setIsAccountNameValid(false);
      } else {
        setIsAccountNameValid(true);
      }

      if (event.target.value === "") {
        setIsAccountNameValid(true);
      }
    }

    if (event.target.name === "account_type") {
      if (!isValidName(event.target.value)) {
        setIsAccountTypeValid(false);
      } else {
        setIsAccountTypeValid(true);
      }

      if (event.target.value === "") {
        setIsAccountTypeValid(true);
      }
    }

    if (event.target.name === "account_number") {
      if (!isValidNumber(event.target.value)) {
        setIsAccountNumberValid(false);
      } else {
        setIsAccountNumberValid(true);
      }

      if (event.target.value === "") {
        setIsAccountNumberValid(false);
      }
    }

    if (event.target.name === "debit_order_day") {
      if (!isValidNumber(event.target.value)) {
        setIsDebitOrderDayValid(false);
      } else {
        setIsDebitOrderDayValid(true);
      }

      if (event.target.value === "") {
        setIsDebitOrderDayValid(false);
      }

      if (event.target.value < 1) {
        setIsDebitOrderDayValid(false);
      }

      if (event.target.value > 31) {
        setIsDebitOrderDayValid(false);
      }
    }

    setBankingDetails({
      ...customer_bank_details,
      [event.target.name]: event.target.value,
    });
  };

  function onChangeBank(bank) {
    setBankName(bank);

    if (bank === null) {
      setBankBranch("");
      setBankBranchCode("");
    } else {
      branchCodes.forEach((item) => {
        if (bank.value === item.id) {
          setBankBranch(item.branch);
          setBankingDetails({ ...customer_bank_details, branch: item.branch });
          setBankBranchCode(item.branchCode);
          console.log("item b code: ", item.branchCode);
        }
      });
    }
    setBankingDetails({
      ...customer_bank_details,
      bank_name: bank?.label,
      branch: bankBranch,
      branch_code: bankBranchCode,
    });
  }

  function onChangeAccountType(account) {
    setAccount(account);

    if (account === null) {
      setIsAccountValid(false);
    } else {
      setIsAccountValid(true);
    }

    setBankingDetails({
      ...customer_bank_details,
      account_type: account.label,
    });
  }

  const onSubmit = async (e, contextUser) => {
    const bank_details = {
      bank_name: customer_bank_details.bank_name,
      account_holder_name: customer_bank_details.account_holder_name,
      account_type: customer_bank_details.account_type,
      account_number: customer_bank_details.account_number,
      debit_order_day: customer_bank_details.debit_order_day,
    };

    e.preventDefault();
    setShow(true);

    let formEmpty = checkIsFormEmpty(bank_details);
    if (formEmpty === false) {
      toast.error("Please full in all the required fields marked with (*)", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let formValid = checkIsFormValid();
    if (formValid === false) {
      toast.error("Please fix all validation errors", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var data = JSON.stringify({
      bank_details: bank_details,
    });

    const quoteId = JSON.parse(localStorage.getItem("quoteId"));

    var config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/complete-sale/${quoteId}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config)
      .then((response) => {
        if (response) {

          if (response.data.success === false) {
            closeModal();
            toast.warn(`Failed to complete sale`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log("Complete sale", JSON.stringify(response.data))
            );
          } else {
            toast.success("Information captured successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            localStorage.setItem("completedSale", JSON.stringify(response.data))
            closeModal();

            setTimeout(() => {
              window.location.assign("/success");
            }, 1000);

          }
        }
      })
      .catch((error) => {
        closeModal();
        toast.error(error.reason, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Complete sale", JSON.stringify(error));
        return;
      });
  };

  const checkIsFormEmpty = (bank_details) => {
    if (
      bank_details.account_holder_name === "" ||
      bank_details.account_number === 0 ||
      bank_details.debit_order_day === 0 ||
      bank_details.account_type === null ||
      bank_details.bank_name === null ||
      bank_details.branch === "" ||
      bank_details.branch_code === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkIsFormValid = () => {
    if (
      !isBranchCodeValid ||
      !isBranchValid ||
      !isAccountNameValid ||
      !isAccountNumberValid ||
      !isAccountTypeValid ||
      bankName === null ||
      !isDebitOrderDayValid
    ) {
      return false;
    } else {
      return true;
    }
  };

  const closeApplicationModal = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.assign("/");
    }, 1000);
  };

  const onClick = () => {
    return;
  };

  const onCompleteClicked = () => {
    navigate("/success");
    return;
  };

  function showEditNotification() {
    toast.success("Your policy has been updated!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  function showEditPreApprovedNotification() {
    toast.success("Additional cover has been added!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  function showDeleteNotification() {
    toast.success("Your policy has been deleted!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  const closeModal = () => {
      console.log("CLOSING W OVERLAY");
    setShow(false);
  };

  const [finalQuote, setFinalQuote] = useState([
    {
      product: "",
      cover: 0,
      premium: 0,
      underwriting_status: "",
    },
  ]);
  const [rows, setRows] = useState(finalQuote);
  const [rowIndex, setRowIndex] = useState(0);
  const [additionalCoverRows, setAdditionalCoverRows] =
    useState(additionalCover);
  const [rowEditable, setRowEditable] = useState(false);
  const [benefit, setBenefit] = useState(null);
  const [monthlyPremuim, setMonthlyPremuim] = useState(0);
  const [premuim, setPremuim] = useState(0);
  const [quoteEstimates, setQuoteEstimates] = useState({});
  const [coverEdit, setCoverEdit] = useState(null);
  const [currentSelectedProduct, setCurrentSelectedProduct] = useState("");

  useEffect( async () => {
    setDropOffId(7);
    const product = JSON.parse(localStorage.getItem("productKey"));
    if (product !== null && product !== undefined) {
      let dropOff = applicationModal.find((x) => x.id === 7);
      dropOff.messageSection1 = dropOff.messageSection1.replace(`{0}`, product);
      setDropOffModel(dropOff);
    }

    const getFinalQuote = async () => {
      setShow(true);
      const quoteId = JSON.parse(localStorage.getItem("quoteId"));

      var config = {
        method: "post",
        url: `${API_ENDPOINT}buyonline/final-quote/${quoteId}`,
        headers: {
          Authorization: `${AUTH_KEY}`,
        },
      };

      let response = await axios(config)
        .then((response) => {
          if (response) {
            if (response.data.success === false) {
              setShowDropOff(true);

              showDropOffRef.current = true;
              closeModal();
              Promise.reject(
                console.log("Fetch final quote", JSON.stringify(response.data))
              );
            } else {
              response.data.map((quote) => {
                  console.log(response.data);
                  console.log(productList);
                  let items = additionalCover;
                  let itemToRemove = items.find((x) => x.title === quote.product);
                  let index = items.indexOf(itemToRemove);
                  if (index !== -1) {
                      items.splice(index, 1);
                  }
                  console.log(items);
                  let ests = JSON.parse(sessionStorage.estimates);
                  additionalCoverRef.current = [];
                  items.forEach((i, index)=> {
                      let code = productList.filter(p=>p.id.toLowerCase() === i.title.toLowerCase())[0].value;
                      console.log("CODE: ", code);
                      i.estimates = ests.filter(e=>e.code.toLowerCase()===code.toLowerCase())[0].estimates;
                      i.code = code;
                      i.min_cover = i.estimates[0].cover;
                      i.step = i.estimates[1].cover - i.estimates[0].cover;
                      i.premium = i.estimates[0].premium;
                      console.log(productList);
                      console.log(i);
                      let objct = {};
                      objct["estimates"] =i.estimates;
                      objct["min_cover"] =i.min_cover;
                      objct["step"] =i.step;
                      objct["code"] = code;
                      objct["cover"] = i.min_cover;
                      objct["title"] = i.title;
                      additionalCoverRef.current[index] = objct;


                      additionalCoverRef.current[index] = objct;
                  });
                  setAdditionalCoverRows(items);
                  
              });
              checkForDeclinedQuotes(response.data);
              closeModal();
            }
          }
        })
        .catch((error) => {
          closeModal();
          setShowDropOff(true);
          showDropOffRef.current = true;
          console.log("Fetch final quote", JSON.stringify(error));
        });


      console.log("showDropOffRef.current: " + showDropOffRef.current)

      if (showDropOffRef.current === true) {
        console.log("submit drop off quote")
        console.log("AXIOS POST LEAD DROP OFF")
        // AXIOS POST LEAD DROP OFF
        const quoteId = JSON.parse(localStorage.getItem("quoteId"));
        var config = {
          method: "post",
          url: `${API_ENDPOINT}buyonline/submit-lead/by-quote-id/${quoteId}`,
          headers: {
            Authorization: `${AUTH_KEY}`,
            "Content-Type": "application/json",
          },
        };

        let submitLead = axios(config)
          .then((response) => {
            console.log("lead submitted");
            return;
          })
          .catch((error) => {
            toast.error(`An error occured while submitting drop-off lead`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return;
          });
      }

    };
    await getFinalQuote();



  }, []);

  const getQuoteEstimates = async (currentProduct) => {
    const custId = JSON.parse(localStorage.getItem("custId"));

    let selectedProduct = "";
    if (currentProduct === "life") {
      selectedProduct = "Life";
    } else {
      let result = currentProduct.toUpperCase();
      selectedProduct = result;
      closeModal();
    }

    var config = {
      method: "get",
      url: `${API_ENDPOINT}buyonline/quote-estimate/${custId}/${selectedProduct}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
      },
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            toast.warn(`Failed to fetch quote estimates`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log(
                "Fetch quote estimates",
                JSON.stringify(response.data)
              )
            );
          } else {
            setQuoteEstimates(response.data);

            let estimates = response.data.products.find(
              (x) => x.code === selectedProduct
            )?.estimates;

            let options = estimates.map((item, index) => ({
              value: index,
              label: item.cover,
            }));
            return options;
          }
        }
      })
      .catch((error) => {
        toast.error(`Failed to fetch quote estimates`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Fetch quote estimates", JSON.stringify(error));
        return;
      });
  };

  const addProduct = async (currentProduct) => {
    let result = true;
    setShow(true);
    let selectedProduct = "";
    if (currentProduct) {
      selectedProduct = products
        .find((x) => x.id === currentProduct.product)
        .value.toLowerCase();
    } else {
      closeModal();
    }

    const quoteId = JSON.parse(localStorage.getItem("quoteId"));

    var data = JSON.stringify({
      cover: currentProduct.cover,
    });

    var config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/add-quote-product/${quoteId}/${selectedProduct}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            closeModal();
            toast.warn(`Failed to add product`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log("Add product", JSON.stringify(response.data))
            );
          } else {
            let checkResult = checkForDeclinedQuotesOnAdd(response.data);
            if (checkResult) {
              result = false;
            }
            closeModal();
          }
        }
        return result;
      })
      .catch((error) => {
        closeModal();
        toast.warn(`Failed to add product`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Add product", JSON.stringify(error));
        return;
      });
  };

  const updateProduct = async (currentProduct) => {
    setShow(true);
    let selectedProduct = "";
    if (currentProduct) {
      selectedProduct = products
        .find((x) => x.value === currentProduct)
        .value.toLowerCase();
    } else {
      closeModal();
    }

    const quoteId = JSON.parse(localStorage.getItem("quoteId"));

    var data = JSON.stringify({
      cover: premuim,
    });

    var config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/adjust-quote-product/${quoteId}/${selectedProduct}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            closeModal();
            toast.warn(`Failed to update product`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log("Update product", JSON.stringify(response.data))
            );
          } else {
            checkForDeclinedQuotes(response.data);
            closeModal();
            return;
          }
        }
      })
      .catch((error) => {
        closeModal();
        toast.warn(`Failed to update product`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Update product", JSON.stringify(error));
        return;
      });
  };

  const removeProduct = async (currentProduct) => {
    setShow(true);
    let selectedProduct = "";
    if (currentProduct) {
      selectedProduct = products
        .find((x) => x.value === currentProduct)
        .value.toLowerCase();
    } else {
      closeModal();
    }

    const quoteId = JSON.parse(localStorage.getItem("quoteId"));

    var config = {
      method: "delete",
      url: `${API_ENDPOINT}buyonline/remove-quote-product/${quoteId}/${selectedProduct}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
      },
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          console.log("remove product response: ", response);
          if (response.data.success === false) {
            closeModal();
            toast.warn(`Failed to remove product`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log("Remove product", JSON.stringify(response.data))
            );
          } else {
            closeModal();
            return;
          }
        }
      })
      .catch((error) => {
        closeModal();
        toast.error(`Failed to remove product`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Remove product", JSON.stringify(error));
        return;
      });
  };

  const checkForDeclinedQuotes = (items) => {
    let declinedQuotes = items;
    let quotesToRemove = declinedQuotes.filter((x) => x.premium === "N/A");

    if (quotesToRemove.length > 0) {
      quotesToRemove.forEach((quoteToRemove) => {
        let index = items.indexOf(quoteToRemove);
        if (index !== -1) {
          items.splice(index, 1);
        }
      });
      setFinalQuote(items);
      setRows(items);
    } else {
      setFinalQuote(items);
      setRows(items);
    }
  };

  const checkForDeclinedQuotesOnAdd = (items) => {
    let declinedQuotes = items;
    let quotesToRemove = declinedQuotes.filter((x) => x.premium === "N/A");

    if (quotesToRemove.length > 0) {
      quotesToRemove.forEach((quoteToRemove) => {
        let index = items.indexOf(quoteToRemove);
        if (index !== -1) {
          items.splice(index, 1);
        }
      });
      setFinalQuote(items);
      setRows(items);
      console.log(quotesToRemove);
      toast.error(`Unfortunately this product has been declined and cannot be added to your policy`, {
        position: toast.POSITION.TOP_RIGHT, autoClose: false,
      });
      return false;
    } else {
      setFinalQuote(items);
      setRows(items);
      return true;
    }
  };

    const handleRemoveSpecificRow = (policy, id) => async () => {
        if (policy?.product === "") return;


        let currentProduct = products.find((x) => x.id === policy.product)?.value;
        console.log(policy);
        console.log(currentProduct);

        if (currentProduct === null || currentProduct === undefined) {
            const productKey = JSON.parse(localStorage.getItem("productKey"));
            if (productKey) {
                let selectedProduct = products.find((x) => x.id === productKey).value;
                currentProduct = selectedProduct;
            }
        }
        let removeId = id;
        setCurrentSelectedProduct(currentProduct);

        await removeProduct(currentProduct);

        let items = rows;
        items.splice(removeId, 1);

        setFinalQuote(items);
        setRows(items);

        let ests = JSON.parse(sessionStorage.estimates);
        let estimates = ests.filter(e=>e.code.toLowerCase()===currentProduct.toLowerCase())[0].estimates;
        let addToAdditionalCover = {
            title: policy.product,
            cover: policy.cover,
            premium: policy.premium,
            referenceNumber: policy.underwriting_status,
            estimates: estimates,
            cover: estimates[0].cover,
            min_cover: estimates[0].cover,
            step: estimates[1].cover - estimates[0].cover,
            code: currentProduct
        };
        console.log(addToAdditionalCover);

        setAdditionalCoverRows([...additionalCoverRows, addToAdditionalCover]);
        showDeleteNotification();
    };

    function onRowChange(newValue) {
        let currentProduct = products.find(
            (x) => x.value === currentSelectedProduct
        ).value;
        let selectedProduct = "";
        if (currentProduct === "life") {
            selectedProduct = "Life";
        } else {
            let result = currentProduct.toUpperCase();
            selectedProduct = result;
        }

        let estimates = quoteEstimates.products.find(
            (x) => x.code === selectedProduct
        )?.estimates;

        let options = estimates.map((item, index) => ({
            value: index,
            label: item.premium,
        }));

        let monthlyPremuim = options.find((x) => x.value === newValue.value);

        setBenefit(newValue);

        if (newValue !== null) {
            setMonthlyPremuim(monthlyPremuim.label);
            setPremuim(newValue.label);
        } else {
            setMonthlyPremuim("");
        }
    }

  const handleEditSpecificRow = (policy, id) => async () => {
    if (policy?.product === "") return;

    let currentProduct = products.find((x) => x.id === policy.product)?.value;

    if (currentProduct === null || currentProduct === undefined) {
      const productKey = JSON.parse(localStorage.getItem("productKey"));
      if (productKey) {
        let selectedProduct = products.find((x) => x.id === productKey).value;
        currentProduct = selectedProduct;
      }
    }

    setCurrentSelectedProduct(currentProduct);
    let options = await getQuoteEstimates(currentProduct);

    if (options !== null) {
      setCoverEdit(options);
    }

    setRowEditable(true);
    setRowIndex(id);
    setBenefit(null);
    setMonthlyPremuim(0);
  };

  const handleSaveSpecificRow = (policy, id) => async () => {
    let currentProduct = products.find((x) => x.id === policy.product)?.value;
    let items = rows;

    if (benefit === null) {
      setRowEditable(false);
      setRows(items);
      return;
    } else {
      await updateProduct(currentProduct);
      setRows(items);
      setRowEditable(false);
      showEditNotification();
    }
  };

    const handleAddAdditionalCoverRow = (additionalPolicy, index) => async () => {
        let item = {
            product: additionalPolicy.title,
            cover: additionalPolicy.cover,
            underwriting_status: "",
        };

        console.log("add additional: ");

        console.log(additionalPolicy);
        console.log(item);
        console.log(additionalCoverRef.current[index]);
        console.log(additionalCoverRows[index]);

        let result = await addProduct(item);
        let itemToRemove = additionalPolicy;
        handleRemoveAdditionalCoverRow(itemToRemove);
        if (result) showEditPreApprovedNotification();

        return;
    };

  function handleRemoveAdditionalCoverRow(itemToRemove) {
    let items = additionalCoverRows.filter(
      (row) => row.title !== itemToRemove.title
    );
    setAdditionalCoverRows(items);
  }


    const handleAdditionalCoverRowCoverUpdate = (e, item, value) => {
        let items = additionalCoverRows;
        let index = items.indexOf(item);
        let coverList = additionalCoverRef.current;
        console.log("ITEM INDEX: ", index);
        console.log(item, value);
        if (  index > -1 ) {
            item.cover = parseInt(value);
            item.premium = item.estimates.filter(p=>p.cover===parseInt(value))[0].premium;
            items[index] = item;
            console.log(items[index]);

            let objct = {};
            objct["code"] = item.code;
            objct["cover"] = parseInt(value);
            objct["premium"] = item.estimates.filter(p=>p.cover===parseInt(value))[0].premium;
            objct["title"] = item.title;
            objct["min_cover"] = item.min_cover;
            objct["step"] = item.step;
            objct["estimates"] =item.estimates;
            coverList[index] = objct;

            console.log(coverList);
        }
        console.log(items);
        console.log("COVER LIST: ", coverList);
        setAdditionalCoverRows(items);
        additionalCoverRef.current = coverList;

        e.target.closest(".value-block").querySelector(".post-cover").textContent = value;
        e.target.closest(".value-block").querySelector(".post-premium").textContent = item.premium;

    };

    const getCover = (index, post) => {
        console.log(post.code);
        console.log(additionalCoverRef.current && additionalCoverRef.current[index]);
        return additionalCoverRef.current[index][post.code];
    };


  return (
    <MainContext.Consumer>
      {(value) => (
        <div className="relative bg-light-white px-4 pt-20 pb-20 sm:px-6 lg:px-8 lg:pb-28 lg:pt-28">
          <div className="relative mx-auto lg:pl-36 lg:pr-36">
            <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-1">
              <div className="flex flex-col rounded-lg shadow-lg">
                <div className="flex flex-col bg-white xl:p-6 p-6">
                  <Stepper steps={steps} currentStep={3} />
                </div>
                <hr className="line-break pb-5 w-full"></hr>
                <Loading show={show} closeModal={closeModal} />
                <div className="grid grid-cols-6 bg-white">
                  <div className="col-span-6 sm:col-span-6">
                    <div className="flex flex-1 flex-col justify-between bg-white p-6">
                      {/* {showWarning ? (
                        <div
                          className="bg-blue border-l-4 border-r-4 border-orange text-white p-6 mb-5 text-center"
                          role="alert"
                        >
                          <p className="font-bold">
                            We are sorry looks like something went wrong.
                          </p>
                          <p className="font-bold">
                            Please try and refresh the page.
                          </p>
                          <p className="font-bold">
                            If you have any further questions regarding this,
                            one of our friendly agents would be happy to take
                            your call.
                          </p>
                          <p className="font-bold">
                            You can contact us on 0860 111 737, weekdays from
                            8am to 5pm.
                          </p>
                        </div>
                      ) : (
                        ""
                      )} */}

                      <div>
                        <h3 className="text-3xl font-normal leading-6 text-gray">
                          Confirm your policy with your banking details
                        </h3>
                      </div>
                    </div>

                    <div className="xl:px-6 xl:pb-5 px-6 pb-5">
                      <div className="sm:flex sm:items-center"></div>
                      <div className="mt-5 overflow-scroll shadow-lg ring-1 ring-white ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded">
                        <table className="min-w-full divide-y divide-white table-auto">
                          <thead className="bg-white">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-500 table-cell lg:table-cell sm:table-cell"
                              >
                                PRODUCT
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-500 table-cell lg:table-cell sm:table-cell"
                              >
                                BENEFIT
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-500 table-cell lg:table-cell sm:table-cell"
                              >
                                ACTUAL MONTHLY PREMIUM
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-500 table-cell lg:table-cell sm:table-cell"
                              >
                                LIFE COVER
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left font-semibold text-gray-500 table-cell lg:table-cell sm:table-cell"
                              ></th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-white bg-white">
                            {finalQuote?.map((policy, index) => (
                              <tr key={index}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl font-normal text-gray lg:table-cell sm:table-cell">
                                  {policy?.product}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl font-normal text-gray lg:table-cell sm:table-cell">
                                  <div>
                                    <span
                                      className={
                                        rowEditable === true &&
                                        index === rowIndex
                                          ? "hidden"
                                          : "items-center text-sm text-light-blue"
                                      }
                                    >
                                      R
                                    </span>
                                    {rowEditable === true &&
                                    index === rowIndex ? (
                                      <Select
                                        className="text-sm font-light text-gray"
                                        classNamePrefix="benefit"
                                        isDisabled={false}
                                        placeholder={"Benefit amount"}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        styles={customStyles}
                                        onChange={(value) => onRowChange(value)}
                                        value={benefit}
                                        options={coverEdit}
                                        menuPortalTarget={document.body}
                                      />
                                    ) : (
                                      <span className="pl-2">
                                        {policy?.cover}
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-2xl font-normal text-gray lg:table-cell sm:table-cell">
                                  <div>
                                    <span
                                      className={
                                        rowEditable === true &&
                                        index === rowIndex
                                          ? "items-center text-sm text-light-blue"
                                          : "items-center text-sm text-light-blue"
                                      }
                                    >
                                      R
                                    </span>
                                    {rowEditable === true &&
                                    index === rowIndex ? (
                                      <span className="pl-2">
                                        {monthlyPremuim}
                                      </span>
                                    ) : (
                                      <span className="pl-2">
                                        {policy?.premium}
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray lg:table-cell sm:table-cell">
                                  <div className="flex flex-1 flex-col items-start">
                                    <span className="text-sm text-light-blue">
                                      Status:
                                    </span>
                                    <span className="text-sm font-normal text-gray">
                                      {policy?.underwriting_status}
                                    </span>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 font-light lg:table-cell sm:table-cell">
                                  <div className="flex flew-row justify-between">
                                    {rowEditable === true ? (
                                      <DocumentPlusIcon
                                        aria-hidden="true"
                                        onClick={handleSaveSpecificRow(
                                          policy,
                                          index
                                        )}
                                        className="fill-blue w-8 h-8 xl:w-8 xl:h-8 sm:w-3 sm:h-3 cursor-pointer"
                                      ></DocumentPlusIcon>
                                    ) : (
                                      <PencilSquareIcon
                                        aria-hidden="true"
                                        onClick={handleEditSpecificRow(
                                          policy,
                                          index
                                        )}
                                        className="fill-blue w-8 h-8 xl:w-8 xl:h-8 sm:w-3 sm:h-3 cursor-pointer"
                                      ></PencilSquareIcon>
                                    )}

                                    <TrashIcon
                                      aria-hidden="true"
                                      onClick={handleRemoveSpecificRow(
                                        policy,
                                        index
                                      )}
                                      className="fill-blue w-8 h-8 xl:w-8 xl:h-8 sm:w-3 sm:h-3 cursor-pointer"
                                    ></TrashIcon>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 bg-white">
                  <div className="col-span-6 sm:col-span-6">
                    <div className="flex flex-1 flex-col justify-between bg-white p-6">
                      <div>
                        <h3 className="text-3xl font-normal leading-6 text-orange">
                          Explore additional cover
                        </h3>
                      </div>
                      <div className="relative">
                        <div className="mt-5 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3 bg-white">
                          {additionalCoverRows && additionalCoverRows.map((post, index) => (
                            <div
                              key={index}
                              className="flex flex-col overflow-hidden rounded-lg shadow-xl"
                            >
                              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                                <div className="flex-1">
                                  <div className="mt-2 block">
                                    <p className="text-gray font-normal text-2xl pb-5">
                                      {post.title}
                                    </p>
                                    <div className="w-full flex justify-between">
                                      <span className="text-gray-500 font-normal text-sm">
                                        ESTIMATE MINIMIUM COVER & PREMIUM
                                      </span>
                                    </div>
                                    <div className="w-full flex justify-between">
                                      <div className="mt-3 text-2xl text-gray value-block">
                                        <div className="flex justify-between">
                                          <div>
                                            <span className="text-sm text-light-blue pr-2">
                                              R
                                            </span>
                                            <span className="post-cover">{post.cover}</span>
                                          </div>
                                          <div>
                                            <span className="text-sm text-light-blue pr-2">
                                              R
                                            </span>
                                            <span className="post-premium">{post.premium}</span>
                                          </div>
                                        </div>

                                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6 pb-5">
                                          <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                                            <div className="range-slider__slider w-full">
                                              <input
                                                type="range"
                                                min={post.min_cover}
                                                step={post.step}
                                                max={post.estimates && post.estimates[post.estimates.length-1].cover}
                                                className="slider"
                                                id="rangeSlider"
                                                onChange={(e)=>{handleAdditionalCoverRowCoverUpdate(e, post,  e.target.value);}}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="relative px-5 pb-5 bg-white">
                                <div className="mx-auto grid gap-5 lg:max-w-none lg:grid-cols-1 bg-white">
                                  <div className="items-center">
                                    <div className="flex flex-1 flex-col items-center bg-white">
                                      <button
                                        className="bg-btn-primary-color font-bold w-64 xl:w-64 py-2 px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                                        type="button"
                                        onClick={handleAddAdditionalCoverRow(
                                            post, index
                                        )}
                                      >
                                        <span>ADD POLICY</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <form
                  onSubmit={(event) => onSubmit(event, value)}
                  id="banking-details-form"
                >
                  <div className="grid grid-cols-6 gap-0 xl:gap-6 bg-white">
                    <div className="col-span-6 sm:col-span-4">
                      <div className="flex flex-1 flex-col justify-between bg-white p-6">
                        <div className="grid grid-cols-6 gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="bankName"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  bankName === null
                                    ? "after:content-['*'] after:text-red font-normal"
                                    : "after:content-['*'] font-normal"
                                }
                              >
                                BANK
                              </span>
                            </label>
                            <div className="text-gray pb-2">
                              <Select
                                className="text-sm font-light text-gray"
                                classNamePrefix="bankName"
                                placeholder={"Your bank"}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={false}
                                styles={customStyles}
                                onChange={(value) => onChangeBank(value)}
                                value={bankName}
                                options={banks}
                              />
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="branch"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  branch.length === 0
                                    ? "after:content-['*'] after:text-red font-normal"
                                    : "after:content-['*'] font-normal"
                                }
                              >
                                YOUR BANK BRANCH
                              </span>
                            </label>
                            <div
                              className={
                                isBranchValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                readOnly={true}
                                placeholder="Your bank branch"
                                type="text"
                                name="branch"
                                id="branch"
                                autoComplete="branch"
                                value={bankBranch}
                                className="text-gray block border-none flex-1 text-sm bg-light-gray focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isBranchValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID BRANCH NAME
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="branch_code"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  branch_code === 0
                                    ? "after:content-['*'] after:text-red font-normal"
                                    : "after:content-['*'] font-normal"
                                }
                              >
                                BRANCH CODE
                              </span>
                            </label>
                            <div
                              className={
                                isBranchCodeValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                readOnly={true}
                                placeholder="Your branch code"
                                type="text"
                                name="branch_code"
                                id="branch_code"
                                autoComplete="branch_code"
                                value={bankBranchCode}
                                className="text-gray block border-none flex-1 text-sm bg-light-gray focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>
                            <p
                              className={
                                !isBranchCodeValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID BRANCH CODE
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="account_holder_name"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  account_holder_name.length === 0
                                    ? "after:content-['*'] after:text-red font-normal"
                                    : "after:content-['*'] font-normal"
                                }
                              >
                                ACCOUNT HOLDER NAME
                              </span>
                            </label>
                            <div
                              className={
                                isAccountNameValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                required={true}
                                placeholder="Your account holder name"
                                type="text"
                                name="account_holder_name"
                                id="account_holder_name"
                                autoComplete="account_holder_name"
                                value={account_holder_name}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isAccountNameValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID ACCOUNT NAME
                            </p>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="account"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  account === null
                                    ? "after:content-['*'] after:text-red font-normal"
                                    : "after:content-['*'] font-normal"
                                }
                              >
                                ACCOUNT TYPE
                              </span>
                            </label>
                            <div className="text-gray pb-2">
                              <Select
                                className="text-sm font-light text-gray"
                                classNamePrefix="account"
                                placeholder={"Your account type"}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={false}
                                styles={customStyles}
                                onChange={(value) => onChangeAccountType(value)}
                                value={account}
                                options={accountTypes}
                              />
                            </div>
                            <p
                              className={
                                !isAccountNameValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID ACCOUNT TYPE SELECTED
                            </p>
                          </div>
                        </div>
                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="account_number"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  account_number === 0
                                    ? "after:content-['*'] after:text-red font-normal"
                                    : "after:content-['*'] font-normal"
                                }
                              >
                                ACCOUNT NUMBER
                              </span>
                            </label>
                            <div
                              className={
                                isAccountNumberValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                required={true}
                                placeholder="Your account number"
                                type="text"
                                name="account_number"
                                id="account_number"
                                autoComplete="account_number"
                                value={account_number}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>
                            <p
                              className={
                                !isAccountNumberValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID ACCOUNT NUMBER
                            </p>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="debit_order_day"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  debit_order_day === 0
                                    ? "after:content-['*'] after:text-red font-normal"
                                    : "after:content-['*'] font-normal"
                                }
                              >
                                DEBIT ORDER DAY
                              </span>
                            </label>
                            <div
                              className={
                                isDebitOrderDayValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              {tooltip && (
                                <ReactTooltip
                                  className="flex text-sm font-light w-60"
                                  id="debit_order_day"
                                  place="right"
                                  effect="solid"
                                  textColor="#ffffff"
                                  backgroundColor="#0089FF"
                                  scrollHide={true}
                                  resizeHide={true}
                                >
                                  <p>
                                    I understand that the policy will only
                                    commence on the date my first premium is
                                    received.
                                  </p>
                                  <p>
                                    Once the policy has commenced, I will remain
                                    covered if the premium is collected on the
                                    agreed debit date.
                                  </p>

                                  <p>
                                    Should a premium not be collected on that
                                    agreed date, I will not be covered.
                                  </p>
                                </ReactTooltip>
                              )}

                              <input
                                data-tip
                                data-for="debit_order_day"
                                placeholder="Your debit order day"
                                required={true}
                                type="number"
                                min={1}
                                max={31}
                                name="debit_order_day"
                                id="debit_order_day"
                                autoComplete="debit_order_day"
                                value={debit_order_day}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                onMouseEnter={() => showTooltip(true)}
                                onMouseLeave={() => {
                                  showTooltip(false);
                                  setTimeout(() => showTooltip(true), 50);
                                }}
                              />
                            </div>
                            <p
                              className={
                                !isDebitOrderDayValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              DEBIT ORDER DAY INVALID
                            </p>
                          </div>
                        </div>
                        {/* <div className="flex py-5">
                          <div className="w-full flex justify-center hidden">
                            <button
                              className="bg-btn-primary-color font-bold xl:w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                              type="submit"
                              onClick={onClick}
                            >
                              <span>SAVE BANKING DETAILS</span>
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-2 gap-0">
                      <div className="flex flex-col flex-1 justify-between border-0 border-transparent outline-hidden bg-white pt-1 pb-6 px-6 xl:p-6 sticky top-0">
                        <div className="flex items-center justify-between bg-orange rounded-lg shadow-xl justify-content-evenly">
                          <LockClosedIcon
                            style={{scale: "0.7"}}
                            className="h-20 w-20 ml-1 mr-1 text-white stroke-white border-0 border-transparent"
                            aria-hidden="true"
                          />
                          <p className="text-white font-normal text-base leading-normal">
                            Please note that all information provided is managed
                            securely
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-0 xl:gap-6 bg-white">
                    <div className="col-span-6 sm:col-span-4">
                      <div className="flex flex-1 flex-col justify-between bg-white p-6">
                        <div>
                          <h3 className="text-3xl font-normal leading-6 text-gray pb-5">
                            Summary of your application
                          </h3>
                        </div>

                        <div>
                          <div className="container">
                            <div className="flex flex-col">
                              <ul className="flex xl:space-x-10 sm:space-x-2 border-b border-light-gray cursor-pointer justify-evenly">
                                {tabs.map((tab) => (
                                  <li key={tab.name}>
                                    <a
                                      href={tab.link}
                                      onClick={onSetActiveTab}
                                      className={
                                        openTab === tab.name
                                          ? "inline-block py-2 font-semibold text-sm text-light-blue border-blue border-b-2"
                                          : "inline-block py-2 text-gray font-semibold text-sm hover:text-light-blue hover:border-blue hover:border-b-2 focus:border-blue focus:border-b-2"
                                      }
                                    >
                                      {tab.name}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                              <div className="mt-6 bg-white border border-transparent">
                                {tabs.map((tab) => (
                                  <div
                                    key={tab.name}
                                    className={
                                      tab.name === openTab ? "block" : "hidden"
                                    }
                                  >
                                    {
                                      <>
                                        <h3 className="text-xl font-normal leading-6 text-blue pb-2">
                                          {tab.name}
                                        </h3>
                                        {tab.href === 'nominated-beneficiary' ? (

                                          tab?.content?.map((info) => (
                                            <div
                                              key={info.id}
                                              className="border-b border-gray-200"
                                            >
                                              <dl className="">
                                                <div className="py-5 flex justify-between">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Full Name
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {info.first_name} {info.last_name}
                                                  </dd>
                                                </div>
                                              </dl>

                                               <dl className="">
                                                <div className="py-5 flex justify-between">
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    Relationship
                                                  </dt>
                                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {info.relationship_to_client.replace('_', ' ').toUpperCase()}
                                                  </dd>
                                                </div>
                                              </dl>


                                              <dl className="">
                                                <div className="py-5 flex justify-between">


                                                  {info.id_number === "" ? (
                                                    <>
                                                      <dt className="text-sm font-medium text-gray-500">
                                                        Passport Number
                                                      </dt>
                                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                        {info.passport_number}
                                                      </dd>
                                                      </>
                                                  ) : (
                                                    <>
                                                      <dt className="text-sm font-medium text-gray-500">
                                                        ID Number
                                                      </dt>
                                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                        {info.id_number}
                                                      </dd>
                                                    </>
                                                  )}
                                                </div>
                                              </dl>

                                            </div>
                                          ))

                                        ) : tab.content.map((info) => (
                                          <div
                                            key={info.description}
                                            className="border-b border-gray-200"
                                          >
                                            <dl className="">
                                              <div className="py-5 flex justify-between">
                                                <dt className="text-sm font-medium text-gray-500">
                                                  {info.description}
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                  {info.value}
                                                </dd>
                                              </div>
                                            </dl>
                                          </div>
                                        ))}
                                      </>
                                    }
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="py-10">
                          <h3 className="text-xl font-normal leading-6 text-blue pb-2">
                            Lifestyle choices
                          </h3>
                          {lifeStyle?.map((info) => (
                            <div
                              key={info.description}
                              className="border-b border-gray-200"
                            >
                              <dl className="">
                                <div className="py-5 flex justify-between">
                                  <dt className="text-sm font-medium text-gray-500">
                                    {info.description}
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {info.value}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                          ))}
                        </div>

                        <div>
                          <h3 className="text-2xl font-normal leading-6 text-gray pb-5">
                            Review our Terms and Conditions
                          </h3>
                          <div className="flex flex-col pb-5">
                            <div className="mx-auto w-full bg-white-200 py-5">
                              <Disclosure>
                                {({ open }) => (
                                  <>
                                    <div className="flex w-full justify-between items-center rounded-lg px-4 py-2 text-left text-sm font-normal">
                                      <span className="text-xl w-16 xl:w-64 font-normal text-gray">
                                        General Terms & Conditions
                                      </span>
                                      <div className="ml-3 block items-start">
                                        <input
                                          id="term-and-conditions"
                                          name="term-and-conditions"
                                          type="checkbox"
                                          required={true}
                                          className="h-8 w-8 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full"
                                        />
                                        <label
                                          htmlFor="term-and-conditions"
                                          className="ml-5 text-sm font-light text-gray"
                                        >
                                          YES
                                        </label>
                                      </div>

                                      <Disclosure.Button>
                                        <ChevronDownIcon
                                          className={`${
                                            open ? "rotate-180 transform" : ""
                                          } h-5 w-5 text-gray justify-end`}
                                        />
                                      </Disclosure.Button>
                                    </div>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                      I understand that I am responsible to
                                      ensure the accuracy of any information
                                      supplied by me or any person on my behalf,
                                      I confirm that all information that I have
                                      supplied is true and complete. I have read
                                      the policy documents and I am satisfied
                                      with the accuracy and completeness of the
                                      information contained therein.
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          </div>

                          <div className="flex flex-col pb-5">
                            <div className="mx-auto w-full bg-white-200 py-5">
                              <Disclosure>
                                {({ open }) => (
                                  <>
                                    <div className="flex w-full justify-between items-center rounded-lg px-4 py-2 text-left text-sm font-normal">
                                      <span className="text-xl w-16 xl:w-64 font-normal text-gray">
                                        HIV Status Disclaimer
                                      </span>
                                      <div className="ml-3 block">
                                        <input
                                          id="term-and-conditions"
                                          name="term-and-conditions"
                                          type="checkbox"
                                          required={true}
                                          className="h-8 w-8 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full"
                                        />
                                        <label
                                          htmlFor="term-and-conditions"
                                          className="ml-5 text-sm font-light text-gray"
                                        >
                                          YES
                                        </label>
                                      </div>

                                      <Disclosure.Button>
                                        <ChevronDownIcon
                                          className={`${
                                            open ? "rotate-180 transform" : ""
                                          } h-5 w-5 text-gray`}
                                        />
                                      </Disclosure.Button>
                                    </div>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                      I understand that I am responsible to
                                      ensure the accuracy of any information
                                      supplied by me or any person on my behalf,
                                      I confirm that all information that I have
                                      supplied is true and complete. I have read
                                      the policy documents and I am satisfied
                                      with the accuracy and completeness of the
                                      information contained therein.
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          </div>

                          <div className="flex flex-col pb-5">
                            <div className="mx-auto w-full bg-white-200 py-5">
                              <Disclosure>
                                {({ open }) => (
                                  <>
                                    <div className="flex w-full justify-between items-center rounded-lg px-4 py-2 text-left text-sm font-normal">
                                      <span className="text-xl w-16 xl:w-64 font-normal text-gray">
                                        Other Important Info
                                      </span>
                                      <div className="xl:ml-3 sm:ml-1 block">
                                        <input
                                          id="term-and-conditions"
                                          name="term-and-conditions"
                                          type="checkbox"
                                          required={true}
                                          className="h-8 w-8 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full"
                                        />
                                        <label
                                          htmlFor="term-and-conditions"
                                          className="ml-5 text-sm font-normal text-gray"
                                        >
                                          YES
                                        </label>
                                      </div>

                                      <Disclosure.Button>
                                        <ChevronDownIcon
                                          className={`${
                                            open ? "rotate-180 transform" : ""
                                          } h-5 w-5 text-gray`}
                                        />
                                      </Disclosure.Button>
                                    </div>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                      Your 31-day cooling-off period starts from
                                      acceptance of this quotation. During this
                                      time you can cancel your policy and be
                                      eligible for a premium refund. However,
                                      this also means that you are not eligible
                                      to claim for an event that occurred within
                                      the period you were refunded for. Once the
                                      application is approved, you will receive
                                      the full terms and conditions of our
                                      agreement in your policy document. Please
                                      ensure that you review the information
                                      that you provided us, including lifestyle
                                      choices, medical questions and bank
                                      details, and contact us immediately if you
                                      find any inaccuracies.
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-2">
                      <div className="flex flex-1 flex-col justify-between bg-white pt-1 pb-6 px-6 xl:p-6 sticky top-0">
                        <SummaryInformation></SummaryInformation>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 sm:gap-0 gap-6 bg-white-200 pb-2">
                    <div className="col-span-6 sm:col-span-6">
                      <p className="flex flex-1 flex-col text-gray font-normal justify-between p-6">
                        I authorise Liberty to debit all premium amounts due
                        from this account, as per the terms of my contract with
                        them. This authorisation is to remain in force until
                        terminated by Liberty or myself.
                      </p>
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <div className="w-100 flex text-gray justify-start font-normal items-center p-6">
                        <div className="flex items-center">
                          <input
                            id="agree"
                            required={true}
                            aria-describedby="agree-description"
                            name="agree"
                            type="checkbox"
                            className="h-8 w-8 border-light-blue text-light-blue focus:ring-light-blue rounded-full"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="agree"
                            className="font-light text-gray"
                          >
                            <p>
                              I accept that Liberty may debit my account on a
                              date other than that specified. The debit order
                              amount that I have agreed to as part of this
                              application is as stipulated above.
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <div className="w-100 flex text-gray justify-start items-center p-6">
                        <div className="flex items-center">
                          <input
                            id="agree"
                            required={true}
                            aria-describedby="agree-description"
                            name="agree"
                            type="checkbox"
                            className="h-8 w-8 border-light-blue text-light-blue focus:ring-light-blue rounded-full"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="agree"
                            className="font-light text-gray"
                          >
                            <p>
                              I authorise Liberty to debit all premium amounts
                              due from this account, as per the terms of my
                              contract.
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <div className="w-100 flex text-gray justify-start items-center p-6">
                        <div className="flex items-center">
                          <input
                            id="agree"
                            required={true}
                            aria-describedby="agree-description"
                            name="agree"
                            type="checkbox"
                            className="h-8 w-8 border-light-blue text-light-blue focus:ring-light-blue rounded-full"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="agree"
                            className="font-light text-gray"
                          >
                            <p>
                              If my debit order date falls on a weekend or
                              public holiday, I agree that the premium may be
                              debited from my account on the business day prior.
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <div className="w-100 flex text-gray justify-start items-center p-6">
                        <div className="flex items-center">
                          <input
                            id="agree"
                            required={true}
                            aria-describedby="agree-description"
                            name="agree"
                            type="checkbox"
                            className="h-8 w-8 border-light-blue text-light-blue focus:ring-light-blue rounded-full"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="agree"
                            className="font-light text-gray"
                          >
                            <p>
                              I agree that, although I may cancel this authority
                              and mandate, that will not cancel the Agreement. I
                              also understand that I cannot claim back amounts
                              that Liberty has deducted from my account under
                              this debit order mandate if I legally owed those
                              amounts to Liberty
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <p className="flex flex-1 flex-col text-gray font-normal justify-between p-6">
                        I further acknowledge and agree that this amount may
                        change at each policy anniversary in line with the terms
                        and conditions of my policy or where I’ve agreed to
                        changes in the initial premium.
                      </p>
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <p className="flex flex-1 flex-col text-gray font-normal justify-between p-6">
                        By clicking complete, I consent to all of the above.
                      </p>
                    </div>
                  </div>
                  <div className="p-6 bg-white">
                    <div className="flex flex-1 flex-col items-center xl:flex xl:flex-row xl:justify-between xl:w-full">
                      <button
                        className="w-64 xl:w-64 mb-2 xl:mb-0 py-2 px-4 bg-btn-secondary-color text-light-blue shadow-sm border-light-blue hover:bg-btn-primary-color hover:text-white hover: border-solid border-2 font-bold"
                        type="button"
                        onClick={onClickCallMe}
                      >
                        <span>LET US CALL YOU</span>
                      </button>
                      <button
                        className="bg-btn-primary-color font-bold mt-2 xl:mt-0 w-64 xl:w-64 py-2 px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                        type="submit"
                      >
                        <span>COMPLETE</span>
                      </button>
                    </div>
                  </div>
                </form>
                <CallUsModal
                  show={showCallMe}
                  closeModal={closeCallMeModal}
                  goBack={goBack}
                />

                <ApplicationModal
                  dropOffModel={dropOffModel}
                  dropOffId={dropOffId}
                  show={showDropOff}
                  closeModal={closeApplicationModal}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </MainContext.Consumer>
  );
}
