import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/button/Button";
import { products } from "../models/DataModels";
import { useNavigate } from "react-router-dom";

export default function Success() {
  const navigate = useNavigate();
  const [sale, setSale] = useState();

  function redirectUser() {
    localStorage.clear();
    navigate("/");
  }

  useEffect(() => {
    setSale(JSON.parse(localStorage.getItem('completedSale')))
    console.log(JSON.parse(localStorage.getItem('completedSale')))
  }, []);

  return (
    <div className="relative bg-light-white px-4 pb-20 sm:px-6 lg:px-8 lg:pb-28 lg:pt-10">
      <div className="relative mx-auto lg:pl-36 lg:pr-36">
        <div className="mx-auto pt-20 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-1">
          <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="flex flex-col overflow-hidden">
              <div className="flex flex-1 flex-col justify-between bg-white p-5">
                <div className="flex-1">
                  <div className="mt-2 block">
                    <h1 className="mb-5 text-gray font-normal leading-6 text-3xl">
                      Success!
                    </h1>
                    <p className="mb-5 text-gray font-normal">
                      You have successfully applied for {JSON.parse(localStorage.getItem('productKey'))} with
                      Liberty's direct life insurance services. We will confirm
                      approval of your application by sending you your policy
                      documents, via email, in the next few days.
                    </p>
                    <p className="mb-5 text-gray font-normal">
                      For reference, your policy bank reference numbers are:
                    </p>

                    <div className="mb-5 text-gray font-normal">
                      {sale?.products?.map((product) => (
                        <div key={product?.bank_reference}>
                          <p className="mb-5 text-gray font-normal">
                            {Object.values(products).filter((v) => v.value === product?.product)[0].id}
                          </p>

                          <p className="mb-5 text-gray text-2xl font-normal border-b-2 border-gray-200 w-56">
                            {product?.bank_reference}
                          </p>
                        </div>
                      ))}
                    </div>
                    <p className="mb-5 text-gray font-normal ">
                      We will be processing your application in the next 3
                      working days. During that time, if we need to check any
                      information with you, we will give you a call. If we are
                      not able to get hold of you, we may not be able to
                      commence your cover with us. If you have not received your
                      policy documents within two weeks from today, please
                      contact Customer Service on the details below. If we do
                      not hear from you we&apos;ll assume that you are in possession
                      of your documents.
                    </p>
                    <p className="mb-5 text-gray font-normal">
                      Your 31-day cooling-off period starts from acceptance of
                      this quotation. During this time you can cancel your
                      policy and be eligible for a premium refund. However, this
                      also means that you are not eligible to claim for an event
                      that occurred within the period you were refunded for. To
                      do this contact Customer Service.
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6">
                  {/* <div className="col-span-6 sm:col-span-2">
                    <p className="mb-5 text-gray-500 font-normal">
                      PRE-SALE QUERIES
                    </p>
                    <span className="mb-5 text-white font-normal">
                      <p>support@standardbankinsurance.co.za</p>
                    </span>
                  </div> */}
                  <div className="col-span-6 sm:col-span-2">
                    <p className="mb-5 text-gray-500 font-normal">
                      POST-SALE QUERIES
                    </p>
                    <span className="mb-5 text-light-blue font-normal">
                      <Link to="/">service@standardbankinsurance.co.za</Link>
                    </span>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    <p className="mb-5 text-gray-500 font-normal">
                      CONTACT NUMBER
                    </p>
                    <span className="mb-5 text-light-blue font-normal">
                      <Link to="/">0860 225 534</Link>
                    </span>
                  </div>
                </div>

                <div className="bg-white px-4 py-6 text-center sm:px-6">
                  <Button
                    type="submit"
                    variant="solid"
                    color="slate"
                    className="w-52 font-bold"
                    onClick={redirectUser}
                  >
                    <span>DONE</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
