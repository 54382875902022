import React, { useState } from "react";
import CallUsModal from "../modals/CallUsModal";
import { useNavigate } from "react-router-dom";

const PersonalInformation = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
    navigate("/");
    return;
  };

  const onClick = () => {
    setShow(true);
    return;
  };

  const goBack = () => {
    setShow(false);
    return;
  };

  return (
    <div>
      <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex flex-1 flex-col justify-between bg-gradient-to-r from-primary-color to-secondary-color p-6">
          <div className="mt-2 block h-10 items-center text-center">
            <span className="text-white text-center text-md font-normal mb-5">
              Personal Information
            </span>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-center">
          <p className="mt-3 font-normal p-2 text-sm text-center text-gray mb-5 indent-8">
            To provide you with the insurance product, we need your consent to
            us processing any personal information you provide. It will be
            securely stored, kept confidential and only be used by us and our
            partners. This includes your payment history and your individual and
            family medical history. For risk management purposes we may exchange
            claim information with other insurers. We'll need access to your
            medical and other relevant information to process a claim. Please
            tick the box provided in the bottom left.
          </p>

          <hr className="line-break"></hr>
          <p className="mt-3 text-base font-normal text-gray">
            Would you like us to call you?
          </p>
        </div>

        <div className="relative mx-5">
          <div className="mx-auto mt-5 grid gap-5 lg:max-w-none lg:grid-cols-1">
            <div className="items-center pb-5">
              <div className="flex flex-1 flex-col items-center text-center">
                <button
                  className="xl:w-100 w-64 font-bold py-2 px-4 sm:py-2 sm:px-4 bg-btn-secondary-color text-light-blue shadow-sm border-light-blue hover:bg-btn-primary-color hover:text-white hover: border-solid border-2"
                  type="submit"
                  onClick={onClick}
                >
                  <span>LET US CALL YOU</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <CallUsModal show={show} closeModal={closeModal} goBack={goBack} />
      </div>
    </div>
  );
};

export default PersonalInformation;
