import moment from "moment";
import LifeCover from "../assets/images/life-cover.svg";
import Disability from "../assets/images/disability-cover.svg";
import Salary from "../assets/images/salary-protection.svg";
import Illness from "../assets/images/serious-illness-cover.svg";
import Shield from "../assets/images/shield-paper.svg";
import SalaryProtection from "../assets/terms/product_brochures/31311_PBB_DLIS_Salary_Protection_cover_Buy_Online.pdf";
import Life from "../assets/terms/product_brochures/31311_PBB_DLIS_Life_cover_Buy_Online.pdf";
import SeriousIllness from "../assets/terms/product_brochures/31311_PBB_DLIS_Serious_Illness_cover_Buy_Online.pdf";
import DisabilityCover from "../assets/terms/product_brochures/31311_PBB_DLIS_Disability_Cover_Buy_Online.pdf";
import SalaryDisclosure from "../assets/terms/product_disclosures/SBDLIS_Product_disclosures_for_Salary_Protection.pdf";
import LifeDisclosure from "../assets/terms/product_disclosures/SBDLIS_Product_disclosures_for_Life_Cover.pdf";
import IllnessDisclosure from "../assets/terms/product_disclosures/SBDLIS_Product_disclosures_for_Serious_Illness.pdf";
import DisabilityDisclosure from "../assets/terms/product_disclosures/SBDLIS_Product_disclosures_for_Disability_Cover.pdf";

export const steps = ["Application", "Quote", "Buy"];

export const productCover = [
  { value: "life-cover", id: "Life Cover" },
  { value: "serious-illness-cover", id: "Serious Illness Cover" },
  { value: "salary-protection", id: "Salary Protection" },
  { value: "disability-cover", id: "Disability Cover" },
];

export const productSlugs = [
  "salary-protection",
  "life-cover",
  "serious-illness-cover",
  "disability-cover",
];

export const products = [
  { value: "life", id: "Life Cover" },
  { value: "cib", id: "Serious Illness Cover" },
  { value: "ip13", id: "Salary Protection" },
  { value: "lsd", id: "Disability Cover" },
];

export const titleTypes = [
  { value: "mr", label: "Mr" },
  { value: "mrs", label: "Mrs" },
  { value: "ms", label: "Ms" },
  { value: "miss", label: "Miss" },
  { value: "dr", label: "Dr" },
  { value: "rev", label: "Rev" },
  { value: "prof", label: "Prof" },
];

export const languageTypes = [
  { value: "english", label: "English" },
  { value: "afrikaans", label: "Afrikaans" },
  { value: "xhosa", label: "Xhosa" },
  { value: "zulu", label: "Zulu" },
  { value: "tsonga", label: "Tsonga" },
  { value: "sepedi", label: "Sepedi" },
  { value: "setswana", label: "Setswana" },
  { value: "swati", label: "Swati" },
  { value: "sotho", label: "Sotho" },
  { value: "venda", label: "Venda" },
];

export const genderTypes = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
];

export const accountTypes = [
  { value: 0, label: "Cheque" },
  { value: 1, label: "Savings" },
  { value: 2, label: "Credit" },
  { value: 3, label: "Current" },
];

export const maritalTypes = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
  { value: "widowed", label: "Widowed" },
  { value: "divorced", label: "Divorced" },
];

export const provinceTypes = [
  { value: "wc", label: "Western Cape" },
  { value: "gt", label: "Gauteng" },
  { value: "lim", label: "Limpopo" },
  { value: "ec", label: "Eastern Cape" },
  { value: "fs", label: "Free State" },
  { value: "kzn", label: "KwaZulu-Natal" },
  { value: "mpu", label: "Mpumalanga" },
  { value: "nc", label: "Northern Cape" },
  { value: "nw", label: "North West" },
];

export const educationTypes = [
  { value: "no_matric", label: "No Matric" },
  { value: "matric", label: "Matric" },
  { value: "certificate", label: "Certificate" },
  { value: "diploma", label: "Diploma" },
  { value: "degree", label: "Degree" },
  { value: "post_grad", label: "Post Graduate" },
  { value: "professional", label: "Professional" },
];

export const tobaccoTypes = [
  { value: "CIGARETTES", label: "Cigarettes" },
  { value: "CIGARS", label: "Cigars" },
  { value: "PIPE", label: "Pipe" },
  { value: "OTHER", label: "Other" },
];

export const navigation = [
  { name: "Salary Protection", href: "/salary-protection" },
  { name: "Life Cover", href: "/life-cover" },
  { name: "Serious Illness Cover", href: "/serious-illness-cover" },
  { name: "Disability Cover", href: "/disability-cover" },
];

export const footerNavigation = {
  products: [
    { name: "Salary Protection", href: SalaryProtection, target: "_blank" },
    { name: "Life Cover", href: Life, target: "_blank" },
    { name: "Serious Illness", href: SeriousIllness, target: "_blank" },
    { name: "Disability Cover", href: DisabilityCover, target: "_blank" },
    {
      name: "More insurance from Standard Bank",
      href: "https://www.standardbank.co.za/southafrica/personal/products-and-services/insure-what-matters/yourself",
      target: "_blank",
    },
  ],

  links: [
    {
      name: "Standard Bank South Africa",
      href: "https://www.standardbank.co.za/southafrica/personal/home",
    },
  ],
};

export const productDisclosures = [
  {
    id: "Salary Protection",
    value: SalaryDisclosure,
  },
  {
    id: "Life Cover",
    value: LifeDisclosure,
  },
  {
    id: "Serious Illness Cover",
    value: IllnessDisclosure,
  },
  {
    id: "Disability Cover",
    value: DisabilityDisclosure,
  },
];

export const footerPolicyNavigation = {
  disclaimer: [
    {
      policy:
        "© " +
        moment(new Date()).format("YYYY") +
        " Products are underwritten by Liberty Group Limited, a licensed life insurer and an authorised financial services provider (FSP 2409). Products branded by Standard Bank.",
    },
  ],
  links: [
    {
      name: "Legal |",
      href: "https://www.standardbank.co.za/southafrica/personal/about-us/legal",
    },
    {
      name: "Regulator",
      href: "https://www.standardbank.co.za/southafrica/personal/about-us/regulatory",
    },
  ],
};

export const tellMeMoreLife = [
  {
    productKey: "life",
    title: "Learn More About Life Cover",
    href: "/",
    detailsSection: [
      {
        title: "Would your loved ones get by without your financial support?",
        message:
          "Liberty's direct life insurance services offers a tax-free lump sum payout of up to R5 million to your chosen beneficiaries when you pass away. With Liberty's direct life insurance services, you can take out Life Cover directly, without using a broker. Going direct means you save time and money.",
        imageUrl: LifeCover,
      },
      {
        title: "How much cover will you get?",
        message:
          "The amount of cover you’ll get will be based on a number of factors, some being your individual risk profile and your monthly premium. You can give a obligation-free quote based on your own details",
        imageUrl: LifeCover,
      },
      {
        title: "Convenience",
        message:
          "Because life is unpredictable, you can change or add beneficiaries on your life insurance policy at any time. We’ll send our Nurses on Wheels to you to have your blood tests done – free within the first 90 days of taking out your policy.",
        imageUrl: LifeCover,
      },
    ],
  },
];

export const tellMeMoreSalary = [
  {
    productKey: "salary",
    title: "Learn more about Salary Protection",
    href: "/",
    detailsSection: [
      {
        title: "If you couldn’t work tomorrow, how would you pay the bills?",
        message:
          "With Salary Protection from Liberty's direct life insurance services you’ll be able to safeguard your salary in the event that you cannot perform your job for a period of time, due to illness or injury. We’re not always as in control of life as we’d like to be. In the event of you being unable to work, Liberty's direct life insurance offers a reliable means of income, through monthly payouts, until you can work again.",
        imageUrl: Salary,
      },
      {
        title: "How much cover will you get?",
        message:
          "The amount of cover you’ll get will be based on a number of factors, some being your individual risk profile and your monthly premium. You can give a obligation-free quote based on your own details",
        imageUrl: Salary,
      },
      {
        title: "Convenience",
        message:
          "Within the first 90 days of your policy, you’ll be able to get all required blood tests done for free by our Nurses on Wheels, who will come to you. In addition, you can apply to increase your cover at any time with Liberty’s Salary Protection.",
        imageUrl: Salary,
      },
    ],
  },
];

export const tellMeMoreDisability = [
  {
    productKey: "disability",
    title: "Learn more about Disability Cover",
    href: "/",
    detailsSection: [
      {
        title:
          "How would you cope financially if you were permanently disabled?",
        message:
          "With Disability Cover from Liberty's direct life insurance services, you’ll receive a tax-free lump sum payout of up to R1.5 million should you ever become permanently disabled.",
        imageUrl: Disability,
      },
      {
        title: "How much cover will you get?",
        message:
          "The amount of cover you’ll get will be based on a number of factors, some being your individual risk profile and your monthly premium. You can give a obligation-free quote based on your own details.",
        imageUrl: Disability,
      },
      {
        title: "Convenience",
        message:
          "Within the first 90 days of your policy, you’ll be able to get all required blood tests done for free by our Nurses on Wheels, who will come to you. In addition, you can apply to increase your cover at any time with Liberty’s Disability Cover.",
        imageUrl: Disability,
      },
    ],
  },
];

export const tellMeMoreIllness = [
  {
    productKey: "illness",
    title: "Learn more about Serious Illness Cover",
    href: "/",
    detailsSection: [
      {
        title:
          "If you get very ill, how will you support your loved ones financially?",
        message:
          "With Serious Illness Cover from Liberty's direct life insurance services, you get a tax-free payout of between R100,000 and R2 million should you be diagnosed with cancer, suffer a heart attack or stroke or have to undergo open-heart surgery.",
        imageUrl: Illness,
      },
      {
        title: "How much cover will you get?",
        message:
          "The amount of cover you’ll get will be based on a number of factors, some being your individual risk profile and your monthly premium. You can give a obligation-free quote based on your own details.",
        imageUrl: Illness,
      },
      {
        title: "Convenience",
        message:
          "Within the first 90 days of your policy, you’ll be able to get all required blood tests done for free by our Nurses on Wheels, who will come to you. In addition, you can apply to increase your cover at any time with Liberty’s Serious Illness Cover.",
        imageUrl: Illness,
      },
    ],
  },
];

export const posts = [
  {
    title: "Life Cover",
    href: "/life-cover",
    getQuote: "/quick-quote",
    buyNow: "/life-cover",
    description: "Look after your family, even after your death",
    imageUrl: LifeCover,
    tellMeMore: "Tell me more",
    tellMeMoreHref: "/tell-me-more",
  },
  {
    title: "Salary Protection",
    href: "/salary-protection",
    getQuote: "/quick-quote",
    buyNow: "/salary-protection",
    description: "Safeguard your salary",
    imageUrl: Salary,
    tellMeMore: "Tell me more",
    tellMeMoreHref: "/tell-me-more-salary",
  },
  {
    title: "Disability Cover",
    href: "/disability-cover",
    getQuote: "/quick-quote",
    buyNow: "/disability-cover",
    description: "Cope financially if you were permanently disabled",
    imageUrl: Disability,
    tellMeMore: "Tell me more",
    tellMeMoreHref: "/tell-me-more-disability",
  },
  {
    title: "Serious Illness Cover",
    href: "/serious-illness-cover",
    getQuote: "/quick-quote",
    buyNow: "/serious-illness-cover",
    description: "How will you support your loved ones financially?",
    imageUrl: Illness,
    tellMeMore: "Tell me more",
    tellMeMoreHref: "/tell-me-more-illness",
  },
];

export const personalSteps = [
  { name: "Application", href: "#", status: "complete" },
  { name: "Quote", href: "#", status: "current" },
  { name: "Buy", href: "#", status: "upcoming" },
];

export const medicalQuestionsOptions = [
  { id: "yes", title: "YES" },
  { id: "no", title: "NO" },
];

export const smokedOptions = [
  { id: "smoker", title: "YES" },
  { id: "nonSmoker", title: "NO" },
];

export const coverOptions = [
  { id: "coverYes", title: "YES" },
  { id: "coverNo", title: "NO" },
];

export const alreadyGotCover = [
  { id: "alreadyGotCoverYes", title: "YES" },
  { id: "alreadyGotCoverNo", title: "NO" },
];

export const travelOptions = [
  { id: "travelYes", title: "YES" },
  { id: "travelNo", title: "NO" },
];

export const alcholOptions = [
  { id: "alcholYes", title: "YES" },
  { id: "alcholNo", title: "NO" },
];

export const activityOptions = [
  { id: "activityYes", title: "YES" },
  { id: "activityNo", title: "NO" },
];

export const productDisclosure = [
  { id: "disclosureYes", title: "YES" },
  { id: "disclosureNo", title: "NO" },
];

export const marketingConsent = [
  { id: "consentYes", title: "YES" },
  { id: "consentNo", title: "NO" },
];

export const libertyGroupConsent = [
  { id: "consentLibertyGroupYes", title: "YES" },
  { id: "consentLibertyGroupNo", title: "NO" },
];

export const otherConsent = [
  { id: "consentOtherYes", title: "YES" },
  { id: "consentOtherNo", title: "NO" },
];

export const researchConsent = [
  { id: "consentResearchYes", title: "YES" },
  { id: "consentResearchNo", title: "NO" },
];

export const marketingConsentAll = [
  { id: "consentAllYes", title: "YES" },
  { id: "consentAllNo", title: "NO" },
];

export const medicalConsent = [
  { id: "hasMedicalConsent", title: "YES" },
];

export const idType = [
  { id: "idNumber", title: "ID Number" },
  { id: "passportNumber", title: "Passport Number" },
];

export const relationship = [
  { value: "immediate_family", label: "Immediate Family" },
  { value: "spouse", label: "Spouse" },
  { value: "other_family", label: "Other Family" },
  { value: "other", label: "Other" },
];

export const beneficiary = [
  {
    id: 1,
    firstName: "Lindsay",
    lastName: "Walton",
    idNumber: "8903275059084",
    relationship: "Sister",
    claimPercentage: "5%",
  },
  {
    id: 2,
    firstName: "Janica",
    lastName: "Walton",
    idNumber: "8903275059085",
    relationship: "Wife",
    claimPercentage: "5%",
  },
  {
    id: 3,
    firstName: "Janica",
    lastName: "Walton",
    idNumber: "8903275059085",
    relationship: "Wife",
    claimPercentage: "5%",
  },
];

export const applicationModal = [
  {
    id: 1,
    image: Shield,
    dropOff: "occupation",
    title: "We are unfortunately unable to process your application",
    messageSection1:
      "Unfortunately, due to the nature of the risk involved in the occupation you have indicated, we will not be able to process your online application at this time.",
    messageSection2:
      "Thank you for your interest and if you have any further questions regarding this, one of our friendly agents would be happy to take your call. You can contact us on 0860 111 737, weekdays from 8am to 5pm.",
  },
  {
    id: 2,
    image: Shield,
    dropOff: "smoker",
    title: "We are unfortunately unable to process your application",
    messageSection1:
      "Unfortunately, we will not be able to process your application at this time due to the nature of your smoking habits.",
    messageSection2:
      "Thank you for your interest and if you have any further questions regarding this, one of our friendly agents would be happy to take your call. You can contact us on 0860 111 737, weekdays from 8am to 5pm.",
  },
  {
    id: 3,
    image: Shield,
    dropOff: "monthlyIcome",
    title: "We are unfortunately unable to process your application",
    messageSection1:
      "Unfortunately, due to your monthly income, we won’t be able to process your application at this time. Please note that we may request proof of your salary during the application process and at claims stage.",
    messageSection2:
      "Thank you for your interest and if you have any further questions regarding this, one of our friendly agents would be happy to take your call. You can contact us on 0860 111 737, weekdays from 8am to 5pm.",
  },
  {
    id: 4,
    image: Shield,
    dropOff: "citizenship",
    title: "We are unfortunately unable to process your application",
    messageSection1:
      "You need to be a South African citizen with a SA ID to apply for cover with us.",
    messageSection2:
      "Thank you for your interest and if you have any further questions regarding this, one of our friendly agents would be happy to take your call. You can contact us on 0860 111 737, weekdays from 8am to 5pm.",
  },
  {
    id: 5,
    image: Shield,
    dropOff: "idNumber",
    title: "It looks like you may have already completed this application",
    messageSection1: `Your ID number: {0}, has already been linked to an application for {1}.`,
    messageSection2:
      "One of our friendly agents will call you back on 083 929 2335, between our weekday business hours of 8am and 5pm to confirm your details.",
    messageSection3: `Not your ID number or the incorrect contact number?`,
    messageSection4: "Click here to edit your application.",
  },
  {
    id: 6,
    image: Shield,
    dropOff: "age",
    title: "We are unfortunately unable to process your application",
    messageSection1: `You unfortunately do not meet the age criteria to apply for {0} with us.`,
    messageSection2:
      "Thank you for your interest and if you have any further questions regarding this, one of our friendly agents would be happy to take your call. You can contact us on 0860 111 737, weekdays from 8am to 5pm.",
  },
  {
    id: 7,
    image: Shield,
    dropOff: "moreInfo",
    title: "We are going to need a little bit more information",
    messageSection1: `Thank you for considering Liberty for your {0} needs.`,
    messageSection2:
      "Based on your answers so far, we need to contact you to get more information in order to complete your application.",
    messageSection3:
      "One of our friendly agents will call you shortly on 083 929 2335, to take you through a more detailed sales process and to answer any questions you may have.",
    messageSection4:
      "Our business hours are weekdays from 8am to 5pm. Not the correct contact number?",
    messageSection5: "Click here to edit your application.",
  },
];

export const confirmationModal = {
  id: 1,
  image: Shield,
  title: "Add Beneficiary Confirmation",
  message:
    "You are about to proceed to the next step in your application, before you go do you want to add a beneficiary?",
  alternativeMessage:
    "Click Skip to proceed to next step or Confirm to add a beneficiary",
};

export const policyReferenceNumber = [
  {
    policyId: 1,
    referenceNumber: "42DX7",
    policyType: "Salary Protection Policy",
  },
];

export const vaccination = [
  {
    value: 0,
    label: "Unvaccinated",
  },
  {
    value: 1,
    label: "Partially vaccinated",
  },
  {
    value: 2,
    label: "Fully vaccinated ",
  },
  {
    value: 3,
    label: "Unknown / Not prepared to answer",
  },
];

export const personalContent = [
  {
    description: "FULL NAME",
    value: "Test Test",
  },
  {
    description: "GENDER",
    value: "Male",
  },
  {
    description: "D.O.B",
    value: "27 March 1989",
  },
  {
    description: "IDENTITY NUMBER",
    value: "8903275678094",
  },
  {
    description: "HEIGHT",
    value: "180 cm",
  },
  {
    description: "WEIGHT",
    value: "100 kg",
  },
  {
    description: "QUALIFICATION",
    value: "Professional",
  },
  {
    description: "GROSS MONTHLY INCOME",
    value: "R 34 567",
  },
  {
    description: "TAKE HOME PAY",
    value: "R 29 567",
  },
];

export const beneficiaryContent = [
  {
    description: "BENEFICIARY FIRSTNAME",
    value: "Janica",
  },
  {
    description: "BENEFICIARY LASTNAME",
    value: "Walton",
  },
  {
    description: "RELATIONSHIP TO CLIENT",
    value: "WIFE",
  },
  {
    description: "BENEFICIARY IDENTITY NUMBER",
    value: "8903275678094",
  },
];

export const contactContent = [
  {
    description: "CONTACT NUMBER",
    value: "061 458 5862",
  },
  {
    description: "ALTERNATIVE CONTACT NUMBER",
    value: "062 458 5342",
  },
  {
    description: "EMAIL ADDRESS",
    value: "test@mctesty.com",
  },
  {
    description: "PROVINCE",
    value: "Gauteng",
  },
];

export const lifeStyleContent = [
  {
    description: "SMOKER STATUS",
    value: "Non Smoker",
  },
  {
    description: "RISKY SPORTS OR ACTIVITIES",
    value: "None",
  },
  {
    description: "OCCUPATION",
    value: "Exotic dancer",
    
  },
];

export const additionalCover = [
  {
    title: "Life Cover",
    cover: 200000,
  },
  {
    title: "Serious Illness Cover",
    cover: 100000,
  },
  {
    title: "Disability Cover",
    cover: 200000,
  },
  {
    title: "Salary Protection",
    cover: 5000,
  },
];

export const banks = [
  { value: 0, label: "Absa" },
  { value: 1, label: "African Bank" },
  { value: 2, label: "Bank of Athens" },
  { value: 3, label: "Bidvest Bank" },
  { value: 4, label: "Capitec" },
  { value: 5, label: "First National Bank" },
  { value: 6, label: "Investec" },
  { value: 7, label: "Nedbank" },
  { value: 8, label: "SA Post Bank" },
  { value: 9, label: "Standard Bank" },
  { value: 10, label: "Tyme Bank" },
  { value: 11, label: "Discovery Bank" },
];

export const branchCodes = [
  {
    id: 0,
    bank: "Absa",
    branchCode: "632005",
    branch: "ABSA ELECTRONIC SETTLEMENT CNT",
  },
  {
    id: 1,
    bank: "African Bank",
    branchCode: "430000",
    branch: "HEAD OFFICE",
  },
  {
    id: 2,
    bank: "Bank of Athens",
    branchCode: "410506",
    branch: "HEAD OFFICE",
  },
  {
    id: 3,
    bank: "Bidvest Bank",
    branchCode: "462005",
    branch: "BIDVEST BANK",
  },
  {
    id: 4,
    bank: "Capitec",
    branchCode: "470010",
    branch: "CAPITEC BANK LIMITED",
  },
  {
    id: 5,
    bank: "First National Bank",
    branchCode: "250655",
    branch: "REMOTE BANKING SERVICE 560",
  },
  {
    id: 6,
    bank: "Investec",
    branchCode: "580105",
    branch: "INVESTEC BANK GRAYSTON DRIVE",
  },
  {
    id: 7,
    bank: "Nedbank",
    branchCode: "198765",
    branch: "NEDBANK SOUTH AFRICA",
  },
  {
    id: 8,
    bank: "SA Post Bank",
    branchCode: "460005",
    branch: "HEAD OFFICE",
  },
  {
    id: 9,
    bank: "Standard Bank",
    branchCode: "051001",
    branch: "STANDARD BANK SOUTH AFRICA",
  },
  {
    id: 10,
    bank: "Tyme Bank",
    branchCode: "678910",
    branch: "TYME ROSEBANK",
  },
  {
    id: 11,
    bank: "Discovery Bank",
    branchCode: " 67900000",
    branch: "DISCOVERY UNIVERSAL BRANCH",
  },
];

export const policyTableData = [
  {
    id: 1,
    product: "Salary Protection",
    benefit: Math.round(200000).toFixed(2),
    monthlyPremuim: Math.round(200).toFixed(2),
    lifeCover: "LIBERTYFFSP0006674106P02",
  },
];

export const isBankingAccount = [
  { id: "yes", title: "YES" },
  { id: "no", title: "NO" },
];

export const qualifiedAmount = [
  { value: 100000, label: "R 100 000" },
  { value: 200000, label: "R 200 000" },
  { value: 300000, label: "R 300 000" },
  { value: 400000, label: "R 400 000" },
  { value: 500000, label: "R 500 000" },
  { value: 600000, label: "R 600 000" },
  { value: 700000, label: "R 700 000" },
  { value: 800000, label: "R 800 000" },
  { value: 900000, label: "R 900 000" },
  { value: 1000000, label: "R 1 000 000" },
];

export const qualifiedPreApprovedAmount = [
  { value: 100000, label: "R 100 000" },
  { value: 200000, label: "R 200 000" },
  { value: 300000, label: "R 300 000" },
  { value: 400000, label: "R 400 000" },
  { value: 500000, label: "R 500 000" },
  { value: 600000, label: "R 600 000" },
  { value: 700000, label: "R 700 000" },
  { value: 800000, label: "R 800 000" },
  { value: 900000, label: "R 900 000" },
  { value: 1000000, label: "R 1 000 000" },
];

export const qualifiedPremuim = [
  { value: 100, label: "R 100" },
  { value: 200, label: "R 200" },
  { value: 300, label: "R 300" },
  { value: 400, label: "R 400" },
  { value: 500, label: "R 500" },
  { value: 600, label: "R 600" },
  { value: 700, label: "R 700" },
  { value: 800, label: "R 800" },
  { value: 900, label: "R 900" },
  { value: 1000, label: "R 1000" },
];
