import React from "react";
import { footerNavigation as navigation } from "../../models/DataModels";
import { footerPolicyNavigation } from "../../models/DataModels";

function Footer() {
  return (
    <footer
      id="footer"
      aria-labelledby="footer-heading"
      className="bg-gradient-to-r from-primary-color to-secondary-color shadow-lg"
    >
      <div className="justify-items-end px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-items-end mt-5 mb-5">
          <a href="/">
            <img
              className="hidden pr-2 w-auto lg:block"
              src={require("../../assets/images/logo_std.png")}
              alt="Your Company"
            />
          </a>

          <a href="/">
            <img
              className="hidden w-auto lg:block"
              src={require("../../assets/images/logo_name.png")}
              alt="Your Company"
            />
          </a>
        </div>
        <div className="flex flex-col items-start mt-5">
          <div className="grid grid-cols-6 gap-6 mb-12">
            <div className="col-span-6 sm:col-span-3">
              <div>
                <h2 className="font-normal border-b-2 text-white text-2xl">
                  Products
                </h2>
                <ul className="mt-6 space-y-6">
                  {navigation.products.map((item) => (
                    <li key={item.name} className="text-md font-normal">
                      <a
                        href={item.href}
                        className=" text-white hover:border-b-2"
                        target={item.target}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <div>
                <h2 className="font-normal border-b-2 text-white text-2xl">
                  Links
                </h2>
                <ul className="mt-6 space-y-6">
                  {navigation.links.map((item) => (
                    <li key={item.name} className="text-md font-normal">
                      <a
                        href={item.href}
                        target="_blank"
                        className=" text-white hover:border-b-2"
                      >
                        {item.name}
                      </a>
                    </li>

                  ))}
                  
                    <li className="text-md font-normal">
                      <a className="text-white hover:border-b-2 ot-sdk-show-settings" id="ot-sdk-btn" >Cookie Settings</a>
                    </li>
                    
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom-section bg-dark-blue ">
        <div className="flex flex-col items-center mt-5 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-10">
              <div className="pt-6 pb-5">
                {footerPolicyNavigation.disclaimer.map((dis) => (
                  <p
                    key={dis.policy}
                    className="text-sm font-light text-white items-center"
                  >
                    {dis.policy}
                  </p>
                ))}
              </div>
            </div>

            <div className="col-span-2">
              <div className="pt-5 pb-5">
                {footerPolicyNavigation.links.map((link) => (
                  <a
                    key={link.name}
                    href={link.href}
                    target="_blank"
                    className="inline-flex items-center font-light border-b-2 border-transparent px-1 pt-1 text-sm text-white hover:border-white hover:text-white"
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
