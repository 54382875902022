import React, { useState, useEffect } from "react";
import { idType, relationship } from "../models/DataModels";
import Stepper from "../components/multi-form-stepper/Stepper";
import { steps } from "../models/DataModels";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { MainContext } from "../App";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useNavigate } from "react-router";
import { TrashIcon } from "@heroicons/react/20/solid";

export default function AddBenificiary() {
  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;

  const [hasBeneficiaries, setHasBeneficiaries] = useState(false);
  const [data, setData] = useState({});
  const {} = data;
  const [beneficiaries, setBeneficiaries] = useState([
    {
      created_at: "",
      customer_id: 0,
      deleted: false,
      first_name: "",
      id: 0,
      id_number: "",
      last_name: "",
      passport_number: "",
      relationship_to_client: "",
      updated_at: "",
    },
  ]);

  useEffect(() => {
    const fetchCustomer = async () => {
      const id = JSON.parse(localStorage.getItem("id"));

      var config = {
        method: "get",
        url: `${API_ENDPOINT}buyonline/customer/by-id-number/${id}`,
        headers: {
          Authorization: `${AUTH_KEY}`,
        },
      };

      return await axios(config)
        .then((response) => {
          if (response) {
            if (response.data.success === false) {
              toast.dismiss()
              toast.warn(`Failed to fetch beneficiary`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              return Promise.reject(
                console.log("Fetch beneficiary", JSON.stringify(response.data))
              );
            } else {
              setBeneficiaries(response.data.beneficiaries);
              setData(response.data);
              if (response.data.beneficiaries.length > 0)
                setHasBeneficiaries(true);
            }
          }
        })
        .catch((error) => {
          toast.dismiss()
          toast.error(`Failed to fetch beneficiary`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("Fetch beneficiary", JSON.stringify(error));
          return;
        });
    };
    fetchCustomer();
  }, []);

  const calculateClaimPercentage = (numberOfBeneficiaries) => {
    let controlValue = 100;
    let numberOfBens = parseFloat(numberOfBeneficiaries);
    let percentage = (controlValue / numberOfBens).toFixed();
    return percentage;
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isIdValid, setIsIdValid] = useState(true);
  const [isPassportValid, setIsPassportValid] = useState(true);
  const [retationshipStatus, setRelationshipStatus] = useState(null);
  const [idChecked, setIdChecked] = useState(false);
  const [passportChecked, setPassportChecked] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [customerId, setCustomerId] = useState(0);
  const [beneficiary, setBeneficiary] = useState({
    first_name: "",
    last_name: "",
    id_number: "",
    passport_number: "",
    relationship_to_client: "",
  });

  const {
    first_name,
    last_name,
    id_number,
    passport_number,
    relationship_to_client,
  } = beneficiary;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "2px",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#3C4B6C",
    }),
    control: (styles, state) => ({
      ...styles,
      borderColor: "#858D9D",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #0033a1",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    const validate = async () => {
      if ((await cookies.productSlug) === undefined) {
        sessionStorage.setItem("invalidPath", true);
        window.location = "/";
      }
    };
    validate();
  });

  const onSubmit = async (e, contextUser) => {
    e.preventDefault();

    let formIsEmpty = isFormEmpty(false);
    if (formIsEmpty && formIsEmpty !== undefined) {
        toast.error("Please fill in all the fields before clicking on save!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      return;
    }

    let isValid = isFormValid();
    if (!isValid && isValid !== undefined) return;

    let requestData = JSON.stringify(beneficiary);
    var config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/customer/add-beneficiary/${customerId}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            toast.dismiss()
            toast.warn(`Failed to add beneficiary`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log("Submit beneficiary", JSON.stringify(response.data))
            );
          } else {
            setBeneficiaries(response.data.beneficiaries);
            setHasBeneficiaries(true);
            setData(response.data);

            let stored_beneficiaries = localStorage.getItem('beneficiaries')
            localStorage.setItem('beneficiaries', JSON.stringify(response.data.beneficiaries))
            
            toast.dismiss()
            setTimeout(() => {
              toast.success("Beneficiary added successfully!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              return;
            }, 500);
          }
        }
      })
      .catch((error) => {
        toast.dismiss()
        toast.error(`Failed to add beneficiary`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Submit beneficiary", JSON.stringify(error));

        return;
      });
  };

  function isValidName(name) {
    return /[a-zA-Z]$/gm.test(name);
  }

  function isValidNumber(idNumber) {
    return /^[0-9\b]+$/.test(idNumber);
  }

  function isValidPassport(passportNumber) {
    return /[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/gm.test(passportNumber);
  }

  const isFormValid = () => {
    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      (idChecked && !isIdValid) ||
      (passportChecked && !isPassportValid) ||
      relationship === null
    ) {
      toast.error("Please fix validation errors !", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const isFormEmpty = (cont=false) => {
    if (
      first_name === "" ||
      last_name === "" ||
      relationship_to_client === "" ||
      (idChecked && id_number === "") ||
      (passportChecked && passport_number === "") ||
      relationship_to_client === "" ||
      (id_number === "" && passport_number === "")
    ) {
      toast.dismiss()
      if (cont = false) {
        toast.error("Please fill in all the fields before clicking on save!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      
      return true;
    }
  };

  const onClick = (e, context) => {
    toast.dismiss()

    let isEmpty = isFormEmpty(true);
    let isValid = isFormValid();

    if (isEmpty === true) {
      navigate("/quote");
    } else if (isValid === true) {
      onSubmit(e, context);
      navigate("/quote");
    } 
  };

  const onAddBeneficiary = () => {
    return;
  };

  const onRemoveBeneficiary = (itemToRemove) => async () => {
    var config = {
      method: "delete",
      url: `${API_ENDPOINT}buyonline/customer/remove-beneficiary/${itemToRemove.customer_id}/${itemToRemove.id}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
      },
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            toast.warn(`Failed to delete beneficiary`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            return Promise.reject(
              console.log("Remove beneficiary", JSON.stringify(response.data))
            );
          } else {
            setBeneficiaries(response.data.beneficiaries);
            
            let stored_beneficiaries = localStorage.getItem('beneficiaries')
            localStorage.setItem('beneficiaries', JSON.stringify(response.data.beneficiaries))
            
            if (response.data.beneficiaries.length === 0) {
              setHasBeneficiaries(false);
            }
            setData(response.data);
            setTimeout(() => {
              showNotification();
              return;
            }, 500);
          }
        }
      })
      .catch((error) => {
        toast.error(`Failed to delete beneficiary`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Remove beneficiary", JSON.stringify(error));

        return;
      });
  };

  const onChange = (event) => {
    if (event.target.name === "first_name") {
      if (!isValidName(event.target.value)) {
        setIsFirstNameValid(false);
      } else {
        setIsFirstNameValid(true);
      }

      if (event.target.value === "") {
        setIsFirstNameValid(true);
      }
    }

    if (event.target.name === "last_name") {
      if (!isValidName(event.target.value)) {
        setIsLastNameValid(false);
      } else {
        setIsLastNameValid(true);
      }

      if (event.target.value === "") {
        setIsLastNameValid(true);
      }
    }

    if (event.target.name === "id_number") {
      if (!isValidNumber(event.target.value)) {
        setIsIdValid(false);
      } else {
        setIsIdValid(true);
        let isValid = IdNumberValidation(event.target.value);
        if (!isValid) {
          setIsIdValid(false);
        }
      }

      if (event.target.value === "") {
        setIsIdValid(true);
      }
    }

    if (event.target.name === "passport_number") {
      if (!isValidPassport(event.target.value)) {
        setIsPassportValid(false);
      } else {
        setIsPassportValid(true);
      }

      if (event.target.value.length < 8 || event.target.value.length > 8) {
        setIsPassportValid(false);
      } else {
        setIsPassportValid(true);
      }

      if (event.target.value === "") {
        setIsPassportValid(true);
      }
    }

    let customerId = JSON.parse(localStorage.getItem("custId"));
    setCustomerId(customerId);

    setBeneficiary({ ...beneficiary, [event.target.name]: event.target.value });
  };

  function IdNumberValidation(id_number) {
    const generate_luhn_digit = (id_number) => {
      let total = 0;
      let count = 0;
      let i = 0;
      while (i < id_number.length) {
        let multiple = (count % 2) + 1;
        count = count + 1;
        let temp = multiple * parseInt(id_number[i]);
        temp = parseInt(Math.floor(temp / 10) + (temp % 10));
        total = total + temp;
        i = i + 1;
      }
      total = (total * 9) % 10;
      return total;
    };

    const validate = (id_number) => {
      let isnum = /^\d+$/.test(id_number);
      try {
        if (!isnum || id_number.length !== 13) {
          return false;
        }
        let last_digit = parseInt(
          id_number.substr(id_number.length - 1, id_number.length)
        );
        let number_section = id_number.substr(0, id_number.length - 1);
        return last_digit === generate_luhn_digit(number_section);
      } catch (error) {
        return false;
      }
    };

    let tempDate = new Date(
      id_number.substring(0, 2),
      id_number.substring(2, 4) - 1,
      id_number.substring(4, 6)
    );
    let id_year = tempDate.getFullYear();
    let currentYear = new Date().getFullYear();
    let age = currentYear - id_year;

    let genderCode = id_number.substring(6, 10);
    var gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

    var isCitizen = parseInt(id_number.substring(10, 11)) === 0 ? true : false;

    return id_number !== "0000000000000" && validate(id_number) && isCitizen;
  }

  function onRelationshipChange(selectedValue) {
    if (selectedValue !== null) {
      setRelationshipStatus(selectedValue);
      setBeneficiary({
        ...beneficiary,
        relationship_to_client: selectedValue.value,
      });
    } else {
      setRelationshipStatus(null);
    }
  }

  function checkValue(e) {
    if (e.target.id === "idNumber") {
      let isId = !idChecked;
      setIdChecked(isId);
      setPassportChecked(false);
      setBeneficiary({ ...beneficiary, passport_number: "" });

      if (isId === false) {
        setBeneficiary({ ...beneficiary, id_number: "" });
        setIsIdValid(true);
      }
    }

    if (e.target.id === "passportNumber") {
      let isPassport = !passportChecked;
      setPassportChecked(isPassport);
      setIdChecked(false);
      setBeneficiary({ ...beneficiary, id_number: "" });

      if (isPassport === false) {
        setBeneficiary({ ...beneficiary, passport_number: "" });
        setIsPassportValid(true);
      }
    }
  }

  function showNotification() {
    toast.success("Beneficiary has been deleted!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return (
    <MainContext.Consumer>
      {(value) => (
        <div className="relative bg-light-white px-4 pt-20 pb-20 sm:px-6 lg:px-8 lg:pb-28 lg:pt-28">
          <div className="relative mx-auto lg:pl-36 lg:pr-36">
            <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-1">
              <div className="flex flex-col rounded-lg shadow-lg">
                <div className="flex flex-col bg-white xl:p-6 p-6">
                  <Stepper steps={steps} currentStep={1} />
                </div>

                <hr className="line-break pb-5 w-full"></hr>

                <div className="grid grid-cols-6 gap-6 bg-white">
                  <div className="col-span-6 sm:col-span-4">
                    <div className="flex flex-1 flex-col justify-between bg-white p-6">
                      <form
                        onSubmit={(event) => onSubmit(event, value)}
                        id="add-beneficiary-form"
                      >
                        <div>
                          <h3 className="text-3xl font-normal leading-6 text-gray pb-5">
                            Add beneficiary Detail
                          </h3>

                          <p className="mt-3 text-sm text-gray font-normal pb-5">
                            If you do not have beneficiaries on record for your product and you die, we will pay out to your deceased estate. Contact us as soon as possible to provide details of your beneficiaries or to notify us of any changes.

                            <ul style={{ paddingTop: '30px', listStyle: 'revert', marginLeft: '30px' }}>
                              <li style={{ paddingBottom: "10px" }}>Add an unlimited number of beneficiaries</li>
                              <li style={{ paddingBottom: "20px" }}>Change or add beneficiaries at any time</li>
                            </ul>

                          </p>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first_name"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span>NOMINATED BENEFICIARY’S FIRST NAME</span>
                            </label>
                            <div
                              className={
                                isFirstNameValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                placeholder="Nominated beneficiary’s first name"
                                type="text"
                                name="first_name"
                                id="first_name"
                                autoComplete="first_name"
                                value={first_name}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isFirstNameValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID BENEFICIARY FIRSTNAME
                            </p>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last_name"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span>NOMINATED BENEFICIARY’S LAST NAME</span>
                            </label>
                            <div
                              className={
                                isLastNameValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                placeholder="Nominated beneficiary’s last name"
                                type="text"
                                name="last_name"
                                id="last_name"
                                autoComplete="last_name"
                                value={last_name}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isLastNameValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID BENEFICIARY LASTNAME
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6 pb-2">
                          <div className="col-span-6 sm:col-span-3">
                            <label className="text-sm font-normal text-gray-500">
                              <span>FORM OF IDENTIFICATION</span>
                            </label>
                            <fieldset className="mt-2">
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                {idType.map((idType, idTypeIdx) => (
                                  <div
                                    key={idType.id}
                                    className="flex items-center"
                                  >
                                    {idTypeIdx === 0 ? (
                                      <input
                                        id={idType.id}
                                        name={idType.title}
                                        value={idChecked}
                                        type="checkbox"
                                        checked={idChecked}
                                        onChange={checkValue}
                                        className="h-6 w-6 border-light-blue text-light-blue focus:ring-light-blue checked:bg-light-blue rounded-full"
                                      />
                                    ) : (
                                      <input
                                        id={idType.id}
                                        name={idType.title}
                                        value={passportChecked}
                                        type="checkbox"
                                        checked={passportChecked}
                                        onChange={checkValue}
                                        className="h-6 w-6 border-light-blue text-light-blue focus:ring-light-blue checked:bg-light-blue rounded-full"
                                      />
                                    )}

                                    <label
                                      htmlFor={idType.id}
                                      className="ml-3 block text-sm font-normal text-gray-500"
                                    >
                                      {idType.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          {idChecked === true ? (
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="id_number"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span>NOMINATED BENEFICIARY’S ID NUMBER</span>
                              </label>
                              <div
                                className={
                                  isIdValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <input
                                  maxLength={13}
                                  placeholder="Nominated beneficiary’s ID number"
                                  type="text"
                                  name="id_number"
                                  id="id_number"
                                  autoComplete="id_number"
                                  value={id_number}
                                  onChange={onChange}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>
                              <p
                                className={
                                  !isIdValid
                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                    : "hidden"
                                }
                              >
                                INVALID BENEFICIARY ID NUMBER
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {passportChecked === true ? (
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="passport_number"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span>NOMINATED BENEFICIARY’S PASSPORT</span>
                              </label>
                              <div
                                className={
                                  isPassportValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                <input
                                  maxLength={8}
                                  placeholder="Nominated beneficiary’s passport"
                                  type="text"
                                  name="passport_number"
                                  id="passport_number"
                                  autoComplete="passport_number"
                                  value={passport_number}
                                  onChange={onChange}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                />
                              </div>
                              <p
                                className={
                                  !isPassportValid
                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                    : "hidden"
                                }
                              >
                                INVALID BENEFICIARY PASSPORT
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="retationship"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span>RELATIONSHIP TO CLIENT</span>
                            </label>
                            <div className="text-gray pb-2">
                              <Select
                                className="text-sm font-light text-gray"
                                classNamePrefix="retationship"
                                placeholder={"Relationship to client"}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                styles={customStyles}
                                onChange={(value) =>
                                  onRelationshipChange(value)
                                }
                                value={retationshipStatus}
                                options={relationship}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex pt-5 xl:pt-10">
                          <div className="flex flex-1 flex-col items-center xl:w-full xl:flex xl:flex-row xl:justify-start">
                            <button
                              className="bg-btn-primary-color mb-2 xl:mb-0 xl:mr-2 font-bold xl:w-100 w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                              type="submit"
                              onClick={onAddBeneficiary}
                            >
                              <span>SAVE AND ADD ANOTHER</span>
                            </button>

                            <button
                              className="xl:ml-2 mt-2 xl:mt-0 font-bold xl:w-100 w-64 py-2 px-4 sm:py-2 sm:px-4 bg-btn-secondary-color text-light-blue shadow-sm border-light-blue hover:bg-btn-primary-color hover:text-white hover: border-solid border-2"
                              type="button"
                              onClick={(event) => onClick(event, value)}
                            >
                              <span>SAVE AND CONTINUE</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    <div className="flex flex-1 flex-col justify-between bg-white pt-1 pb-6 px-6 xl:p-6 sticky top-0">

                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-6 bg-white">
                  <div className="col-span-6 sm:col-span-6">
                    <>
                      {hasBeneficiaries === false ? (
                        ""
                      ) : (
                        <div className="xl:px-4 xl:pb-10 px-5 pb-10">
                          <div className="sm:flex sm:items-center"></div>
                          <div className="mt-8 overflow-scroll shadow-lg ring-1 ring-blue-200 ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                            <table className="min-w-full divide-y divide-blue-200 table-auto">
                              <thead className="bg-primary-color">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white table-cell lg:table-cell sm:table-cell"
                                  >
                                    FIRST NAME
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white table-cell lg:table-cell sm:table-cell"
                                  >
                                    LAST NAME
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white table-cell lg:table-cell sm:table-cell"
                                  >
                                    ID NUMBER
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white table-cell lg:table-cell sm:table-cell"
                                  >
                                    RELATIONSHIP TO CLIENT
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white table-cell lg:table-cell sm:table-cell"
                                  >
                                    CLAIM PERCENTAGE
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white table-cell lg:table-cell sm:table-cell"
                                  ></th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-blue-200 bg-white">
                                {beneficiaries?.map((person) => (
                                  <tr key={person.id}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-500 lg:table-cell sm:table-cell">
                                      {person.first_name.toUpperCase()}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-500 lg:table-cell sm:table-cell">
                                      {person.last_name.toUpperCase()}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3text-sm font-normal text-gray-500 lg:table-cell sm:table-cell">
                                      {person.id_number === ""
                                        ? person.passport_number
                                        : person.id_number}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-500 lg:table-cell sm:table-cell">
                                      {person.relationship_to_client.toUpperCase()}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-500 lg:table-cell sm:table-cell">
                                      {calculateClaimPercentage(
                                        data.beneficiaries.length
                                      )}{" "}
                                      %
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light lg:table-cell sm:table-cell">
                                      <TrashIcon
                                        aria-hidden="true"
                                        onClick={onRemoveBeneficiary(person)}
                                        className="fill-blue w-8 h-8 xl:w-8 xl:h-8 sm:w-3 sm:h-3 cursor-pointer"
                                      ></TrashIcon>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainContext.Consumer>
  );
}
