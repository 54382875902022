import React from "react";
import { Link } from "react-router-dom";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

const ConfirmationModal = ({
  image,
  title,
  message,
  alternativeMessage,
  show,
  closeModal,
}) => {
  const cancelButtonRef = useRef(null);
  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-primary-color bg-opacity-75 transition-opacity backdrop-blur-sm" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-md sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-16 w-20 flex-shrink-0 items-center justify-center rounded-full bg-white sm:mx-0 sm:h-10 sm:w-10">
                      <img
                        className="h-16 w-20 shield-img"
                        src={image}
                        alt="shield"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-gray">
                      <Dialog.Title
                        as="h3"
                        className="font-normal text-2xl leading-6 text-gray"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="pt-5 text-left">
                        <p className="text-sm text-gray font-normal">
                          {message}
                        </p>
                      </div>

                      <div className="pt-5 text-left">
                        <p className="text-sm text-gray font-normal">
                          {alternativeMessage}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5 text-center">
                    <div className="flex flex-1 flex-col items-center xl:flex xl:flex-row xl:justify-between xl:w-full">
                      <Link to={"/quote"}>
                        <button
                          className="w-100 xl:w-100 mb-2 xl:mb-0 py-2 px-4 bg-btn-secondary-color text-light-blue shadow-sm border-light-blue hover:bg-btn-primary-color hover:text-white hover: border-solid border-2 font-bold"
                          type="submit"
                        >
                          <span>SKIP</span>
                        </button>
                      </Link>
                      <Link to={"/add-beneficiary"}>
                        <button
                          className="bg-btn-primary-color font-bold mt-2 xl:mt-0 w-100 xl:w-100 py-2 px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                          type="submit"
                        >
                          <span>CONFIRM</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ConfirmationModal;
