import React, { useState } from "react";
import slugify from "slugify";
import { Link } from "react-router-dom";
import { Button } from "../components/button/Button";
import { posts } from "../models/DataModels";
import { useCookies } from "react-cookie";

export default function Home() {
  const [data, setData] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies();
  const setProduct = (product) => {
    setData(product);
    localStorage.setItem("productKey", JSON.stringify(product));
    setCookie("productSlug", slugify(product));
  };

  return (
    <div className="relative bg-light-white px-4 pt-20 pb-20 sm:px-6 lg:px-12 lg:pt-28 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3 bg-light-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto">
        <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-4 bg-light-white">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={post.imageUrl}
                  alt=""
                />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <div className="mt-2 block">
                    <p className="text-gray font-normal text-3xl mb-5">
                      {post.title}
                    </p>
                    <p className="mt-3 text-sm font-normal text-gray mb-5">
                      {post.description}
                    </p>
                    <p className="mt-3 text-base">
                      <a
                        href={post.tellMeMoreHref}
                        className="border-b-2 border-transparent hover:border-light-blue text-light-blue"
                      >
                        {post.tellMeMore}
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <hr className="line-break"></hr>

              <div className="relative px-5 bg-white">
                <div className="mx-auto mt-12 grid gap-5 lg:max-w-none lg:grid-cols-1 bg-white">
                  <div className="items-center">
                    <div className="flex flex-1 flex-col justify-between bg-white">
                      <Link
                        to={post.getQuote}
                        onClick={() => setProduct(post.title)}
                      >
                        <Button
                          type="submit"
                          variant="outline"
                          color="white"
                          className="btn w-100 font-bold"
                        >
                          <span>GET QUOTE</span>
                        </Button>
                      </Link>
                    </div>
                  </div>

                  <div className="items-center pb-5">
                    <div className="flex flex-1 flex-col justify-between">
                      <Link
                        to={post.buyNow}
                        onClick={() => setProduct(post.title)}
                      >
                        <Button
                          type="submit"
                          variant="solid"
                          color="slate"
                          className="btn w-100 font-bold"
                        >
                          <span>BUY NOW</span>
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
