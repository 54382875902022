import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  educationTypes,
  medicalConsent,
  provinceTypes,
  smokedOptions,
  tobaccoTypes,
  titleTypes,
  languageTypes,
  steps,
  maritalTypes,
  products,
  confirmationModal,
} from "../models/DataModels";
import ReactTooltip from "react-tooltip";
import PersonalInformation from "../components/content/PersonalInformation";
import Stepper from "../components/multi-form-stepper/Stepper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import ApplicationModal from "../components/modals/ApplicationModal";
import { applicationModal } from "../models/DataModels";
import { MainContext } from "../App";
import { useCookies } from "react-cookie";
import AsyncSelect from "react-select/async";
import axios from "axios";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import saIdParser from "south-african-id-parser";

export default class PersonalDetails extends React.Component {
  render() {
    return <Application />;
  }
}

function Application() {
  const navigate = useNavigate();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
  const [tooltip, showTooltip] = useState(true);
  const [data, setData] = useState([]);
  const setCustId = (id) => {
    setData(id);
    localStorage.setItem("custId", JSON.stringify(id));
  };
  const [dropOffModel, setDropOffModel] = useState({
    id: 0,
    image: "",
    dropOff: "",
    title: "",
    messageSection1: "",
    messageSection2: "",
    messageSection3: "",
    messageSection4: "",
    messageSection5: "",
  });
  const [currentStep, setCurrentStep] = React.useState(1);
  const [titleType, setTitleType] = useState(null);
  const [languageType, setLanguageType] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isTobaccoUnitsValid, setIsTobaccoUnitsValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isAltNumberValid, setIsAltNumberValid] = useState(true);
  const [isIdNumberValid, setIsIdNumberValid] = useState(true);
  const [isIncomeValid, setIsIncomeValid] = useState(true);
  const [isTakeHomeValid, setIsTakeHomeValid] = useState(true);
  const [maritalType, setMaritalType] = useState(null);
  const [provinceType, setProvinceType] = useState(null);
  const [occupationType, setOccupationType] = useState(null);
  const [educationType, setEducationType] = useState(null);
  const [smokeChecked, setSmokeChecked] = useState(false);
  const [nonSmokeChecked, setNonSmokeChecked] = useState(false);
  const [yesConsentChecked, setYesConsent] = useState(false);
  const [noConsentChecked, setNoConsent] = useState(false);
  const [tobaccoType, setTobaccoType] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tobaccoUnits, setTobaccoUnits] = useState(null);
  const [dropOffId, setDropOffId] = useState(1);
  const [occupationInputId, setOccupationInputId] = useState(null);
  const [userDetails, setUserDetails] = useState({
    product: "",
    title: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    alternative_contact_number: "",
    email_address: "",
    id_number: "",
    binary_data: null,
    income: 0,
    take_home_pay: 0,
    qualification: "",
    occupation: "",
    smoker: false,
    tobacco_type: "",
    tobacco_units: 0,
    drinker: false,
    alcohol_units: 0,
    province: "",
    citizenship: "",
    home_language: "",
    marital_status: "",
    height: 0,
    weight: 0,
    measurments_captured: 0,
    liberty_direct_marketing: false,
    liberty_group_marketing: false,
    partner_marketing: false,
    research_marketing: false,
    beneficiaries: [],
  });

  const {
    title,
    first_name,
    last_name,
    contact_number,
    alternative_contact_number,
    email_address,
    id_number,
    income,
    take_home_pay,
    occupation,
    qualification,
    smoker,
    tobacco_type,
    tobacco_units,
    province,
    home_language,
    marital_status,
  } = userDetails;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "2px",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#3C4B6C",
    }),
    control: (styles, state) => ({
      ...styles,
      borderColor: "#858D9D",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #0033a1",
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    const validate = async () => {
      let selectedProduct = "";
      const productKey = JSON.parse(localStorage.getItem("productKey"));
      if (productKey) {
        if (productKey === "Serious Illness") {
          let productToFind = "Serious Illness Cover";
          selectedProduct = products.find((x) => x.id === productToFind).value;
          setUserDetails({ ...userDetails, product: selectedProduct });
        } else {
          selectedProduct = products.find((x) => x.id === productKey).value;
          setUserDetails({ ...userDetails, product: selectedProduct });
        }
      }

      if ((await cookies.productSlug) === undefined) {
        sessionStorage.setItem("invalidPath", true);
        window.location = "/";
      }
    };
    validate();


    setQuickQuoteCustomerDetails();
  }, []);

  const setQuickQuoteCustomerDetails = async () => {
    let quickQuoteModel = JSON.parse(localStorage.getItem("quickQuoteData"));

    if (quickQuoteModel !== undefined && quickQuoteModel !== null) {
      setUserDetails({
        ...userDetails,
        product: quickQuoteModel.product,
        first_name: quickQuoteModel.first_name,
        last_name: quickQuoteModel.last_name,
        contact_number: quickQuoteModel.contact_number,
        alternative_contact_number: quickQuoteModel.alternative_contact_number,
        email_address: quickQuoteModel.email_address,
        id_number: quickQuoteModel.id_number,
        income: quickQuoteModel.income,
      });
    } else {
      return;
    }
  };

  function setErrorDropOff() {
    setDropOffId(7);
    const product = JSON.parse(localStorage.getItem("productKey"));
    if (product !== null && product !== undefined) {
      let dropOff = applicationModal.find((x) => x.id === 7);
      dropOff.messageSection1 = dropOff.messageSection1.replace(`{0}`, product);
      setDropOffModel(dropOff);
      setShow(true);
    } else {
      setShow(false);
    }
  }

  function isValidEmail(email) {
    return /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      email
    );
  }

  function isValidName(name) {
    return /[a-zA-Z]$/gm.test(name);
  }

  function isValidNumber(contactNumber) {
    return /^[0-9]+$/.test(contactNumber);
  }

  function isValidAmount(amount) {
    return /^[0-9\b]+$/.test(amount);
  }

  const onSubmit = async (e, contextUser) => {
    e.preventDefault();

    let result = validateEmptyOnSubmit();
    if (result === false) {
      toast.error("Please fill in all the required fields marked with (*)", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let isValid = validFormOnSubmit();
    if (isValid === false) {
      toast.error("Please fix all validation errors", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let payResult = checkTakeHomePay();
    if (payResult) {
      toast.error("Your take home pay can't be more or equal to your income", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const productKey = JSON.parse(localStorage.getItem("productKey"));
    await contextUser.setContextUser(userDetails);
    let requestData = JSON.stringify(userDetails);

    let config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/customer`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            toast.warn(`Failed to add customer`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrorDropOff();
            return Promise.reject(
              console.log("Customer Submit", JSON.stringify(response.data))
            );
          } else {
            setCustId(response.data?.pk);
            toast.success("Customer added successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              if (productKey === "Life Cover") {
                navigate("/add-beneficiary");
              } else {
                setShowConfirmation(true);
              }
            }, 500);
          }
        }
      })
      .catch((error) => {
        toast.error(`Failed to add customer`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Customer Submit", JSON.stringify(error));
        setErrorDropOff();
        return;
      });
  };

  function onChangeTitleType(selectedValue) {
    if (selectedValue !== null) {
      setTitleType(selectedValue);
      setUserDetails({ ...userDetails, title: selectedValue.value });
    } else {
      setTitleType(null);
    }
  }

  function onChangeLanguageType(selectedValue) {
    if (selectedValue !== null) {
      setLanguageType(selectedValue);
      setUserDetails({ ...userDetails, home_language: selectedValue.value });
    } else {
      setLanguageType(null);
    }
  }

  function onChangeMaritalType(selectedValue) {
    if (selectedValue !== null) {
      setMaritalType(selectedValue);
      setUserDetails({ ...userDetails, marital_status: selectedValue.value });
    } else {
      setMaritalType(null);
    }
  }

  function onChangeProvince(selectedValue) {
    if (selectedValue !== null) {
      setProvinceType(selectedValue);
      setUserDetails({ ...userDetails, province: selectedValue.value });
    } else {
      setProvinceType(null);
    }
  }

  function onChangeEducation(selectedValue) {
    if (selectedValue !== null) {
      setEducationType(selectedValue);
      setUserDetails({ ...userDetails, qualification: selectedValue.value });
    } else {
      setEducationType(null);
    }
  }

  function onTobaccoChange(selectedValue) {
    if (selectedValue !== null) {
      setTobaccoType(selectedValue);
      setUserDetails({ ...userDetails, tobacco_type: selectedValue.value });
    } else {
      setTobaccoType(null);
    }
  }

  const onSearchOccupationType = (search) => {
    search !== "" ? setSearchTerm(search) : setSearchTerm("");
  };

  const fetchOccupationData = async () => {
    var config = {
      method: "get",
      url: `${API_ENDPOINT}buyonline/search-occupation/${searchTerm}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
      },
    };

    if (searchTerm !== "") {
      return await axios(config)
        .then((response) => {
          if (response) {
            if (response.data.success === false) {
              toast.warn(`Failed to fetch occupation`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              return Promise.reject(
                console.log("Occupation fetch", JSON.stringify(response.data))
              );
            } else {
              let options = response.data.map((item, index) => ({
                value: index,
                label: item,
              }));
              return options;
            }
          }
        })
        .catch((error) => {
          toast.error(`Failed to fetch occupation`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("Occupation fetch", JSON.stringify(error));
          return;
        });
    } else {
      return;
    }
  };

  function onChangeOccupation(selectedValue) {
    if (selectedValue !== null) {
      setOccupationType(selectedValue);
      setUserDetails({
        ...userDetails,
        occupation: selectedValue.label,
      });
    } else {
      setOccupationType(null);
    }
  }

  function IdNumberValidation(id_number) {
    let idParse = saIdParser.parse(id_number);

    if (idParse.isValid === true) {
      let dob = moment(idParse.dateOfBirth).format("MM/DD/YYYY");
      localStorage.setItem("dob", JSON.stringify(dob));
    }

    // idParse.dateOfBirth
    // idParse.isFemale
    // idParse.isMale
    // idParse.isSouthAfricanCitizen
    // idParse.isValid
    return idParse.isValid;
  }

  const checkIfIdExists = async (customer_id, product) => {
    setDropOffId(5);
    var config = {
      method: "get",
      url: `${API_ENDPOINT}buyonline/quotes/accepted/by-customer-id/${customer_id}`,
      headers: {
        Authorization: `${AUTH_KEY}`,
      },
    };

    return await axios(config, product)
      .then((response) => {
        if (response.data.success === false) {
          toast.warn(`Failed to fetch accepted quotes by customer`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          return Promise.reject(
            console.log("Fetch accepted quotes", JSON.stringify(response.data))
          );
        } else {
          let existingQuote = false;
          response.data.forEach((list) => {
            let hasProduct = list.products.find(
              (prod) => prod.product__name === product
            );
            if (hasProduct) {
              existingQuote = true;
            }
          });

          if (existingQuote) {
            let dropOff = applicationModal.find((x) => x.id === 5);
            dropOff.messageSection1 = dropOff.messageSection1.replace(
              `{0}`,
              customer_id
            );
            dropOff.messageSection1 = dropOff.messageSection1.replace(
              `{1}`,
              product
            );
            setDropOffModel(dropOff);
            return true;
          }
          return false;
        }
      })
      .catch((error) => {
        toast.error(`Failed to fetch accepted quotes`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("Accepted quotes fetch", JSON.stringify(error));

        return true;
      });
  };

  const checkIdDropOff = async (event) => {
    return;
  };

  const checkAgeDropOff = async (customerAge, product) => {
    setDropOffId(6);

    if (customerAge < 18 || customerAge > 65) {
      let dropOff = applicationModal.find((x) => x.id === 6);
      dropOff.messageSection1 = dropOff.messageSection1.replace(`{0}`, product);
      setDropOffModel(dropOff);
      return false;
    } else {
      return true;
    }
  };

  const checkCitizenDropOff = async (customerIsCitizen) => {
    setDropOffId(4);

    if (!customerIsCitizen) {
      let dropOff = applicationModal.find((x) => x.id === 4);
      setDropOffModel(dropOff);
      return false;
    } else {
      return true;
    }
  };

  const checkTakeHomePay = () => {
    let takeHomePay = Number(take_home_pay);
    let grossIncome = Number(income);
    let isEqual = grossIncome > takeHomePay;
    if (isEqual) {
      return false;
    } else {
      return true;
    }
  };

  const onChange = (event) => {
    if (event.target.name === "first_name") {
      if (!isValidName(event.target.value)) {
        setIsNameValid(false);
      } else {
        setIsNameValid(true);
      }

      if (event.target.value === "") {
        setIsNameValid(true);
      }
    }

    if (event.target.name === "last_name") {
      if (!isValidName(event.target.value)) {
        setIsLastNameValid(false);
      } else {
        setIsLastNameValid(true);
      }

      if (event.target.value === "") {
        setIsLastNameValid(true);
      }
    }

    if (event.target.name === "email_address") {
      if (!isValidEmail(event.target.value)) {
        setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }

      if (event.target.value === "") {
        setIsEmailValid(true);
      }
    }

    if (event.target.name === "contact_number") {
      if (!isValidNumber(event.target.value)) {
        setIsNumberValid(false);
      } else {
        setIsNumberValid(true);
      }

      if (event.target.value === "0000000000") setIsNumberValid(false);

      if (event.target.value === "") {
        setIsNumberValid(true);
      }
    }

    if (event.target.name === "alternative_contact_number") {
      if (!isValidNumber(event.target.value)) {
        setIsAltNumberValid(false);
      } else {
        setIsAltNumberValid(true);
      }

      if (event.target.value === "0000000000") setIsAltNumberValid(false);

      if (event.target.value === "") {
        setIsAltNumberValid(true);
      }
    }

    if (event.target.name === "id_number") {
      setIsIdNumberValid(false);
      if (
        isValidNumber(event.target.value) &&
        IdNumberValidation(event.target.value)
      ) {
        setIsIdNumberValid(true);
        localStorage.setItem("id", JSON.stringify(event.target.value));
      }
    }

    if (event.target.name === "income") {
      if (!isValidAmount(event.target.value)) {
        setIsIncomeValid(false);
      } else {
        setIsIncomeValid(true);
      }

      if (event.target.value <= 0 || event.target.value === "") {
        setIsIncomeValid(false);
      }
    }

    if (event.target.name === "take_home_pay") {
      if (!isValidAmount(event.target.value)) {
        setIsTakeHomeValid(false);
      } else {
        setIsTakeHomeValid(true);
      }

      if (event.target.value <= 0 || event.target.value === "") {
        setIsTakeHomeValid(false);
      }
    }

    if (event.target.name === "tobacco_units") {
      if (!isValidNumber(event.target.value)) {
        setIsTobaccoUnitsValid(false);
        setTobaccoUnits(event.target.value);
      } else {
        setIsTobaccoUnitsValid(true);
        setTobaccoUnits(event.target.value);
      }

      if (event.target.value <= 0 || event.target.value === "") {
        setIsTobaccoUnitsValid(false);
        setTobaccoUnits(event.target.value);
      } else {
        setIsTobaccoUnitsValid(true);
        setTobaccoUnits(event.target.value);
      }
    }

    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  };

  function checkValue(e) {
    if (e.target.id === "smoker") {
      let isSmoker = !smokeChecked;
      setSmokeChecked(isSmoker);
      setNonSmokeChecked(false);

      if (isSmoker === false) {
        setTobaccoType(null);
        setTobaccoUnits(0);
      }

      setUserDetails({
        ...userDetails,
        smoker: true,
      });
    }

    if (e.target.id === "nonSmoker") {
      let isNonSmoker = !nonSmokeChecked;
      setNonSmokeChecked(isNonSmoker);
      setSmokeChecked(false);

      setUserDetails({
        ...userDetails,
        smoker: false,
      });
    }

    if (e.target.id === "hasMedicalConsent") {
      let yesConsent = !yesConsentChecked;
      setYesConsent(yesConsent);
      setNoConsent(false);
    }

    if (e.target.id === "hasNoMedicalConsent") {
      let noConsent = !noConsentChecked;
      setNoConsent(noConsent);
      setYesConsent(false);
    }
  }

  const closeModal = async () => {
    setShow(false);
    let contact_details = {
      product: userDetails.product,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      contact_number: userDetails.contact_number,
    };

    var data = JSON.stringify({
      ...contact_details,
    });

    let config = {
      method: "post",
      url: `${API_ENDPOINT}buyonline/submit-lead/drop-off`,
      headers: {
        Authorization: `${AUTH_KEY}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config)
      .then((response) => {
        if (response) {
          if (response.data.success === false) {
            return Promise.reject(
              console.log("Drop-off error", JSON.stringify(response.data))
            );
          } else {
            navigate("/");
            return;
          }
        }
      })
      .catch((error) => {
        console.log("Drop-off error", JSON.stringify(error));
        return;
      });
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
    navigate("/quote");
  };

  const onAddBeneficiaryClick = () => {
    let result = validateEmptyOnSubmit();
    if (result === false) {
      toast.error("Please fill in all the required fields marked with (*)", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return null;
    } else {
      navigate("/add-beneficiary");
    }
  };

  const validateEmptyOnSubmit = () => {
    if (
      email_address === "" ||
      first_name === "" ||
      last_name === "" ||
      contact_number === "" ||
      id_number === "" ||
      take_home_pay === 0 ||
      income === 0 ||
      marital_status === "" ||
      qualification === "" ||
      province === "" ||
      occupation === "" ||
      home_language === "" ||
      title === "" ||
      (smoker === true && tobacco_type === "" && tobacco_units === 0)
    ) {
      return false;
    }
  };

  const validFormOnSubmit = () => {
    if (
      !isEmailValid ||
      !isNameValid ||
      !isLastNameValid ||
      !isNumberValid ||
      !isAltNumberValid ||
      !isIdNumberValid ||
      !isIncomeValid ||
      !isTakeHomeValid ||
      !isTobaccoUnitsValid
    ) {
      return false;
    }
  };

  const onClick = () => {
    return;
  };

  return (
    <MainContext.Consumer>
      {(value) => (
        <div className="relative bg-light-white px-4 pt-20 pb-20 sm:px-6 lg:px-8 lg:pb-28 lg:pt-28">
          <div className="relative mx-auto lg:pl-36 lg:pr-36">
            <div className="mx-auto grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-1">
              <div className="flex flex-col rounded-lg shadow-lg">
                <div className="flex flex-col bg-white xl:p-6 p-6">
                  <Stepper steps={steps} currentStep={1} />
                </div>

                <hr className="line-break pb-5 w-full"></hr>

                <div className="grid grid-cols-6 gap-6 xl:gap-6 sm:gap-0 bg-white">
                  <div className="col-span-6 sm:col-span-4">
                    <div className="flex flex-1 flex-col justify-between bg-white p-6">
                      <form
                        onSubmit={(event) => onSubmit(event, value)}
                        id="personal-details-form"
                      >
                        <div>
                          <h3 className="text-3xl font-normal leading-6 text-gray pb-5">
                            Complete your personal details
                          </h3>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="first_name"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  first_name.length === 0
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                FIRST NAME
                              </span>
                            </label>
                            <div
                              className={
                                isNameValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                required={true}
                                placeholder="Your first name"
                                type="text"
                                name="first_name"
                                id="first_name"
                                autoComplete="first_name"
                                value={first_name}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isNameValid && first_name.length > 0
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID FIRSTNAME
                            </p>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="last_name"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  last_name.length === 0
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                LAST NAME
                              </span>
                            </label>
                            <div
                              className={
                                isLastNameValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                required={true}
                                placeholder="Your last name"
                                type="text"
                                name="last_name"
                                id="last_name"
                                autoComplete="last_name"
                                value={last_name}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isLastNameValid && last_name.length > 0
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID LASTNAME
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="title"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    titleType === null
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  TITLE
                                </span>
                              </label>
                              <div className="text-gray pb-2">
                                <Select
                                  className="text-sm font-light text-gray"
                                  classNamePrefix="title"
                                  isDisabled={false}
                                  placeholder={"Your title"}
                                  isLoading={false}
                                  isClearable={true}
                                  isRtl={false}
                                  isSearchable={true}
                                  styles={customStyles}
                                  onChange={(value) => onChangeTitleType(value)}
                                  value={titleType}
                                  options={titleTypes}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="language"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    languageType === null
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  HOME LANGUAGE
                                </span>
                              </label>
                              <div className="text-gray pb-2">
                                <Select
                                  className="text-sm font-light text-gray"
                                  classNamePrefix="language"
                                  isDisabled={false}
                                  placeholder={"Your language"}
                                  isLoading={false}
                                  isClearable={true}
                                  isRtl={false}
                                  isSearchable={true}
                                  styles={customStyles}
                                  onChange={(value) =>
                                    onChangeLanguageType(value)
                                  }
                                  value={languageType}
                                  options={languageTypes}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="contact_number"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  contact_number.length === 0
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                CONTACT NUMBER
                              </span>
                            </label>
                            <div
                              className={
                                isNumberValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                maxLength={10}
                                required={true}
                                placeholder="Your contact number"
                                type="text"
                                name="contact_number"
                                id="contact_number"
                                autoComplete="contact_number"
                                value={contact_number}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isNumberValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID MUST BE 10 DIGITS
                            </p>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="alternative_contact_number"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span>ALTERNATIVE CONTACT NUMBER</span>
                            </label>
                            <div
                              className={
                                isAltNumberValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                maxLength={10}
                                placeholder="Alternative contact number"
                                type="text"
                                name="alternative_contact_number"
                                id="alternative_contact_number"
                                autoComplete="alternative_contact_number"
                                value={alternative_contact_number}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isAltNumberValid
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID MUST BE 10 DIGITS
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="email_address"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  email_address.length === 0
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                EMAIL ADDRESS
                              </span>
                            </label>
                            <div
                              className={
                                isEmailValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                required={true}
                                placeholder="Your email address"
                                type="text"
                                name="email_address"
                                id="email_address"
                                autoComplete="email_address"
                                value={email_address}
                                onChange={onChange}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>

                            <p
                              className={
                                !isEmailValid && email_address.length > 0
                                  ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                  : "hidden"
                              }
                            >
                              INVALID EMAIL ADDRESS
                            </p>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="id_number"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  id_number.length === 0
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                ID NUMBER
                              </span>
                            </label>
                            <div
                              className={
                                isIdNumberValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <input
                                maxLength={13}
                                required={true}
                                placeholder="Your id number"
                                type="text"
                                name="id_number"
                                id="id_number"
                                autoComplete="id_number"
                                value={id_number}
                                onChange={onChange}
                                onBlur={checkIdDropOff}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>
                            <p
                              className={
                                !isIdNumberValid
                                  ? "peer-invalid:visible text-red text-sm font-bold pb-2 pt-2"
                                  : "hidden"
                              }
                            >
                              INVALID ID NUMBER MUST BE 13 DIGITS
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="marital_status"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  maritalType === null
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                MARITAL STATUS
                              </span>
                            </label>
                            <div className="text-gray pb-2">
                              <Select
                                className="text-sm font-light text-gray"
                                classNamePrefix="marital_status"
                                isDisabled={false}
                                placeholder={"Your marital status"}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                styles={customStyles}
                                onChange={(value) => onChangeMaritalType(value)}
                                value={maritalType}
                                options={maritalTypes}
                              />
                            </div>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="province"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  provinceType === null
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                PROVINCE
                              </span>
                            </label>
                            <div className="text-gray pb-2">
                              <Select
                                className="text-sm font-light text-gray"
                                classNamePrefix="province"
                                placeholder={"Your province"}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                styles={customStyles}
                                onChange={(value) => onChangeProvince(value)}
                                value={provinceType}
                                options={provinceTypes}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="education-level"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  educationType === null
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                EDUCATION LEVEL
                              </span>
                            </label>
                            <div className="text-gray pb-2">
                              <Select
                                className="text-sm font-light text-gray"
                                classNamePrefix="education-level"
                                placeholder={"Your education"}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                styles={customStyles}
                                onChange={(value) => onChangeEducation(value)}
                                value={educationType}
                                options={educationTypes}
                              />
                            </div>
                          </div>

                          
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="occupation"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  occupationType === null
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                OCCUPATION
                              </span>

                              <ReactTooltip
                                id="custom-tooltip"
                                place="bottom"
                                effect="solid"
                                textColor="#ffffff"
                                backgroundColor="#0033a1"
                                scrollHide={true}
                              >
                                Start typing to see a dynamic list of occupations
                              </ReactTooltip>

                            </label>

                            <div className="text-gray pb-2">

                              <AsyncSelect
                                id="occupation-select"
                                className="text-sm font-light text-gray"
                                classNamePrefix="occupation"
                                placeholder={"Start typing to see a list of occupations"}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                styles={customStyles}
                                onChange={(value) => onChangeOccupation(value)}
                                value={occupationType}
                                onInputChange={(value) =>
                                  onSearchOccupationType(value)
                                }
                                loadOptions={fetchOccupationData}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 sm:gap-0 xl:gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="income"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  income === 0 || income === ""
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                GROSS MONTHLY HOUSEHOLD INCOME
                              </span>
                            </label>
                            <div
                              className={
                                isIncomeValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <span className="inline-flex items-center border-none focus:border-none px-3 text-sm text-light-blue">
                                R
                              </span>
                              <input
                                required={true}
                                placeholder="Your household income"
                                type="text"
                                name="income"
                                id="income"
                                autoComplete="income"
                                value={income}
                                onChange={onChange}
                                onFocus={(e) => (e.target.value = "")}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>
                            <p
                              className={
                                !isIncomeValid
                                  ? "peer-invalid:visible text-red text-sm font-bold pb-2 pt-2"
                                  : "hidden"
                              }
                            >
                              GROSS INCOME INVALID
                            </p>
                          </div>

                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="take_home_pay"
                              className="block text-sm font-normal text-gray-500 py-2"
                            >
                              <span
                                className={
                                  take_home_pay === 0 || take_home_pay === ""
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                TAKE HOME PAY
                              </span>
                            </label>
                            <div
                              className={
                                isTakeHomeValid
                                  ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                  : "flex border-2 border-red rounded w-full"
                              }
                            >
                              <span className="inline-flex items-center border-none focus:border-none px-3 text-sm text-light-blue">
                                R
                              </span>
                              <input
                                required={true}
                                placeholder="Your take home pay"
                                type="text"
                                name="take_home_pay"
                                id="take_home_pay"
                                autoComplete="take_home_pay"
                                value={take_home_pay}
                                onChange={onChange}
                                onFocus={(e) => (e.target.value = "")}
                                className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                              />
                            </div>
                            <p
                              className={
                                !isTakeHomeValid
                                  ? "peer-invalid:visible text-red text-sm font-bold pb-2 pt-2"
                                  : "hidden"
                              }
                            >
                              TAKE HOME PAY INVALID
                            </p>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-6 pb-5 pt-5">
                          <div className="col-span-6 sm:col-span-3">
                            <label className="text-sm font-normal text-gray-500">
                              <span
                                className={
                                  !smokeChecked && !nonSmokeChecked
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                HAVE YOU SMOKED IN THE LAST 12 MONTHS
                              </span>
                            </label>
                            <fieldset className="mt-2">
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                {smokedOptions.map(
                                  (smokedOptions, smokedOptionsIdx) => (
                                    <div
                                      key={smokedOptions.id}
                                      className="flex items-center"
                                    >
                                      {smokedOptionsIdx === 0 ? (
                                        <input
                                          required={!nonSmokeChecked}
                                          id={smokedOptions.id}
                                          name={smokedOptions.title}
                                          value={smokeChecked}
                                          type="checkbox"
                                          checked={smokeChecked}
                                          onChange={checkValue}
                                          className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                        />
                                      ) : (
                                        <input
                                          required={!smokeChecked}
                                          id={smokedOptions.id}
                                          name={smokedOptions.title}
                                          value={nonSmokeChecked}
                                          type="checkbox"
                                          checked={nonSmokeChecked}
                                          onChange={checkValue}
                                          className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                        />
                                      )}

                                      <label
                                        htmlFor={smokedOptions.id}
                                        className="ml-3 block text-sm font-normal text-gray"
                                      >
                                        {smokedOptions.title}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </fieldset>
                          </div>
                        </div>

                        {smokeChecked === true ? (
                          <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="tobacco"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    tobaccoType === null
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  TOBACCO TYPE
                                </span>
                              </label>
                              <div className="text-gray pb-2">
                                <Select
                                  className="text-sm font-light text-gray"
                                  classNamePrefix="tobacco"
                                  placeholder={"Select Tobacco Type"}
                                  isDisabled={false}
                                  isLoading={false}
                                  isClearable={true}
                                  isRtl={false}
                                  isSearchable={true}
                                  styles={customStyles}
                                  onChange={(value) => onTobaccoChange(value)}
                                  value={tobaccoType}
                                  options={tobaccoTypes}
                                />
                              </div>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                              <label
                                htmlFor="tobacco_units"
                                className="block text-sm font-normal text-gray-500 py-2"
                              >
                                <span
                                  className={
                                    tobacco_units === 0
                                      ? "after:content-['*'] after:text-red"
                                      : "after:content-['*']"
                                  }
                                >
                                  TOBACCO UNITS
                                </span>
                              </label>
                              <div
                                className={
                                  isTobaccoUnitsValid
                                    ? "flex border border-gray-500 rounded w-full hover:border-blue hover:border-2 mb-2"
                                    : "flex border-2 border-red rounded w-full"
                                }
                              >
                                {tooltip && (
                                  <ReactTooltip
                                    id="tobacco_units"
                                    place="bottom"
                                    effect="solid"
                                    textColor="#ffffff"
                                    backgroundColor="#0033a1"
                                    scrollHide={true}
                                  >
                                    1 unit is 1 Cigar, Cigarette or Pipe
                                  </ReactTooltip>
                                )}
                                <input
                                  data-tip
                                  data-for="tobacco_units"
                                  required={true}
                                  placeholder="Tobacco Units"
                                  type="number"
                                  min="1"
                                  name="tobacco_units"
                                  id="tobacco_units"
                                  autoComplete="tobacco_units"
                                  value={tobaccoUnits}
                                  onChange={onChange}
                                  className="text-gray block border-none flex-1 text-sm focus:outline-transparent focus:ring-transparent focus:border-transparent rounded"
                                  onMouseEnter={() => showTooltip(true)}
                                  onMouseLeave={() => {
                                    showTooltip(false);
                                    setTimeout(() => showTooltip(true), 50);
                                  }}
                                />
                              </div>

                              <p
                                className={
                                  !isTobaccoUnitsValid
                                    ? "peer-invalid:visible text-red font-bold pb-2 pt-2 text-sm"
                                    : "hidden"
                                }
                              >
                                INVALID UNIT ENTERED
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 xl:col-span-6 sm:col-span-3">
                            <label className="text-sm font-normal text-gray-500">
                              <span
                                className={
                                  !yesConsentChecked && !noConsentChecked
                                    ? "after:content-['*'] after:text-red"
                                    : "after:content-['*']"
                                }
                              >
                                CONSENT
                              </span>
                            </label>
                            <p className="text-xs text-gray-500 mt-2 font-normal">
                              We’ll need access to your medical and other
                              relevant information to process a claim. Do you
                              consent?
                            </p>
                            <fieldset className="mt-2">
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                {medicalConsent.map(
                                  (
                                    medicalConsentOptions,
                                    medicalConsentOptionsIdx
                                  ) => (
                                    <div
                                      key={medicalConsentOptions.id}
                                      className="flex items-center"
                                    >
                                      {medicalConsentOptionsIdx === 0 && (
                                        <input
                                          required={!noConsentChecked}
                                          id={medicalConsentOptions.id}
                                          name={medicalConsentOptions.title}
                                          value={yesConsentChecked}
                                          type="checkbox"
                                          checked={yesConsentChecked}
                                          onChange={checkValue}
                                          className="h-6 w-6 border-light-blue text-light-blue focus:ring-blue checked:bg-light-blue rounded-full peer"
                                        />
                                      )}

                                      <label
                                        htmlFor={medicalConsentOptions.id}
                                        className="ml-3 block text-sm font-normal text-gray"
                                      >
                                        {medicalConsentOptions.title}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </fieldset>
                          </div>
                        </div>

                        <div className="xl:text-center xl:items-center text-center bg-white pt-5 xl:pt-10">
                          <button
                            className="bg-btn-primary-color font-bold xl:w-100 w-64 py-2 px-4 sm:py-2 sm:px-4 text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2"
                            type="submit"
                            onClick={onClick}
                          >
                            <span>CONTINUE</span>
                          </button>
                        </div>
                        <ConfirmationModal
                          image={confirmationModal.image}
                          title={confirmationModal.title}
                          message={confirmationModal.message}
                          alternativeMessage={
                            confirmationModal.alternativeMessage
                          }
                          show={showConfirmation}
                          closeModal={closeConfirmationModal}
                        />
                        <ApplicationModal
                          dropOffModel={dropOffModel}
                          dropOffId={dropOffId}
                          show={show}
                          closeModal={closeModal}
                        />
                      </form>
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-2">
                    <div className="flex flex-1 flex-col justify-between bg-white pt-1 pb-6 px-6 xl:p-6 sticky top-0">
                      <PersonalInformation></PersonalInformation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainContext.Consumer>
  );
}
