import React, { useEffect, useContext } from 'react'
import {  useCookies } from "react-cookie";
import Content from "../components/content/Content";

export default function DisabilityCover() {
    const [cookies, setCookie, removeCookie] = useCookies();
    useEffect( () => {
        setCookie('productSlug', 'disability-cover');
        localStorage.setItem("productKey", JSON.stringify("Disability Cover"));
    },[]);

    return (
        <>
          <div className="bg-light-white">
            <h1 className="text-3xl pt-5 font-normal tracking-tight text-gray text-center sm:text-4xl">
              Buy Disability Cover
            </h1>
            <Content></Content>
          </div>
        </>
    );
};
