import { Link } from "react-router-dom";
import clsx from "clsx";

const baseStyles = {
  solid:
    "group inline-flex items-center justify-center py-2 px-4 font-bold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
  outline:
    "group inline-flex items-center justify-center py-2 px-4 font-bold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2",
};

const variantStyles = {
  solid: {
    slate:
      "bg-btn-primary-color text-white shadow-sm border-light-blue hover:bg-blue hover:text-white hover:border-blue border-solid border-2",
      darkblue:
      "bg-dark-blue text-white shadow-sm border-dark-blue hover:bg-light-blue hover:text-white hover:border-light-blue border-solid border-2",
  },
  outline: {
    white:
      "bg-btn-secondary-color text-light-blue shadow-sm border-light-blue hover:bg-btn-primary-color hover:text-white hover: border-solid border-2",
  },
};

export function Button({
  variant = "solid",
  color = "slate",
  className,
  href,
  ...props
}) {
  className = clsx(
    baseStyles[variant],
    variantStyles[variant][color],
    className
  );

  return href ? (
    <Link href={href} className={className} {...props} />
  ) : (
    <button className={className} {...props} />
  );
}
